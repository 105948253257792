<div class="row mt-4">
  <!-- First slider -->
  <div class="col-12 col-md-6 mb-4">
    <div class="slider position-relative">
      <div class="arrow-container">
        <div (click)="goToPrevious1()" class="leftArrow" style="font-size: 80%;">❰</div>
        <div (click)="goToNext1()" class="rightArrow" style="font-size: 80%;">❱</div>
      </div>
      <div class="slide" [ngStyle]="{ 'background-image': getCurrentSlideUrl1() }">
        <div class="dotsContainer">
          <div
            *ngFor="let slide of slides1; let slideIndex = index"
            class="dot"
            (click)="goToSlide1(slideIndex)">●
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Second slider -->
  <div class="col-12 col-md-6 mb-4">
    <div class="slider position-relative slide12">
      <div class="arrow-container">
        <div (click)="goToPrevious2()" class="leftArrow" style="font-size: 80%;">❰</div>
        <div (click)="goToNext2()" class="rightArrow" style="font-size: 80%;">❱</div>
      </div>
      <div class="slide" [ngStyle]="{ 'background-image': getCurrentSlideUrl2() }"></div>
      <div class="dotsContainer">
        <div
          *ngFor="let slide of slides2; let slideIndex = index"
          class="dot"
          (click)="goToSlide2(slideIndex)"> ●
        </div>
      </div>
    </div>
  </div>
</div>


<div class="row p-2 mb-3 mtx justify-content-center align-items-center">
  <div class="col-md-6 col-sm-8 col-10 mt-4 position-relative" style="margin-bottom: 10px;">
    <input class="form-control search-input" type="text" placeholder="Search Profession Services"
    (keyup)="onSearch($event)">
    <i class="fa fa-search search_icon1" aria-hidden="true"></i>
  </div>
</div>

<div class="profession-container" >
  <ng-container *ngIf="loading$ | async; else showListings">
    <app-loading></app-loading>
  </ng-container>
  <ng-template #showListings>
    <ng-container *ngIf="professions.length > 0; else noListings">
      <div *ngFor="let profession of professions">

        <div class="profession-card"(click)="expertiespopup(profession.id , profession.icon, profession.name ,profession.expertiseCount)">
          <img
            [src]="profession.icon ? profession.icon : '../assets/images/professionals.png'"
            alt="{{ profession.name }} icon"
            class="profession-icon"/>
        </div>
        <div class="profession-title">
          {{ profession.name }}
        </div><div>
      </div>
      </div>
    </ng-container>
    <ng-template #noListings>
      <div class="no-listings-container">
        <app-not-found></app-not-found>
      </div>
    </ng-template><br><br><br><br>
</ng-template>
</div>
