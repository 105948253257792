import { environment } from 'src/environments/environment';

export const ApiURL = {
    // login: `${environment.url}oauth/token`,

    login: `${environment.url}global/user/login`,
    // loginglove: `${environment.url}/global/user/login?platform=portal&userName`,

    getProfileData: `${environment.url}global/user/profile`,
    userPofile: `${environment.url}global/user/verify`,
    forget: `${environment.url}global/user/forgot/password`,
    otpVerify:`${environment.url}global/user/reset/password`,
    updatePofile: `${environment.url}admin/update/user/profile`,
    fetchProfile: `${environment.url}admin/fetch/user/profiles`,
    fetchEmployeeList: `${environment.url}employee/list`,
    createEmploye: `${environment.url}employee/profile/create`,
    updateEmploye: `${environment.url}employee/profile/update`,
    getEmployeeById:`${environment.url}employee/profile/id`,
    roleMaster:`${environment.url}rolesmaster/all`,
    departmentAll:`${environment.url}department/all`,
    designationAll:`${environment.url}designation/all`,
    checkUniqueEmpIdentifier:`${environment.url}employee/unique/identifier`,
    checkUniqueUsernameIdentifier:`${environment.url}global/user/unique/username`,
    getCountry: `${environment.url}global/country/all`,
    country: `${environment.url}global/country`,
    state: `${environment.url}global/state`,
    stateByCountry: `${environment.url}global/state/by-country`,
    stateUniqueness: `${environment.url}global/state/uniqueness`,

    cityByCountry: `${environment.url}global/city/by-country`,
    cityByState: `${environment.url}global/city/by-state`,
    city: `${environment.url}global/city`,
    countryUniqueness: `${environment.url}global/country/uniqueness`,
    cityUniqueness: `${environment.url}global/city/uniqueness`,
    getUserData: `${environment.url}global/user`,
    getUserEmail:`${environment.url}global/user/reset/password`,
    statusEmployee:`${environment.url}employee/status`,

    //glove role
    gloveRoleListing: `${environment.url}gloverole/all`,
    roleUniqueness: `${environment.url}gloverole/uniqueness`,
    updateRoleById:`${environment.url}gloverole/update`,
    statusGloverole:`${environment.url}gloverole/status`,

    //status api
    statusCountry:`${environment.url}global/country/status`,
    statusState:`${environment.url}global/state/status`,
    statusCity:`${environment.url}global/city/status`,

    //Interview
    getInterviewAll: `${environment.url}interview/all`,
    interview:`${environment.url}interview`,
    createInterview: `${environment.url}interview/add`,
    updateInterviewById: `${environment.url}interview/update`,
    statusInterview:`${environment.url}interview/status`,
    interviewUniqueness:`${environment.url}interview/uniqueness`,

    //Institute
    getInstituteAll:`${environment.url}institute/all`,
    getInstituteById:`${environment.url}institute`,
    updateInstitute:`${environment.url}institute/update`,
    createInstitute:`${environment.url}institute/add`,
    statusInstitute:`${environment.url}institute/status`,
    instituteUniqueness:`${environment.url}institute/uniqueness`,

    //Qualifications
    getQualificationAll:`${environment.url}qualification/all`,
    getQualificationById:`${environment.url}qualification`,
    updateQualification:`${environment.url}qualification/update`,
    createQualification:`${environment.url}qualification/add`,
    statusQualification:`${environment.url}qualification/status`,
    qualificationUniqueness:`${environment.url}qualification/uniqueness`,

    //Certifications
    getCertificationsAll:`${environment.url}certification/all`,
    getCertificationsById:`${environment.url}certification`,
    updateCertifications:`${environment.url}certification/update`,
    createCertifications:`${environment.url}certification/add`,
    statusCertifications:`${environment.url}certification/status`,
    certificationUniqueness:`${environment.url}certification/uniqueness`,

    //Department
    getDepartmentAll:`${environment.url}department/all`,
    getDepartmentById:`${environment.url}department`,
    updateDepartment:`${environment.url}department/update`,
    createDepartment:`${environment.url}department/add`,
    statusDepartment:`${environment.url}department/status`,
    departmentUniqueness: `${environment.url}department/uniqueness`,

    //Designations
    getDesignationAll:`${environment.url}designation/all`,
    getDesignationById:`${environment.url}designation`,
    updateDesignation:`${environment.url}designation/update`,
    createDesignation:`${environment.url}designation/add`,
    statusDesignation:`${environment.url}designation/status`,
    designationUniqueness: `${environment.url}designation/uniqueness`,


    //offer desginations
    offerDesignationAll:`${environment.url}offer_designation/all`,
    getOfferDesignationById:`${environment.url}offer_designation`,
    updateOfferDesignation:`${environment.url}offer_designation`,
    createOfferDesignation:`${environment.url}offer_designation`,
    statusOfferDesignation:`${environment.url}offer_designation/status`,
    offerDesignationUniqueness:`${environment.url}offer_designation/unique`,
    // deleteOfferDesignation:`${environment.url}offer_designation`,


    //RoleMaster(Candidate Role)
    getRoleMasterAll:`${environment.url}rolemaster/all`,
    getRoleMasterById:`${environment.url}rolemaster`,
    updateRoleMaster:`${environment.url}rolemaster/update`,
    createRoleMaster:`${environment.url}rolemaster/add`,
    statusRoleMaster:`${environment.url}rolemaster/status`,
    rolemasterUniqueness: `${environment.url}rolemaster/uniqueness`,

    //Industry
    getIndustryAll:`${environment.url}industry/all`,
    getIndustryById:`${environment.url}industry`,
    updateIndustry:`${environment.url}industry/update`,
    createIndustry:`${environment.url}industry/add`,
    statusIndustry:`${environment.url}industry/status`,
    checkUniqueIndIdentifier:`${environment.url}industry/uniqueness`,

    // Client
    getClientAll:`${environment.url}clientMaster/getAll`,
    getClientById:`${environment.url}clientMaster`,
    updateClient:`${environment.url}clientMaster/client`,
    createClient:`${environment.url}clientMaster/client`,
    createClientScop:`${environment.url}clientMaster/client/spoc`,
    updateClientScop:`${environment.url}clientMaster/client/spoc`,
    statusClient:`${environment.url}clientMaster/status`,
    checkUniqueClientIdentifier:`${environment.url}client/unique/identifier`,
    getallSpocmMaster:`${environment.url}clientSpoc/clientRecords?name=`,
    uploadImage:`${environment.url}global/media/presigned`,

    //Locations
    getLocationAll:`${environment.url}location/all`,
    getLocationById:`${environment.url}location`,
    updateLocation:`${environment.url}location`,
    createLocation:`${environment.url}location`,
    statusLocation:`${environment.url}location/status`,


    //Company
    getCompanyAll:`${environment.url}company/all`,
    getCompanyById:`${environment.url}company/id`,
    updateCompany:`${environment.url}company/update`,
    createCompany:`${environment.url}company/add`,
    statusCompany:`${environment.url}company/status`,
    companyUniqueness: `${environment.url}company/uniqueness`,

    //Education
    getEducationAll:`${environment.url}education/all`,
    getEducationById:`${environment.url}education/id`,
    updateEducation:`${environment.url}education/update`,
    createEducation:`${environment.url}education/add`,
    statusEducation:`${environment.url}education/status`,
    educationUniqueness: `${environment.url}education/uniqueness`,

    //Expereiance level
    getExperienceAll:`${environment.url}experienceLevel`,
    getExperienceById:`${environment.url}experienceLevel/id`,
    updateExperience:`${environment.url}experienceLevel`,
    createExperience:`${environment.url}experienceLevel`,
    statusExperience:`${environment.url}experienceLevel/status`,
    experienceLevelUniqueness: `${environment.url}experienceLevel/uniqueness`,

    //Functional Level
    getFunctionalAll:`${environment.url}functionalArea/all`,
    getFunctionalById:`${environment.url}functionalArea`,
    updateFunctional:`${environment.url}functionalArea/update`,
    createFunctional:`${environment.url}functionalArea/add`,
    statusFunctional:`${environment.url}functionalArea/status`,
    functionalUniqueness: `${environment.url}functionalArea/uniqueness`,

    //Feedback
    getFeedbackAll:`${environment.url}feedback/all`,
    getFeedbackById:`${environment.url}feedback`,
    updateFeedback:`${environment.url}feedback`,
    createFeedback:`${environment.url}feedback`,
    statusFeedback:`${environment.url}feedback/status`,

    //Reason To Change
    getReasonAll:`${environment.url}reasonToChange/all`,
    getReasonById:`${environment.url}reasonToChange`,
    updateReason:`${environment.url}reasonToChange`,
    createReason:`${environment.url}reasonToChange`,
    statusReason:`${environment.url}reasonToChange/status`,

    //Get Profile
    // getProfileData:`${environment.url}global/user`,

    gloveroleaccessrights:`${environment.url}gloveroleaccessrights/add`,
    getGloverole:`${environment.url}gloveroleaccessrights/gloverole`,

    //Candidate Apis
    candidateCreateUpdate:`${environment.url}candidate/master`,
    candidateEducation:`${environment.url}candidate/education`,
    candidateExperience:`${environment.url}candidate/experience`,
    candidate:`${environment.url}candidate`,
    candidateById:`${environment.url}candidate/id`,
    candidateList:`${environment.url}candidate/fetch/list`,
    candidateTracker : `${environment.url}candidateTracker/fetch/list`,
    candidateClouser : `${environment.url}candidateTracker/fetch/closure/list`,
    closureCSVDownload:`${environment.url}candidateTracker/exportClosure`,
    candidateByKey:`${environment.url}candidate/mail/form`,
    candidateSendOtp:`${environment.url}global/user/sendOtp/candidate?email=`,
    candidateVeifyOtp:`${environment.url}global/user/verifyOtp/candidate`,
    candidateEmailForm:`${environment.url}candidate/by-form`,
    candidateAll:`${environment.url}candidate/full`,
    candidateCSVDownload:`${environment.url}candidateTracker/export`,
    getCandidateWithRequirement:`${environment.url}candidate/with-requirement`,
    candidateEmailBcc:`${environment.url}candidate/notify`,

    //company
    allCompany:`${environment.url}company/all`,

    requirementAssign:`${environment.url}requirementManagementAssign/all`,

    // save candidate anwser
    candidateResponse:`${environment.url}candidateResponse`,

    //client Spoc
    getClientSpocAll:`${environment.url}clientSpoc/all`,
    getCompanybaseclientscop:`${environment.url}clientSpoc/allByCLID`,

    getallClientMaster:`${environment.url}clientMaster/getAll`,
    postrequirementManagement:`${environment.url}requirementManagement`,
    postrequirementEvaluation:`${environment.url}requirementQuestions`,
    deleterequirementEvaluation:`${environment.url}requirementQuestions/delete`,
    deleterequirementEvaluationotion:`${environment.url}requirementQuestionsOption/delete`,
    deleteAllRequirementEvaluationQuestion:`${environment.url}requirementQuestionsOption/deleteByQuestion`,
    postrequirementAssign:`${environment.url}requirementManagementAssign/assign`,
    putrequirementAssign:`${environment.url}requirementManagementAssign/assign`,
    getrequirementEvaluation:`${environment.url}requirementQuestions/fetch/requirement`,
    getrequirementassign:`${environment.url}requirementManagementAssign/fetch/requirement`,
    checkevalutionForm:`${environment.url}candidateResponse/checkIfTagged`,
    getrequirementAll:`${environment.url}requirementManagement/all`,
    getrequirementStatuschanged:`${environment.url}requirementManagement/status`,
    evaluationFormDownload:`${environment.url}requirementQuestions/export/`,
    requrementAssigndeleteandCreate:`${environment.url}requirementManagementAssign/assign`,

    //Assignement
    getAssignementAll:`${environment.url}assignment/allPaginated`,
    employeeSavedSearch:`${environment.url}employeeSavedSearch`,
    // fetchCandidateFilter:`${environment.url}employeeSavedSearch/all`,

    //Save Serach
    // saveSerach:`${environment.url}employeeSavedSearch/all`,
    deleteSaveSerach:`${environment.url}employeeSavedSearch/`,

    //Nav Menu
    navMenu:`${environment.url}menu/all`,
    menuFetchForUser:`${environment.url}menu/fetch-for-user`,
    defaultRoleAccess:`${environment.url}gloveroleaccessrights/default`,

    //candidate Interview feedback
    canInterviewFeedback:`${environment.url}candidateOffer/fetch/JCIDCTID`,
    evaluationformQueAns:`${environment.url}candidateResponse`,

    unblockUser:`${environment.url}user/unblockUser`,

    //clientLocation
    clientLocations:`${environment.url}clientLocation/fetch/clientName`,

    //workflow Feedback
    candidateFeedback:`${environment.url}candidateInterviewFeedback`,
    feedbackClousre:`${environment.url}candidateOffer`,
    putFeedback:`${environment.url}candidateOffer`,

    // Dashboard
     interviewSchedule:`${environment.url}candidateOffer/allSchedule`,
     exportCandidateOffer:`${environment.url}candidateOffer/export?offIds=`,

    // client Spoc Fatch By Name
    clientSpocByName:`${environment.url}clientSpoc/allByName?name=`,

}
