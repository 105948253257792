<div class="btn-group" dropdown container="body">
    <button id="button-basic" dropdownToggle type="button" style=" border: none; background: none;" aria-controls="dropdown-basic">
     <img src="../../../../../assets/images/dots.png" style="width: 15px;" alt="">
    </button>
    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
      <li role="menuitem"><a class="dropdown-item" (click)="Edit()">Edit</a></li>
      <li role="menuitem"><a class="dropdown-item" (click)="candidateAdd()">Tag candidate</a></li>
      <li role="menuitem"><a class="dropdown-item" (click)="feedback()">Feedback</a></li>
      <li class="divider dropdown-divider m-0 p-0"></li>
    </ul>
  </div>