<div class="container">
    <div class="box">
        <div class="content">
            <!-- <h1>Login</h1> -->
            <img src="/assets/images/logo/logo-fold copy.png" alt="" style="max-width: 90px; border-radius: 5px;">
            <div class="false col-12 my-2 text-center">
                <h2 class="mt-3 text-black fw-600">Login to your account</h2>
              </div>
              <!-- <form  style="margin-top: 1rem;">
                <div class="form-group mb-3">
                    <label class="form-label">Username </label> <span class="text-danger">*</span>
                    <input type="text"  class="form-control no-validation-icon no-success-validation"  placeholder="Username"   formControlName="username">
                </div>
                <div class="mb-3 ">
                    <label class="form-label">Password</label> <span class="text-danger">*</span>
                    <div class="form-group input-affix flex-column">
                        <label class="d-none">Password</label>
                        <input  placeholder="Password"  class="form-control no-validation-icon no-success-validation"  formControlName="password" >
                        <i class="suffix-icon feather cursor-pointer text-dark" ></i>
                    </div>
                </div>
                <a class="text-primary font float-end my-2" routerLink="/auth/forgot">Forgot Password?</a>
                <button class="btn btn-primary w-100" type="submit">Submit</button>
            </form> -->
            <hr>
            <form action="">
                <div class="mb-3">
                    <div class="form-group">
                        <label class="form-label text-black mb-1">Username </label><span class="text-danger"> *</span>
                        <input type="text" class="form-control" formControlName="username" maxlength="50" placeholder="Username" >
                    </div>
                </div>
                <!-- <div class="form-group">
                    <label class="form-label text-black mb-1">Password </label><span class="text-danger"> *</span>
                    <input type="Password" class="form-control" formControlName="password" maxlength="50" placeholder="Password" >
                </div> -->
                <div class="mb-3">
                    <label class="form-label text-black">Password</label> <span class="text-danger"> *</span>
                    <div class="form-group input-affix flex-column">
                        <label class="d-none text-black">Password</label>
                        <input  [type]="showPassword ? 'text' : 'password'"  placeholder="Password"  class="form-control no-validation-icon no-success-validation"  formControlName="password" >
                        <i class="suffix-icon feather cursor-pointer text-dark"  [ngClass]="showPassword ? 'icon-eye-off' : 'icon-eye'" (click)="onShowPasswordClick()"></i>
                    </div>
                </div>
            </form>
            <hr>
            
            <a href="" class="text-end text-primary">Forgot Password?</a>
            <div class="buttons w-100">
                <button class="btn w-100">Submit</button>
                
            </div>
            
        </div>
    </div>
<div class="link">
</div>