import { HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, UntypedFormBuilder,Validators, UntypedFormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutePath } from '@app/layout/config';
import { CONSTANTS_TEXT } from '@app/layout/const/app.constant';
import { CommonService } from '@app/layout/service/common.service';
import { LocalstorageService } from '@app/layout/service/localstorage.service';
import { log } from 'console';
import moment from 'moment';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription ,finalize, switchMap} from 'rxjs';
import { ModalContentComponent } from '../modal-content/modal-content.component';
import { ModalLoaderComponent } from '../modal-loader/modal-loader.component';
import Swal from 'sweetalert2';
import {  NgcCookieConsentService,NgcNoCookieLawEvent,NgcInitializingEvent,NgcStatusChangeEvent,NgcInitializationErrorEvent } from 'ngx-cookieconsent';

@Component({
  selector: 'app-candidate-mail-form',
  templateUrl: './candidate-mail-form.component.html',
  styleUrls: ['./candidate-mail-form.component.css']
})
export class CandidateMailFormComponent implements OnInit {
  @Input() statusUpdated:boolean
  headerNavColor: string;
  isMobile: boolean;
  subscription: Subscription
  modalRef: BsModalRef;
  resumefiles:any = '';
  hasTyped: boolean = false;
  emailValue:any;
  isMobileNumber:boolean=false;
  isName:boolean=false;
  checked: boolean = false;
  files:any = '';
  form!:FormGroup;
  formGroup: UntypedFormGroup;
  sizeMoreThan5mb:boolean=false;

  subs$!: Subscription;
  constant = CONSTANTS_TEXT;
  candiadteData:any = {};
  status:boolean=false;
  masterCounrty:any = [];
  masterCity:any=[];
  masterState:any = [];
  masterHomeTown:any =[];
  finalFileName:any='';
  masterLocation:any = [];
  masterSource:any = [];
  masterIndustry:any = [];
  masterExperienceLevel:any = [];
  masmasterDepartmentterIndustry:any = [];
  masterCompany:any = [];
  masterDepartment:any = [];
  masterEducation:any = [];
  masterCertification:any = [];
  diversityInclusion:[];
  email:any;
  submitStatus:boolean=false;
  types = ['Tech','Non-Tech'];
  value: string = '4'
  genderValue:any = [];
  isSubmit:boolean=false;
  isLoading:boolean=false;
  isOtpValid:boolean=false;
  inputValue:any='';
  otpModalTemplate: TemplateRef<any>;
  bsModalRef: BsModalRef;
  id:any = '';
  lastWorkingDayRequired:boolean= false;
  noticePeriodValues:string[] = ['1 Month','2 Months','3 Months','Immediate','Serving Notice Period'];
  bsValue: Date = new Date();
  public today = new Date();
  resumefilesName:string = '';
  allowedTypes = ['.pdf', '.jpg', '.jpeg', '.docx','.doc','.png'];
  months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  fromYear:any = [];
  toMonths:any=[];
  toYear:any= [];
  iSCvNeeded:boolean=false;
  masterDesignation:any=[];
  privacyAndPolicyValue:boolean=false;

  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializingSubscription!: Subscription;
  private initializedSubscription!: Subscription;
  private initializationErrorSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;
  inputName: any;
  inputMobile: any;

  constructor(private router:Router,
    private cookieService: NgcCookieConsentService,
    private _commanServcietailse:CommonService,
    private activeRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private _LocalstorageService:LocalstorageService,
    private modalService: BsModalService,
    private _commanServcie:CommonService,
    private fb:FormBuilder,
    ) { }

  ngOnInit(): void {
    this.popupOpenSubscription = this.cookieService.popupOpen$.subscribe(() => {});
    this.popupCloseSubscription = this.cookieService.popupClose$.subscribe(() => {});
    this.initializingSubscription = this.cookieService.initializing$.subscribe((event: NgcInitializingEvent) => {});
    this.initializedSubscription = this.cookieService.initialized$.subscribe(() => {});
    this.initializationErrorSubscription = this.cookieService.initializationError$.subscribe((event: NgcInitializationErrorEvent) => {});
    this.statusChangeSubscription = this.cookieService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {});
    this.revokeChoiceSubscription = this.cookieService.revokeChoice$.subscribe(() => {});
    this.noCookieLawSubscription = this.cookieService.noCookieLaw$.subscribe((event: NgcNoCookieLawEvent) => {});
    this.initializeForm()
    const id = this.activeRoute.snapshot?.children[0]?.params?.id;
    if(id != undefined && id != null && id != ''){
      this.id = id;
      this.submitStatus=true;
    }
    this.masterAll();
   
  }
  getCandidateDataById(id){
    console.log('id  > ',id);
    
    this.subs$ = this._commanServcietailse.getCandidateByKey(id).pipe().subscribe((res:any)=>{
      if(res.status == CONSTANTS_TEXT.SUCCESS){
        this.candiadteData = res.payload;
        this.resumefiles = this.candiadteData?.resume;
        if(this.candiadteData?.resume != ''){
          const url = this.candiadteData.resume;
          const index = url.indexOf("candidate/");
          if (index !== -1) {
            const trimmedUrl =  url.slice(index + "candidate/".length);
            this.resumefilesName = trimmedUrl;
            console.log(trimmedUrl);
          }
        }
        this.setFormData(this.candiadteData);
        this.cdr.detectChanges();
      }
    });
  }

  initializeForm(candiadteData:any = null){
    console.log('candiadteData',candiadteData);
    
    this.form = this.formBuilder.group({
      firstName:['',[Validators.required]], 
      lastName:[''], 
      emailId: ['', [Validators.required,Validators.email]],
      phoneNumber:['', [Validators.required]],
      gender: [null,[Validators.required]],
      type:[null],
      currentCTC:['', [Validators.required]],
      noticePeriod:[null, [Validators.required]],
      countryCode: ['+91',[Validators.required]],
      alternateCountryCode: ['+91',[Validators.required]],
      currentLocation : [null,[Validators.required]],
      skills:[null,[Validators.required]],
      industry: [null],
      // types:[],
      educationList:this.fb.array([]),
      certificationList:[null],
      preferredLocation:[null],
      currentCompany:[null,[Validators.required]],
      lastWorkingDay:[],
      // expectedCTC:[null,[Validators.required]],
      diversityInclusion:[],
      alternateEmailId:[null,Validators.email],
      alternateContactNumber:[null],
      ccCode:[null,[Validators.required]],
      totalExperience:[null,[Validators.required]],
      variableCTC:[null],
      lastDesignation:[null,[Validators.required]],
      careerSynopsis:['',[Validators.required]]
    });
    if(this.id == undefined || this.id == ''){
      this.education().push(this.addeduction())
    }
    this.cdr.detectChanges();
  }

  addeduction(){
    return this.fb.group({
      educationId:[null],
      fromYear:[null],
      fromMonth:[null],
      toYear:[null],
      toMonth:[null],
      college:[null]
    })
  }
  education(): FormArray {
    return this.form.get("educationList") as FormArray
  }
  addeducation(){
    this.education().push(this.addeduction());
  }
  deleteEducation(i:number){
    const fb:any = this.form.get("educationList") as FormArray
    fb.removeAt(i);
  }
  onEducationChange(event: any) {
    const educationList = this.form.get('educationList') as FormArray;
    educationList.controls.forEach((educationGroup: FormGroup) => {
      const educationIdControl = educationGroup.get('educationId');
      const fromYearControl = educationGroup.get('fromYear');
      const fromMonthControl = educationGroup.get('fromMonth');
      const toYearControl = educationGroup.get('toYear');
      const toMonthControl = educationGroup.get('toMonth');

      if (educationIdControl.value) {
        fromYearControl.setValidators([Validators.required]);
        fromMonthControl.setValidators([Validators.required]);
        toYearControl.setValidators([Validators.required]);
        toMonthControl.setValidators([Validators.required]);
      } else {
        fromYearControl.clearValidators();
        fromMonthControl.clearValidators();
        toYearControl.clearValidators();
        toMonthControl.clearValidators();
      }

      fromYearControl.updateValueAndValidity();
      fromMonthControl.updateValueAndValidity();
      toYearControl.updateValueAndValidity();
      toMonthControl.updateValueAndValidity();
      this.cdr.detectChanges();
    });
  }

  selectedCounrty(event){
    let counrtyId = event?.id;
    if(counrtyId != null){
      this.getCityListByCounrtyId(counrtyId);
      this.getStateList(counrtyId);
    }
  }
  getStateList(counrtyId){
    this.subs$ =this._commanServcietailse.getStateList(counrtyId).subscribe(results => {
      this.masterState =results.payload?.stateDataList.filter((i) => i.isDelete !== true&&i.status===true);
      this.masterHomeTown =results.payload?.stateDataList.filter((i) => i.isDelete !== true&&i.status===true);
    });
  }
  getCityListByCounrtyId(counrtyId){
    this.subs$ =this._commanServcietailse.cityByCountry(counrtyId).subscribe(results => {
      this.masterCity =results.payload?.cityList;
    });
  }
  checkNoticePeriod(event){
    if(event == 'Servicing Notice Period'){
      this.form.controls.lastWorkingDay.setValidators([Validators.required]);
      this.lastWorkingDayRequired = true;
    }else{
      this.form.controls.lastWorkingDay.setValidators(null);
      this.lastWorkingDayRequired = false;
    }
    this.form.controls.lastWorkingDay.updateValueAndValidity(); 
  }

  year(){
    const startYear = 1970;
    const currentYear = new Date().getFullYear();
    let years=[];
    for (let year = currentYear; year >= startYear; year--) {
      years.push(year);
    }
    // this.toYear[0] = years;
    return years;
  }
  fromYearInput(event,i){
    this.fromYear[i] =event;
    const educationList = this.form.get('educationList') as FormArray;  
    educationList.controls[i].get('toYear').setValue(null);
    this.cdr.detectChanges();
  }
  toYearInput(event,i){
    this.toYear[i] =event;
    this.cdr.detectChanges();
  }
  fromMonthsInput(event,i){
    const index = this.months.indexOf(event);
    const educationList = this.form.get('educationList') as FormArray;  
    educationList.controls[i].get('toMonth').setValue(null);
    this.toMonths[i] =index;
    this.cdr.detectChanges();
  }

  masterAll() {
    this.subs$ = this._commanServcietailse.getCountry().pipe(
      switchMap((country) => {
        this.masterCounrty = country?.payload.filter((i) => i.isDelete !== true&&i.status===true);
        return this._commanServcietailse.getLocationAll();
      }),
      switchMap((location) => {
        this.masterLocation = location?.payload?.locations.filter((i) => i.isDelete !== true);
        return this._commanServcietailse.getEmployeeList();
      }),
      switchMap((source) => {
        this.masterSource = source.payload?.baseUserList.filter((i) => i.isDelete !== true);
        return this._commanServcietailse.getdepartmentAll();
      }),
      switchMap((department) => {
        this.masterDepartment = department.payload?.departments.filter((i) => i.isDelete !== true);
        return this._commanServcietailse.getIndustryAll();
      }),
      switchMap((industry) => {
        this.masterIndustry = industry.payload.industrys.filter((i) => i.isDelete !== true&& i.status===true);
        return this._commanServcietailse.getEducationAll();
      }),
      switchMap((education) => {
        this.masterEducation = education.payload?.educations.filter((i) => i.isDelete !== true&&i.status==true);
        return this._commanServcietailse.getCertificationsAll();
      }),
      switchMap((certifications) => {
        this.masterCertification = certifications.payload?.certifications.filter((i) => i.isDelete !== true);
        return this._commanServcietailse.offerDesignationAll();
      }),
      switchMap((offerDesignation) => {
        this.masterDesignation = offerDesignation.payload?.offerDesignations.filter((i) => i.isDelete !== true);
        return this._commanServcietailse.getCompanyList({
          "filterConditions": [],
          "page": 0,
          "size": 50,
          "sortConditions": []
        });
        
    // this.subs$ = designation.subscribe((masterDesignation)=>{
    //   this.masterDesignation =masterDesignation.payload?.designations.filter(i => i.isDelete !== true);
    // }, (error) => console.log(error));
      })
    ).subscribe((company) => {
      this.masterCompany = company.payload?.items.filter((i) =>  i.status === true && i.isDelete !== true);
  
      if (this.id != '' && this.id != undefined) {
        this.getCandidateDataById(this.id);
      }
    }, (error) => console.log(error));
  }
  //submit form
  onSubmit(){
    let error:boolean = false
    this.isSubmit=false;
    if(this.resumefiles == '' || this.resumefiles == null || this.resumefiles == undefined){
      error = true;
      this.iSCvNeeded = true;
    }else{
      if (this.form.valid) {
        const payload: any = this.form.value;
        payload.emailId=this.emailValue
        payload.resume=this.resumefiles
        if(this.candiadteData != ''){
          payload.ctid =this.candiadteData?.ctid
          payload.cid  =this.candiadteData?.cid
        }
        if(payload.lastWorkingDay != null && payload.lastWorkingDay !== "Invalid date" && payload.lastWorkingDay != ""){
          payload.lastWorkingDay =  moment(payload.lastWorkingDay).format("DD-MM-YYYY h:mm:ss" );
        }else{
          payload.lastWorkingDay  = "";
        }
        payload.educationList.forEach(res => {
          res.college = (res.college === "") ? null : res.college;
          if(res.fromYear != null &&  res.toYear != null){
            if(res.fromYear > res.toYear){
              error = true;
              Swal.fire(
                'Incorrect education period!',
                '',
                'error'
              );
            }
          }
          if( res.fromMonth != null &&  res.toMonth != null){
            const fromMonthValue = this.months.indexOf(res.fromMonth);
            const toMonthValue = this.months.indexOf(res.toMonth);
            if(res.fromYear == res.toYear && fromMonthValue > toMonthValue){
              error = true;
              Swal.fire(
                'Incorrect education period!',
                '',
                'error'
              );
            }
          }
        });
        payload.educationList = payload.educationList.filter(item => {
          return Object.values(item).some(value => (value !== null));
        });
        if(error == false){
          this.subs$ = this._commanServcietailse.candidateEmailForm(payload).pipe().subscribe((res)=>{
            if(res.status == CONSTANTS_TEXT.SUCCESS){   
              this.isSubmit=true;
              Swal.fire({
                title: 'Thanks!',
                text: 'Your application is now in our system, if your qualifications align with our current active roles, we will connect with you to discuss the next steps in the process.',
                icon: 'success',
                confirmButtonText: 'OK'
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.href = 'https://theglove.co.in/';
                }
              
              });
              
                        
            }else if(res.status == 'EXCEPTION'){
              Swal.fire(
                res?.message,
                '',
                'error'
              )
            }
          },(error) => {
            this.isSubmit=false;
            Swal.fire(
              error.error?.message,
              '',
              'error'
            )
            this._LocalstorageService.showmessage(CONSTANTS_TEXT.error, error.error)})
        }
      }
    }
    
  }
  setEducation(payload){
    // console.log('educationList>>> ',(payload.educationList));
    let educationList = [];
    if(payload.educationList.length > 0){
      payload.educationList.forEach((val)=>{
        const education =  {
          'college':null,
          'educationId': val,
          'fromMonth': null,
          'fromYear':null,
          'toMonth':null,
          'toYear':null
        };
        educationList.push(education);
      })
    }else{
      const education =  {
        'college':null,
        'educationId': '',
        'fromMonth': null,
        'fromYear':null,
        'toMonth':null,
        'toYear':null
      };
      educationList.push(education);
    }
    return educationList;
  }
  // fileUpload(data: File[]){
  //   this.files = data;
  //   var mimeType =data[0].type;
  //   const selectedFile = data[0];
  //   const current = new Date();
  //   const timestamp = current.getTime();
  //   this._commanServcietailse.getMediaMetaData('candidateResume', mimeType, selectedFile,'').pipe(finalize(()=>{})).subscribe((res:any)=>{
  //     if(res.url != undefined && res.url){
  //       this.resumefiles = res.url;
  //       this.cdr.detectChanges();
  //       {this._LocalstorageService.showmessage(CONSTANTS_TEXT.success, 'File uploaded successfully.')}
  //     }
  //   },(error) => {this._LocalstorageService.showmessage(CONSTANTS_TEXT.error, error)}
  //   )
  // }
  onInputChange(event: any): void {
    this.inputValue = event.target.value;
    this.inputName = event.target.value;
    if(this.inputValue.length>1){
      this.isName=true;
    }
    console.log("Full name---->",this.inputValue);
    
  }

  onInputChange2(event: any): void {
    this.inputMobile = event.target.value;
    if(this.inputValue.length>1){
      this.isMobileNumber=true;
    }
    console.log("Mobile---->",this.inputValue);
    
  }

  fileUpload(data: File[]){
    this.files = data;
    const selectedFile = data[0];
    const fileType2 = selectedFile.type.split('/').pop(); // Extract the last part after splitting by '/'
    console.log('File type:', fileType2);
    
      const fileType = selectedFile.name.substring(selectedFile.name.lastIndexOf('.'));
      if (this.allowedTypes.includes(fileType)) {
        if(selectedFile.size > 5000000){
          this.sizeMoreThan5mb=true;
          this.iSCvNeeded= false;
          return;
        }
        else{
          
          this.sizeMoreThan5mb=false
        }
        var mimeType =data[0].type;
        const fileExtension = mimeType.split('.').pop();
        let modifiedString = this.inputValue.replace(/\s+/g, '') + `.${fileExtension}`;
        let name = this.inputValue === "" ? selectedFile.name : modifiedString;
        const current = new Date();
        const timestamp = current.getTime();
        this.uploadmediaFile(fileType, selectedFile,timestamp,name);
        this.iSCvNeeded = false;
        this.cdr.detectChanges();
      } else {
        this._LocalstorageService.swal('Invalid file type. Please select a PDF,JPG,,JPEG,PNG or MS Word document.', 'info')
      }
  }
  uploadmediaFile(mimeType, selectedFile,timestamp,name){
    this._commanServcietailse.getMediaMetaData('candidateResume', mimeType, selectedFile,name,this.inputMobile,'candidateForm').pipe(finalize(()=>{})).subscribe((res:any)=>{
      if(res.url != undefined && res.url){
        // this._LocalstorageService.swal('File Upload Success', 'success')
        // this.resumefiles = res.url;
        this.resumefiles=res.url
  
        // console.log(" base file name  >>",this.resumefiles);
        const urlParts = this.resumefiles.split('/');
        const lastPart = urlParts[urlParts.length - 1]; // Get the last part of the URL
        const fileExtension = lastPart.split('.').pop(); 
        const parts = name.split('.'); 
        const extension = parts.pop(); 
        const baseFilename = parts.join('.'); 
        let varName = baseFilename + "_" + this.inputMobile; 
        var exTen = extension.split('/');
        // var fileExtension = exTen[1];
        this.finalFileName = varName + "." + fileExtension; 
        
        this.cdr.detectChanges();
        {this._LocalstorageService.showmessage(CONSTANTS_TEXT.success, 'File uploaded successfully.')}
      }
    },(error) => {this._LocalstorageService.showmessage(CONSTANTS_TEXT.error, error)}
    )
  }
  openModalLoader(){
    const initialState = {
      list: [
          'Open a modal with component',
          'Pass your data',
          'Do something else',
          '...'
        ],
        title: 'Modal with component'
    };
    this.modalRef = this.modalService.show(ModalLoaderComponent, { initialState });
    this.modalRef.content.closeBtnName = 'Close';
  }

  openModal(response:any,email:any) {
    const initialState = {
      email: email,
      list: [
        'Open a modal with component',
        'Pass your data',
        'Do something else',
        '...'
      ],
      title: 'Modal with component',
      status:this.status
    };
    this.bsModalRef = this.modalService.show(ModalContentComponent, {initialState});
    this.bsModalRef.content.statusUpdated.subscribe((updatedStatus: boolean) => {
      this.status = updatedStatus;
      this.submitStatus=true;
      this.form.controls.emailId.disable();
      this.modalRef?.hide();

    });
  }
  onEmailInput(emailValue: string) {
    this.hasTyped = true;
    console.log('hasTyped',this.hasTyped);
    
}
  
  onEmailInputBlur(email: string): void {
    this.isOtpValid=false;
    console.log('onEmailInputBlur -->');

    if(this.form.controls.emailId.status != "INVALID"){
      this.emailValue = email;
      // this.email = email;
      if (this.hasTyped) {
        this.hasTyped = false; 

        this.subs$ = this._commanServcie.getCandidateSendOtp(email).subscribe((response: any) => {
          console.log(response);
          
          if (response.payload==="otp sent") {
            this.isOtpValid=false;
            
            this.openModal(response,this.emailValue);
          }
          else{
            console.log("jkn");
            this._LocalstorageService.showmessage(CONSTANTS_TEXT.error, response.message)
            this.isOtpValid=true;
            
          }
        },(error) => {
          // this.openModalLoader
        });

      }   
    }else{
      Swal.fire(
        'Invalid Email ID?',
        '',
        'info'
      )
    }
  }
  

  deleteFile(file){
    this.files = []
    this.resumefiles = '';
    this.finalFileName = '';
    this.cdr.detectChanges();
  }
  resumeUrl(url){
    window.open(url);
 }
 verifyOtp(){
  
 }
 setFormData(candidateData){
    let certifications:any = [];
    let educationList=[];
    if(candidateData.education.certifications != null && candidateData.education.certifications != undefined){
      candidateData.education.certifications.forEach(res=>{
        certifications.push(res.id);
      })
    }
    if(candidateData.education.educationList.length > 0 && candidateData.education.educationList != undefined){
      candidateData.education.educationList.forEach(res=>{
        educationList.push(res.education.eduid);
      })
    }
    console.log('this.inputValue',this.inputValue);
    this.inputValue=this.inputValue===""?candidateData?.firstName + (candidateData.lastName ? ' '+ candidateData?.lastName : ''):this.inputValue
    console.log(this.inputValue);
    
    this.email = candidateData?.emailId;
    console.log('emailId',this.email);
    
    this.form.controls.firstName.setValue(candidateData?.firstName + (candidateData.lastName ? ' '+ candidateData?.lastName : ''))
    this.form.controls.lastName.setValue("")
    this.form.controls.emailId.setValue(candidateData?.emailId)
    console.log('candidateData?.emailId',candidateData?.emailId);
    
    this.form.controls.phoneNumber.setValue(candidateData.phoneNumber )
    this.form.controls.gender.setValue(candidateData.gender )
    this.form.controls.type.setValue(candidateData.type ? candidateData.type : null)
    this.form.controls.currentCTC.setValue(candidateData.currentCTC )
    // this.form.controls.expectedCTC.setValue(candidateData.expectedCTC )
    this.form.controls.noticePeriod.setValue(candidateData.noticePeriod )
    this.form.controls.countryCode.setValue(candidateData.countryCode==null?"+91":candidateData.countryCode)
    this.form.controls.alternateCountryCode.setValue(candidateData.alternateCountryCode==null?"+91":candidateData.alternateCountryCode)

    this.form.controls.currentLocation.setValue(candidateData.currentLocation ? candidateData.currentLocation.locId : null)
    this.form.controls.skills.setValue( candidateData.experience?.skills  )
    this.form.controls.industry.setValue(candidateData.experience ? candidateData.experience.industry?.indId  : null )
    // this.form.controls.types.setValue(candidateData?.types)
    this.form.controls.preferredLocation.setValue(candidateData.preferredLocation )
    this.form.controls.currentCompany.setValue(candidateData.experience.currentCompany ? candidateData.experience.currentCompany.cmpid: null )
    this.form.controls.certificationList.setValue(certifications)
    this.form.controls.lastWorkingDay.setValue(candidateData?.lastWorkingDay)
    this.form.controls.diversityInclusion.setValue(candidateData.diversityInclusion )
    this.form.controls.alternateEmailId.setValue(candidateData?.alternateEmailId)
    this.form.controls.alternateContactNumber.setValue(candidateData?.alternateContactNumber)
    this.form.controls.totalExperience.setValue(candidateData.experience?.totalExperience)
    this.form.controls.variableCTC.setValue(candidateData?.variableCTC)
    this.form.controls.ccCode.setValue(candidateData.country ? candidateData.country.idCode : null)
    this.form.controls.lastDesignation.setValue(candidateData.experience ? candidateData.experience.lastDesignation?.id  : null )
    this.form.controls.careerSynopsis.setValue(candidateData?.careerSynopsis)
    this.form.controls.emailId.disable();
    const fb:any = this.form.get("educationList") as FormArray
    fb.removeAt();
    if(candidateData.education.educationList.length > 0){
      this.updateEduction(candidateData.education.educationList)
    }else{
      this.education().push(this.addeduction());
    }
    
  }
  updateEduction(educationData){
    if(educationData.length != undefined && educationData.length > 0){
      educationData.forEach((val)=>{
        this.education().push(this.patchValues(val))
      })
    }else{
      this.education().push(this.addeduction())
    }
  }
  // educationId:[data.education ? data.education.eduid : null,],
  //     fromYear:[data.fromYear ? data.fromYear :  null,],
  //     fromMonth:[data.fromMonth ? data.fromMonth :  null],
  //     toYear:[data.toYear ? data.toYear :  null],
  //     toMonth:[data.toMonth ? data.toMonth :  null],
  //     college:[data.college ? data.college :  null]
  patchValues(data){
    return this.fb.group({
      educationId:[data.education ? data.education.eduid : null,],
      fromYear:[data.fromYear ? data.fromYear :  null],
      fromMonth:[data.fromMonth ? data.fromMonth :  null],
      toYear:[data.toYear ? data.toYear :  null,],
      toMonth:[data.toMonth ? data.toMonth :  null],
      college:[data.college ? data.college :  null]
    })
  }
  privacyAndPolicy(event){
    this.privacyAndPolicyValue = event?.checked;
  }
  link(){
    let url = '/#/'+ RoutePath.privacypolicy;
    window.open(url, '_blank');
  }
  ngOnDestroy() {
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializingSubscription.unsubscribe();
    this.initializedSubscription.unsubscribe();
    this.initializationErrorSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }
}


