<div class="modal-header">
  <h4 class="modal-title">Verify OTP</h4>
  <button type="button" class="close" (click)="closeAllModals()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body text-center">
  <div class="spinner"*ngIf="isLoading"></div>
 
  <div class="form-group mb-5" *ngIf="!isLoading">
    <label for="otpInput">OTP</label>
    <div id="otp" class="flex justify-center">
      <ng-otp-input  (onInputChange)="onInputBlur($event)" [config]="{length:6}"></ng-otp-input>
    </div>
  </div>
  <div class="error" *ngIf="isMessage == true"><h4 style="color: red;">{{message}}</h4></div>
</div>
<div class="modal-footer">
  <button type="button"  class="btn btn-secondary" (click)="resend()">Resend</button>
  <button type="button"  class="btn btn-primary" (click)="verifyOtp()">Verify</button>
</div>


