<div class="footer-content">
    <p class="mb-0">Powered by 
        <span class="d-none d-sm-inline-block"></span>
        <span class="d-none d-sm-inline-block mx-1">|</span>
        <br class="d-sm-none" />ATS.
        <a class="mx-1" href="#" target="_blank"> <img src="./../../../../assets/images/logo/cropped-ksquare.png" style="height: 21px;" alt="Ksquare"></a>
    </p>
    <span>
        <a href="" class="text-gray">v1.0.0</a>
    </span>
</div>