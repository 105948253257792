import { RoutePath } from '@app/layout/config';
import { NavMenu } from '@app/shared/types/nav-menu.interface';


const subMenuWithTitle: NavMenu[] = [

            {
                path: '/dashboard',
                title: 'Dashboard',
                translateKey: 'NAV.DASHBOARD',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-home',
                key: 'dashboard',
                submenu: []
            },
            {
                path: RoutePath.Client,
                title: 'Client',
                translateKey: 'NAV.CLIENT',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user',
                key: 'client',
                submenu: []
            },
            {
                path:RoutePath.Requirement,
                title: 'Requirement',
                translateKey: 'NAV.REQUIREMENT',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-plus-square',
                key: 'requirement',
                submenu: []
            },
            // {
            //     path: '/contract',
            //     title: 'Contract',
            //     translateKey: 'NAV.CONTRACT',
            //     type: 'item',
            //     iconType: 'feather',
            //     icon: 'icon-file-text',
            //     key: 'contract',
            //     submenu: []
            // },
            {
                path: '',
                title: 'Candidate',
                translateKey: 'NAV.CANDIIDATE',
                type: 'collapse',
                iconType: 'feather',
                icon: 'icon-users',
                key: 'submenu-with-title.menu',
                submenu: [
                    {
                        path: '/candidate/create',
                        title: 'Create',
                        translateKey: 'NAV.CANDIIDATE-CREATE',
                        type: 'item',
                        iconType: '',
                        icon: '',
                        key: 'submenu-with-title.menu.submenu-1',
                        submenu: []
                    },
                    {
                        path: '/candidate',
                        title: 'Candidate List',
                        translateKey: 'NAV.CANDIIDATE-CANDIIDATE-LIST',
                        type: 'item',
                        iconType: '',
                        icon: '',
                        key: 'submenu-with-title.menu.submenu-2',
                        submenu: []
                    },
                   
                ]
            },
            {
                path: '/closure',
                title: 'Closure',
                translateKey: 'NAV.CLOSURE',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-briefcase',
                key: 'closure',
                submenu: []
            },
            {
                path: '/assignment',
                title: 'Assignment',
                translateKey: 'NAV.ASSIGNMENT',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-clipboard',
                key: 'assignment',
                submenu: []
            },
            {
                path: '/role',
                title: 'Role',
                translateKey: 'NAV.ROLE',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-life-buoy',
                key: 'role',
                submenu: []
            },
            {
                path: '/employee',
                title: 'Employee',
                translateKey: 'NAV.EMPLOYEE',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user',
                key: 'employee',
                submenu: []
            },
            {
                path: '',
                title: 'Master',
                translateKey: 'NAV.MASTER',
                type: 'collapse',
                iconType: 'feather',
                icon: 'icon-bookmark',
                key: 'submenu-with-title.menu',
                submenu : [
                    // {
                    //     path: '/master/labels',
                    //     title: 'Submenu 1',
                    //     translateKey: 'NAV.MASTER-LABELS',
                    //     type: 'item',
                    //     iconType: '',
                    //     icon: '',
                    //     key: 'submenu-with-title.menu.submenu-1',
                    //     submenu: []
                    // },
                    {
                        path: '/master/country',
                        title: 'Submenu 2',
                        translateKey: 'NAV.MASTER-COUNTRY',
                        type: 'item',
                        iconType: '',
                        icon: '',
                        key: 'submenu-with-title.menu.submenu-2',
                        submenu: []
                    },
                    {
                        path: '/master/state',
                        title: 'Submenu 2',
                        translateKey: 'NAV.MASTER-STATE',
                        type: 'item',
                        iconType: '',
                        icon: '',
                        key: 'submenu-with-title.menu.submenu-2',
                        submenu: []
                    },
                    {
                        path: '/master/city',
                        title: 'Submenu 2',
                        translateKey: 'NAV.MASTER-CITY',
                        type: 'item',
                        iconType: '',
                        icon: '',
                        key: 'submenu-with-title.menu.submenu-2',
                        submenu: []
                    },
                    {
                        path: '/master/industry',
                        title: 'Submenu 2',
                        translateKey: 'NAV.MASTER-INDUSTRIES',
                        type: 'item',
                        iconType: '',
                        icon: '',
                        key: 'submenu-with-title.menu.submenu-2',
                        submenu: []
                    },
                    {
                        path: '/master/qualification',
                        title: 'Submenu 2',
                        translateKey: 'NAV.MASTER-QUALIFICATIONS',
                        type: 'item',
                        iconType: '',
                        icon: '',
                        key: 'submenu-with-title.menu.submenu-2',
                        submenu: []
                    },
                    {
                        path: '/master/institute',
                        title: 'Submenu 2',
                        translateKey: 'NAV.MASTER-INSTIUTES',
                        type: 'item',
                        iconType: '',
                        icon: '',
                        key: 'submenu-with-title.menu.submenu-2',
                        submenu: []
                    },
                    {
                        path: '/master/certification',
                        title: 'Submenu 2',
                        translateKey: 'NAV.MASTER-CERTIFICATIONS',
                        type: 'item',
                        iconType: '',
                        icon: '',
                        key: 'submenu-with-title.menu.submenu-2',
                        submenu: []
                    },
                    {
                        path: '/master/department',
                        title: 'Submenu 2',
                        translateKey: 'Department',
                        type: 'item',
                        iconType: '',
                        icon: '',
                        key: 'submenu-with-title.menu.submenu-2',
                        submenu: []
                    },
                    {
                        path: '/master/interview',
                        title: 'Submenu 2',
                        translateKey: 'NAV.MASTER-INTERVIEW-TYPE',
                        type: 'item',
                        iconType: '',
                        icon: '',
                        key: 'submenu-with-title.menu.submenu-2',
                        submenu: []
                    },
                    {
                        path: '/master/location',
                        title: 'Submenu 2',
                        translateKey: 'Location',
                        type: 'item',
                        iconType: '',
                        icon: '',
                        key: 'submenu-with-title.menu.submenu-2',
                        submenu: []
                    },
                    {
                        path: '/master/designation',
                        title: 'Submenu 2',
                        translateKey: 'Designation',
                        type: 'item',
                        iconType: '',
                        icon: '',
                        key: 'submenu-with-title.menu.submenu-2',
                        submenu: []
                    },
                    {
                        path: '/master/candidaterole',
                        title: 'Submenu 2',
                        translateKey: 'Candidate Role',
                        type: 'item',
                        iconType: '',
                        icon: '',
                        key: 'submenu-with-title.menu.submenu-2',
                        submenu: []
                    },
                    {
                        path: '/master/education',
                        title: 'Submenu 2',
                        translateKey: 'Education',
                        type: 'item',
                        iconType: '',
                        icon: '',
                        key: 'submenu-with-title.menu.submenu-2',
                        submenu: []
                    },
                    {
                        path: '/master/company',
                        title: 'Submenu 2',
                        translateKey: 'Company',
                        type: 'item',
                        iconType: '',
                        icon: '',
                        key: 'submenu-with-title.menu.submenu-2',
                        submenu: []
                    },
                    {
                        path: '/master/experience-level',
                        title: 'Submenu 2',
                        translateKey: 'Experience-Level',
                        type: 'item',
                        iconType: '',
                        icon: '',
                        key: 'submenu-with-title.menu.submenu-2',
                        submenu: []
                    },
                    {
                        path: '/master/functional-area',
                        title: 'Submenu 2',
                        translateKey: 'Functional-Area',
                        type: 'item',
                        iconType: '',
                        icon: '',
                        key: 'submenu-with-title.menu.submenu-2',
                        submenu: []
                    }
                ],
            },
]

export const navConfiguration: NavMenu[] = [
    // ...dashboard,
    // ...navWithSubMenu,
    // ...menuWithTitle,
    ...subMenuWithTitle
]