import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subscription, map } from 'rxjs';
import { RoutePath } from '../config/router.config';
import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  subs$!: Subscription;
  constructor(private router:Router,
    private authService: AuthService,){

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let token = localStorage.getItem('access_token');
    let tokenchecked = true;
    this.subs$ =  this.authService.verifyUserToken(token).pipe().subscribe((adminresponse:any)=>{
      if(adminresponse.status == 'SUCCESS'){
        return true;
      }else{
        return false;
      }
      
    },(error) => {
      // this.router.navigate([RoutePath.auth]);
      localStorage.clear();
      return false;
    });
    if(!token){
      this.router.navigate([RoutePath.auth]);
      return false
    }else{
      return true
    }
 }
}
