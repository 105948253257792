import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from '@app/layout/service/common.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { IncorrectPasswordComponent } from '../incorrect-password/incorrect-password.component';
import { ModalLoaderComponent } from '../modal-loader/modal-loader.component';
import { log } from 'console';

@Component({
  selector: 'app-modal-content',
  templateUrl: './modal-content.component.html',
  styleUrls: ['./modal-content.component.css']
})
export class ModalContentComponent implements OnInit {
  @Input() email: any;
  @Input() status:boolean;
  @Output() statusUpdated = new EventEmitter<boolean>();
  title: string;
  closeBtnName: string;
  modalRef: BsModalRef;
  isLoading:boolean=false
  subs$!: Subscription;
  number:any;
  isLoder:boolean=true;
  message:any = ''
  isMessage:boolean = false
  constructor(public bsModalRef: BsModalRef,
    private _commanServcie:CommonService,
    private modalService: BsModalService,
    private cdr: ChangeDetectorRef,

    ) {}
 
  ngOnInit() {
    console.log("modalRef in ngOnInit token:-"+JSON.stringify(this.bsModalRef))
    console.log("Email in ngOnInit token:-"+JSON.stringify(this.email))
    this.sendOpt(this.email);
  }
  onInputBlur(number:any){
    this.number=number;
  }
  sendOpt(email){
    console.log("send otp email :-"+JSON.stringify(email));
    this.isLoder = true;
      if (email && email.errorCode === 0) {   
        this.isLoder = false;   
        this.cdr.detectChanges();
    }
  }
  resend(){
    this.isLoder = true;
    this.subs$ = this._commanServcie.getCandidateSendOtp(this.email).subscribe(
      (response: any) => {
        if (response && response.errorCode === 0) {
          this.isLoder = false;   
        }
      })
  }
  closeAllModals() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }
  verifyOtp(){ 
    this.isMessage = true;
    const email = this.email;
    console.log("Email in verify token:-"+JSON.stringify(this.email))
    const otp = this.number;
    this.subs$ = this._commanServcie.getcandidateVeifyOtp(email,otp).subscribe(
      (response: any) => {
        if (response && response.payload === "success") {
          // this.openModal(); 
          this.status=true;
          this.statusUpdated.emit(true);
          this.closeAllModals();
        }
        else{
          this.message = 'Incorrect otp. Please try again.';
          this.statusUpdated.emit(false);

          setTimeout(() => {
            this.isMessage = false;
          }, 3000);
          this.cdr.detectChanges();
        }
      })
  }
}