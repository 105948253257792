<dropdown dropdownClass="header-nav-item-select nav-profile" (click)="navProfile.toggle($event)">
    <div class="toggle-wrapper d-flex align-items-center">
        <div class="center-circle">
            <!-- <strong *ngIf="user!=null" >{{user[0] | titlecase}}</strong> -->
             <strong>K</strong>
        </div>
        <!-- <span class="fw-bold1 mx-1">{{user | titlecase}}</span> -->
        <span class="fw-bold1 mx-1">Krishan Gandhi</span>
        <i class="feather icon-chevron-down"></i>
    </div>
    <dropdown-menu #navProfile alignment="right">
        <div class="nav-profile-header">
            <div class="d-flex align-items-center">
                <div class="center-circle1">
                    <strong>K</strong>
                </div>
                <div class="d-flex flex-column ms-1">
                    <span class="fw-bold text-dark">Krishan Gandhi</span>
                </div>
            </div>
        </div>
        <a href="javascript:void(0)" dropdownItem *ngFor="let menuItem of profileMenuList">
            <div class="d-flex align-items-center gap-2" *ngIf="menuItem.route != undefined">
                <a [routerLink]="menuItem.route">
                    <i class="font-size-lg" [ngClass]="menuItem.icon"></i>
                    <span>{{menuItem.item}}</span>
                </a>
            </div>
            <div class="d-flex align-items-center gap-2" *ngIf="menuItem.route == undefined">
                <i class="font-size-lg" [ngClass]="menuItem.icon"></i>
                <span>{{menuItem.item}}</span>
            </div>
        </a>
        <a href="javascript:void(0)" dropdownItem (click)="logout()">
            <div class="d-flex align-items-center gap-2">
                <i class="font-size-lg" [ngClass]="'feather icon-power'"></i>
                <span>Log Out</span>
            </div>
        </a>
    </dropdown-menu>
    
</dropdown>
