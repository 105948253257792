import { Injectable,EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { RoutePath } from '../config';
import { CONSTANTS_TEXT } from 'src/app/layout/const/app.constant'
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {
  constant = CONSTANTS_TEXT;
  message : string = '';
  rowData: any = [];
  type:any = '';
  IsShow:boolean=false
  invokeFirstComponentFunction = new EventEmitter(); 
  constructor(
    private router:Router,
    private _location: Location,
    private tost :ToastrService
    ) {}
    // message show tostor function
    showmessage(type:string, message:string){
      this.showMessage(type,message);
      // this.invokeFirstComponentFunction.emit({'type':type, 'message':message }); 
    }

    backpage(){
      this._location.back();
    }
    // logout
    logout(){
      localStorage.clear();
      this.showmessage(CONSTANTS_TEXT.success,CONSTANTS_TEXT.logoutSucces)
      this.router.navigate([RoutePath.Empty])
    }

    showMessage(type:any ,message ){
      this.message = '';
      if(message){ 
        var type =  type;
        this.message = message;
      
        if(type == this.constant.info){
          this.type = 'info';
          this.tost.info(this.message),({
            timeOut: 3000,
          }); 
        }else if(type == this.constant.error){
          this.type = 'danger';
          this.tost.error(this.message),({
            timeOut: 3000,
          }); 
        }else if(type == this.constant.success){
          this.type = 'success';
          this.tost.success(this.message),({
            timeOut: 3000,
          }); 
        }else if(type == this.constant.warning){
          this.type = 'warning';
          this.tost.warning(this.message),({
            timeOut: 3000,
          }); 
        }
      }
    }
    swal(message,type){
      Swal.fire(
        message,
        '',
        (type ? type : 'info')
      )
    }

}
