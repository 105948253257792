import { Routes } from '@angular/router';
import { RoutePath } from '@app/layout/config';

export const APP_LAYOUT_ROUTES: Routes = [
    //Dashboard
    {path: 'dashboard', loadChildren: () => import('../views/dashboard/dashboard.module').then(m => m.DashboardModule)},
    {path:RoutePath.Client, loadChildren: () => import('../views/client/client.module').then(m => m.ClientModule)},
    {path:RoutePath.employee, loadChildren: ()=> import('../views/employee/employee.module').then(m => m.EmployeeModule)},
    {path:RoutePath.role, loadChildren: ()=> import('../views/role/role.module').then(m => m.RoleModule)},
    {path:RoutePath.master, loadChildren: ()=> import('../views/master/master.module').then(m => m.MasterModule)},
    {path:RoutePath.profile, loadChildren: ()=> import('../views/myprofile/myprofile.module').then(m => m.MyprofileModule)},
    {path:RoutePath.changePassword, loadChildren: ()=> import('../views/change-password/change-password.module').then(m => m.ChangePasswordModule)},
    {path:RoutePath.Requirement, loadChildren: ()=> import('../views/requirement/requirement.module').then(m => m.RequirementModule)},
    {path:RoutePath.assignment, loadChildren: ()=> import('../views/assignment/assignment.module').then(m => m.AssignmentModule)},
    {path:RoutePath.candidate, loadChildren: ()=> import('../views/candidate/candidate.module').then(m => m.CandidateModule)},
    {path:RoutePath.closure, loadChildren: ()=> import('../views/closure/closure.module').then(m => m.ClosureModule)},
];