import { Component, OnInit,TemplateRef ,Injectable} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from '@app/layout/service/common.service';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-view-document',
  templateUrl: './view-document.component.html',
  styleUrls: ['./view-document.component.css']
})
export class ViewDocumentComponent implements OnInit {
  jdReqDoc: any;

  constructor( private modalService: BsModalService,
    private router:Router,
    private _commanServcie:CommonService,
    private sanitizer: DomSanitizer) {
    
   }
  params:any;
  modalRef:any;
  resume:any='';
  ngOnInit(): void {
  }

  agInit(params: any): void {

    this.params = params?.data;

    if (this.params) {
        const reqDocDataList = this.params.requirementManagementDocDataList;

        if (reqDocDataList && reqDocDataList.length > 0) {
            this.jdReqDoc = reqDocDataList[0].name;
        }

        if (this.params.resume) {
            this.resume = this.sanitizeUrl(this.params.resume);
            console.log('this.resume >> ', this.resume);
        } else if (reqDocDataList && reqDocDataList.length > 0) {
            this.resume = this.sanitizeUrl(reqDocDataList[0].name);
        }
    }
}
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  closeModal() {
    this.modalRef.hide();
  }
  sanitizeUrl(url: string): SafeResourceUrl {
    if(url != undefined){
      let checkExtension = this.hasDocxOrDocExtension(url)
      if(checkExtension == true){
        url='https://docs.google.com/gview?url='+url+ "&embedded=true";
      }
      let file =  this.sanitizer.bypassSecurityTrustResourceUrl(url);
      return file;
      
    }
  }
  hasDocxOrDocExtension(fileUrl){
    if(fileUrl != undefined){
      const url = fileUrl
      return url.includes('.docx') || url.includes('.doc');
    } 
  }
}
