import { ChangeDetectorRef, Component, OnInit, ViewChild, } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HomeService } from '../_services/home.service';
import { LoadingService } from '../_services/loading.service';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, finalize, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AmountDialogComponent } from '../Amount-dialog/amount-dialog.component';
import { MatSelectChange } from '@angular/material/select';
import { Observable, of } from 'rxjs';
import { ErrorMessageService } from '../services/error-message.service';
import Swal from 'sweetalert2';
import { environmentbasic } from '../../environments/environment-basic';
@Component({
  selector: 'app-add-lead',
  templateUrl: './add-lead.component.html',
  styleUrls: ['./add-lead.component.scss']
})
export class AddLeadComponent implements OnInit {
  selectedFiles: File[] = [];
  selectedFileNames: string[] = [];
  uploadForm: FormGroup;
  myForm: FormGroup;
  imageItems: any[] = [
    {
      constrolName: 'imageUrls',
      purpose: 'professionalDocument',
      index: 0,
      url: '',
      type: 'image',
      controlArray: true,
      loading: false,
      linkadd: false,
    },
  ];
  uploadedImages: any[] = [];
  professions = [];
  expertises = [];
  stateId: number;
  stateName: string;
  stateList: any[] = [];
  cityDataList: any[] = [];
  id: string;
  listingData: any;
  expiryDate: any;
  paramId: any;
  paramsData: any;
  selectedProfessionId: any;
  constructor(private fb: FormBuilder,
    private homeService: HomeService,
    private loadingService: LoadingService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private errorMessageService:ErrorMessageService

  ) { }


  triggerFileInput(): void {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }
  uploadFiles(): void {
    if (this.selectedFiles.length) {
      const fileUploads$ = this.selectedFiles.map((selectedFile, i) => {
        const mimeType = selectedFile.type;
        const purpose = 'professionalDocument'; // Always set to 'professionalDocument'

        return this.homeService.getMediaData(purpose, mimeType, selectedFile)
          .pipe(finalize(() => { this.imageItems[i].loading = false; }))
          .subscribe((res: any) => {
            this.uploadedImages.push(res.url);
            this.cd.detectChanges();
          });
      });

      this.clearFileInput();
    }
  }

  openFile(index: number): void {
    const fileUrl = this.uploadedImages[index];
    window.open(fileUrl, '_blank');
  }

  // handleChange(event: any): void {
  //   const files = event.target.files;
  //   if (files && files.length) {
  //     for (let i = 0; i < files.length; i++) {
  //       const file = files[i];
  //       const validFileTypes = ['application/pdf', 'application/msword',
  //          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

  //       if (validFileTypes.includes(file.type)) {
  //         this.selectedFiles.push(file);
  //         this.uploadedImages.push(file);
  //         console.log('selectedFileNames', this.selectedFileNames);

  //         this.imageItems.push({ loading: true, purpose: 'document' });
  //       } else {
  //         alert('Only PDF or DOC files are allowed.');
  //       }
  //     }
  //     this.cd.detectChanges();
  //   }
  // }
  handleChange(event: any): void {
    const files = event.target.files;
    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        this.selectedFiles.push(files[i]);
        this.selectedFileNames.push(files[i].name);
        this.imageItems.push({ loading: true, purpose: 'professionalDocument' });
      }
      this.cd.detectChanges();
    }
  }

  clearFileInput(): void {
    this.selectedFiles = [];
    this.selectedFileNames = [];
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
    this.cd.detectChanges();
  }


  removeImage(index: number): void {
    this.uploadedImages.splice(index, 1);
    this.selectedFiles.splice(index, 1);
    this.selectedFileNames.splice(index, 1);
    this.imageItems.splice(index, 1);
    this.cd.detectChanges();
  }


  ngOnInit(): void {
    const emptysearch =''
    this.getCitybyCountryId(emptysearch);
    const currentDate = new Date();
    const nextMonthDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));

    this.myForm = this.fb.group({
      title: [''],
      amount: [''],
      status: [''],
      professionId: [null, Validators.required],
      expertiseId: [null],
      description: [''],
      expiredAt: [nextMonthDate],
      address: [''],
      name: [''],
      email: [''],
      mobileNumber: [''],
      partyAddress: [''],
      cityId: [''],
      stateId: [''],
      uploadPdf: ['']
    });

    let UserData = localStorage.getItem('UserProfile');

    // if (UserData) {
    //   const userDataObject = JSON.parse(UserData);
    //   const expertise = userDataObject.payload.expertise;
    //   const profession = userDataObject.payload.professions
    //   console.log('expertise', expertise);


    //   if (profession) {
    //     const professionId = profession.id;
    //     console.log('ProfessionId:', professionId);

    //     // Set the professionId in the form
    //     this.myForm.get('professionId')?.setValue(professionId);

    //     // Fetch and set expertiseId
    //     this.fetchAndSetExpertises(professionId);
    //   }

    // }

    // this.getCitybyCountryId();
    this.fetchAllProfession();

    this.route.queryParams.subscribe((params) => {
      this.id = params['id'];
      if (this.id) {
        this.getLead();
      } else {
        console.error('No Propertyid found in query params');
      }
    });
  }

  fetchAllExpertiess(id: string): Observable<any> {
    return this.homeService.fetchidExperties(id).pipe(
      tap((response: any) => {
        if (response && response.payload) {
          this.expertises = response.payload;
          console.log('Expertises:', this.expertises);
        } else if (response['message']) {
          // Handle error message from the response
          const errorMessage = this.errorMessageService.getErrorMessage(response['message']);
          Swal.fire({
            icon: 'error',
            title: errorMessage,
            showConfirmButton: false,
            timer: environmentbasic.timer
          });
          this.expertises = [];
        } else {
          console.error('Unexpected data format:', response);
          this.expertises = [];
        }
        this.cd.detectChanges(); // Trigger change detection if needed
      }),
      catchError((error) => {
        console.error('Error fetching expertises:', error);
        this.expertises = [];
        return of([]); // Return an empty array in case of error
      })
    );
  }


  openAmountDialog() {
    const amount = this.myForm.get('amount')?.value;

    if (amount !== null && amount !== '' && amount !== undefined) {
      const professionId = this.myForm.get('professionId')?.value;
      const expertiseId = this.myForm.get('expertiseId')?.value;

      this.dialog.open(AmountDialogComponent, {
        width: '40%',
        height: '55%',
        data: {
          professionId,
          expertiseId,
          amount
        }
      });
    }
  }


  transformPayload(formValue: any): any {
    return {
      address: formValue.address,
      description: formValue.description,
      expertiseId: formValue.expertiseId,
      partyDetail: {
        address: formValue.partyAddress,
        email: formValue.email,
        mobileNumber: formValue.mobileNumber,
        name: formValue.name
      },
      professionId: formValue.professionId,
      status: formValue.status,
      amount: formValue.amount,
      title: formValue.title,
      expiredAt: formValue.expiredAt ? new Date(formValue.expiredAt).getTime() : null,
      // expiredAt:formValue.expiredAt,
      cityId: formValue.cityId,
      stateId: formValue.stateId,
      imageUrls: this.uploadedImages

    };
  }

  fetchAllProfession() {

    this.homeService.fetchAllProfession().subscribe(
      (response: any) => {
        if (response && response.payload) {
          this.loadingService.hide();
          this.professions = response.payload;
        } else {
          console.error('Unexpected data format:', response);
          this.professions = [];

        }
      },
      error => {
        this.loadingService.hide();
        console.error('Error loading listings:', error);
        this.professions = [];
      }
    );
  }

  onProfessionSelect(event: MatSelectChange) {
    this.selectedProfessionId = event.value;
    console.log('Selected Profession ID:', this.selectedProfessionId);
    this.fetchAllExperties(this.selectedProfessionId);
    this.cd.detectChanges();
  }
  fetchAllExperties(id) {

    this.homeService.fetchidExperties(id).subscribe(
      (response: any) => {
        if (response && response.payload) {
          this.loadingService.hide();
          this.expertises = response.payload;
          console.log('expertises', this.expertises);
          this.cd.detectChanges();
        } else {
          console.error('Unexpected data format:', response);
          this.expertises = [];

        }
        this.cd.detectChanges();

      },
      error => {
        this.loadingService.hide();
        console.error('Error loading listings:', error);
        this.expertises = [];
      }
    );
  }


  // getCitybyCountryId() {
  //   this.homeService.getCitybyCountryId().subscribe(
  //     (response: any) => {
  //       if (response.status === 'SUCCESS') {
  //         let fetchedCities = response.payload.cityDataList;

  //         // Define your top priority cities
  //         const topCities = ['Chandigarh', 'Delhi', 'Gurgaon', 'Ajmer', 'Jaipur', 'Rewari', 'Mumbai', 'Noida', 'Pune', 'Sirsa'];

  //         // Get the top cities in order
  //         const prioritizedCities = topCities
  //           .map(city => fetchedCities.find(c => c.name === city))
  //           .filter(city => city); // Remove undefined values

  //         // Get remaining cities (excluding already selected ones)
  //         const remainingCities = fetchedCities.filter(c => !topCities.includes(c.name));

  //         // Merge prioritized cities first, then add up to 190 more cities to make a total of 200
  //         this.cityDataList = [...prioritizedCities, ...remainingCities].slice(0, 500);

  //         console.log(this.cityDataList);
  //       } else {
  //         console.error('Failed to fetch city data:', response);
  //       }
  //     },
  //     (error) => {
  //       console.error('Error fetching city data:', error);
  //     }
  //   );
  // }





  getStateByCityId(cityId: number) {
    this.homeService.getStateByCityId(cityId).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          this.stateId = response.payload.stateId;
          this.stateName = response.payload.stateName;
          this.cd.detectChanges(); // Here

          this.myForm.patchValue({
            stateId: this.stateId,
          });
        } else {
          console.error('Failed to fetch state data:', response);
        }
      },
      (error) => {
        console.error('Error fetching state data:', error);
      }
    );
  }


  onSubmit() {
    const controls = this.myForm.controls;
    for (const i in controls) {
      if (controls.hasOwnProperty(i)) {
        controls[i].markAsDirty();
        controls[i].updateValueAndValidity();
      }
    }
    if (this.myForm.valid) {
      let payload = { ...this.transformPayload(this.myForm.value) };
      payload.cityId=payload.cityId.id
      if (!this.id) {
        delete payload.status;
      }
      console.log('payload', payload);
      console.log(this.id);
      if (this.id) {
        this.UpdateLead(payload, this.id);
      } else {
        this.Save(payload);
      }
    } else {
      console.log('error');
    }

  }

  Save(payload: any) {
    this.homeService.saveLead(payload).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          console.log('response', response);

          Swal.fire({
            icon: 'success',
            title: 'Lead added successfully!',
            showConfirmButton: false,
            timer: environmentbasic.timer
          }).then(() => {
            this.router.navigate(['/UserLeads']);
          });

        } else if (response['message']) {
          const errorMessage = this.errorMessageService.getErrorMessage(response['message']);
          Swal.fire({
            icon: 'error',
            title: errorMessage,
            showConfirmButton: false,
            timer: environmentbasic.timer
          });
        } else {
          console.error('Response status is not SUCCESS');
        }
      },
      (error) => {
        console.error('API call error', error);
        const errorMessage = error.error?.message
          ? this.errorMessageService.getErrorMessage(error.error.message)
          : 'An error occurred while saving. Please try again later.';

        Swal.fire({
          icon: 'error',
          title: errorMessage,
          showConfirmButton: false,
          timer: environmentbasic.timer
        });
      }
    );
  }

	onInput(event: Event): void {
		const input = event.target as HTMLInputElement;
		input.value = input.value.replace(/[^0-9]/g, ''); // Remove any non-digit characters
		if (input.value.length > 10) {
			input.value = input.value.slice(0, 10); // Limit to 10 digits
		}
	}
  UpdateLead(payload: any, id: any) {
    if (!payload.cityId) {
      payload.cityId = this.listingData.cityId;
    }
    this.homeService.UpdateLead(payload, id).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          console.log('response', response);
          this.id = response.payload.id;
          this.paramsData = response.payload;
          // this.router.navigate(['/UserLeads']);
          Swal.fire({
            icon: 'success',
            title: 'Lead update successfully!',
            showConfirmButton: false,
            timer: environmentbasic.timer
          }).then(() => {
            this.router.navigate(['/UserLeads']);
          });
        } else if (response['message']) {
          const errorMessage = this.errorMessageService.getErrorMessage(response['message']);
          Swal.fire({
            icon: 'error',
            title: errorMessage,
            showConfirmButton: false,
            timer: environmentbasic.timer
          });
        } else {
          console.error('Response status is not SUCCESS');
        }
      },
      (error) => {
        console.error('API call error', error);
        const errorMessage = error.error?.message
          ? this.errorMessageService.getErrorMessage(error.error.message)
          : 'An error occurred while updating. Please try again later.';

        Swal.fire({
          icon: 'error',
          title: errorMessage,
          showConfirmButton: false,
          timer: environmentbasic.timer
        });
      }
    );
  }


  getLead() {
    this.loadingService.show();
    this.homeService.getLeadById(this.id).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          this.loadingService.hide();
          this.listingData = response?.payload;
          console.log(this.listingData.professionId);

          this.stateId = response?.payload?.stateId;
          let selectedCityId = response?.payload?.cityId;
          if (selectedCityId) {
            this.getStateByCityId(selectedCityId);
          }
          this.uploadedImages = response?.payload?.mediaGroup.map(media => media.url);

          let expiredAt = response?.payload?.expiredAt;
          let dateObject = null;
          if (expiredAt) {
            const [day, month, year] = expiredAt.split(' ')[0].split('-').map(Number);
            dateObject = new Date(year, month - 1, day);
            console.log('Date Object', dateObject);
          } else {
            console.warn('expiredAt is null or undefined');
          }

          let ans = this.fetchAllExperties(this.listingData.professionId);

          this.myForm.patchValue({
            title: this.listingData.title || '',
            description: this.listingData.description || '',
            status: this.listingData.status || '',
            address: this.listingData.address || '',
            amount: this.listingData.amount || '',
            professionId: this.listingData.professionId || '',
            expertiseId: this.listingData.expertiseId || '',
            name: this.listingData.partyDetail?.name || '',
            email: this.listingData.partyDetail?.email || '',
            mobileNumber: this.listingData.partyDetail?.mobileNumber || '',
            partyAddress: this.listingData.partyDetail?.address || '',
            cityIdupadte: this.listingData.cityId || '',
            cityId: this.listingData.cityName || '',
            stateId: this.stateId || '',
            expiredAt: dateObject || '',
            uploadPdf: this.uploadedImages || ''
          });
          this.cd.detectChanges();
        } else if (response['message']) {
          this.loadingService.hide();
          const errorMessage = this.errorMessageService.getErrorMessage(response['message']);
          Swal.fire({
            icon: 'error',
            title: errorMessage,
            showConfirmButton: false,
            timer: environmentbasic.timer
          });
        } else {
          this.loadingService.hide();
          console.error('Response status is not SUCCESS');
          Swal.fire({
            icon: 'error',
            title: 'Failed to fetch lead data. Please try again later.',
            showConfirmButton: false,
            timer: environmentbasic.timer
          });
        }
      },
      (error) => {
        this.loadingService.hide();
        console.error('API call error', error);
        const errorMessage = error.error?.message
          ? this.errorMessageService.getErrorMessage(error.error.message)
          : 'An error occurred while fetching lead data. Please try again later.';

        Swal.fire({
          icon: 'error',
          title: errorMessage,
          showConfirmButton: false,
          timer: environmentbasic.timer
        });
      }
    );
  }




  onCitySearch(event: any) {
    const searchValue = event.target.value;
    if (searchValue.length > -1) {
      this.getCitybyCountryId(searchValue);
    } else {
      this.getCitybyCountryId(null);
    }
  }

    getCitybyCountryId(searchText: string) {
      this.homeService.searchCity(searchText).subscribe(
        (response: any) => {
          if (response.status === 'SUCCESS') {
            this.cityDataList = response.payload;
            console.log(this.cityDataList);
          } else {
            console.error('Failed to fetch city data:', response);
          }
        },
        (error) => {
          const message = error?.error?.message || 'You are not authorized to access this page';
          if (message) {
            let errorMessage = this.errorMessageService.getErrorMessage(message);
          }


        }
      );
    }
    sendid: any;

    displayCityName(city: any): string {
      console.log('gcd>>>>>>>>>>>>>>>>>>>>>>>>>>', city);
      this.sendid = city.id; // ID assign karna
      // this.getStateByCityId(this.sendid); // Uncomment if needed
      if (!city) return '';
      return typeof city === 'object' && 'name' in city ? city.name : String(city);
    }

    onCityChange(event: any) {
      console.log('onCityChange triggered', event);

      const selectedCity = event.option.value; // Get selected city object
      if (selectedCity && selectedCity.id) {
        this.sendid = selectedCity.id; // Assign city ID
        console.log('selectedCityId:', this.sendid);

        this.getStateByCityId(this.sendid); // Call function with city ID
      } else {
        console.log('No city selected or ID missing');
      }
    }



}

