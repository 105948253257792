<div class="terms-container">
  <img src="../../assets/images/terms.jpg" alt="About Us" class="aboutus">
  <h1>Terms and Conditions </h1>
  <p class="intro">Welcome to theswap.in! Before using our platform, please read these Terms and Conditions carefully. By accessing or using theswap.in, you agree to follow these terms.</p>

  <h2>1. Introduction</h2>
  <ul class="terms-list">
    <li>theswap.in is an online platform that helps users connect with service providers across different categories.</li>
    <li>We act as a bridge between customers and professionals but do not provide services ourselves.</li>
  </ul>

  <h2>2. User Responsibilities</h2>
  <ul class="terms-list">
    <li>✅ You must provide accurate information while using our platform.</li>
    <li>✅ You agree to use the website only for legal and ethical purposes.</li>
    <li>✅ Users should not post false, misleading, or harmful content.</li>
  </ul>

  <h2>3. Service Provider Responsibilities</h2>
  <ul class="terms-list">
    <li>Service providers must ensure that they provide genuine, high-quality services.</li>
    <li>Any disputes related to the service must be resolved between the service provider and the customer.</li>
    <li>theswap.in is not responsible for any loss or damage caused by a service provider.</li>
  </ul>

  <h2>4. Payment and Fees</h2>
  <ul class="terms-list">
    <li>Some services on theswap.in may be free, while others may have charges.</li>
    <li>Payments made to service providers are directly between the user and the provider. theswap.in is not responsible for refunds or service quality.</li>
  </ul>

  <h2>5. Privacy Policy</h2>
  <ul class="terms-list">
    <li>We respect your privacy. Your personal details will only be used as per our <a href="/privacy-policy">Privacy Policy</a>.</li>
    <li>We do not sell or misuse user data.</li>
  </ul>

  <h2>6. Limitation of Liability</h2>
  <ul class="terms-list">
    <li>theswap.in only acts as a platform for connecting users and service providers.</li>
    <li>We do not take responsibility for delayed, unsatisfactory, or incomplete services provided by any professional listed on our platform.</li>
  </ul>

  <h2>7. Prohibited Activities</h2>
  <ul class="terms-list">
    <li>Users must not misuse the platform for illegal activities, fraud, or harassment.</li>
    <li>Any suspicious activity may result in account suspension or legal action.</li>
  </ul>

  <h2>8. Changes to Terms</h2>
  <p>We may update these Terms and Conditions from time to time. Users should review this page regularly for any changes.</p>

  <h2>9. Contact Us</h2>
  <p>If you have any questions about our Terms and Conditions, feel free to contact us at <b>info@ksquaretech.co.in</b>.</p>

  <p class="agreement">By using theswap.in, you accept these Terms and Conditions. If you do not agree, please stop using our platform.</p>
</div>
