import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { TokenStorageService } from './token-storage.service';
import { catchError, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable({
	providedIn: 'root'
})
export class ProfileService {
	token = '';
	usertoken: any = [];
	socialOrigin = '';
	email = '';
	access_token=localStorage.getItem('access_token')
	constructor(private tokenStorage: TokenStorageService,
		private http: HttpClient) {
		// this.usertoken = JSON.parse(this.tokenStorage.getToken());
		this.socialOrigin = this.tokenStorage.getSocialOrigin();
		this.email = this.tokenStorage.getSocialUserEmail();
		if(this.usertoken && this.usertoken.access_token){
			this.token = this.usertoken.access_token;
		}
	}

	fetchUserBasicProfile(username) {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/user/${username}`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	fetchUserProfile(uId) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic '+this.token,
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/user/profile?socialOrigin=${this.socialOrigin}&email=${this.email}&accountOwnerUserId=${uId}`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	getUserPortfolio(uId) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic '+this.token,
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/user/portfolio?socialOrigin=${this.socialOrigin}&email=${this.email}&accountOwnerUserId=${uId}`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	getUserPortfolioByToken() {
		const headers = new HttpHeaders({
			'Authorization': 'Basic '+this.token,
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/user/portfolio/by_token?socialOrigin=${this.socialOrigin}&email=${this.email}`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	saveOrUpdateUserProfile(fdata, uId) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic '+this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}user/update?socialOrigin=${this.socialOrigin}&email=${this.email}&accountOwnerUserId=${uId}`, JSON.stringify(fdata), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}
	updatePersonalDetails(fdata) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic '+this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/user/update?socialOrigin=${this.socialOrigin}&email=${this.email}`, JSON.stringify(fdata), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}
	// getProfileData(){

// 		getProfileData() {
// 		const headers = new HttpHeaders({
// 			'Authorization': 'Basic '+this.token,
// 			'Content-Type': 'application/json'
// 		});
// 		return this.http.get(`${environment.apiUrl}/user/profile
// `, {
// 			headers: headers
// 		})
// 			.pipe(
// 				tap(data => { return data }), catchError(error => {
// 					/*Swal.fire({
// 						icon: 'error',
// 						title: 'Invalid request',
// 						showConfirmButton: false,
// 						timer: 6000
// 					  });*/
// 					return throwError(error);
// 				})
// 			);
// 	}
getProfileData() {
	let Token=localStorage.getItem('auth-token')
	console.log('Token',Token);

		const headers = new HttpHeaders({
			'Authorization': 'Basic '+Token,
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/user/profile`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {

					return throwError(error);
				})
			);
	}
	getProfileById(profileId){
    let httpHeader = new HttpHeaders()
.set('Authorization', 'Basic ' + this.token);
let options = {
headers: httpHeader
};
return this.http.get(`${environment.apiUrl}/global/user/user/${profileId}`, options);
}







	updateprofile(fdata) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic '+this.access_token,
			'Content-Type': 'application/json'
		});
		console.log("this.access_token",this.access_token)
		return this.http.post(`${environment.apiUrl}/global/user/update`, JSON.stringify(fdata), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	fetchAllSocialConnectOfUser(uId) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic '+this.token,
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/user/social_contacts?socialOrigin=${this.socialOrigin}&email=${this.email}&accountOwnerUserId=${uId}`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	fetchAllSoftwareOfUser(uId) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic '+this.token,
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/user/softwares?socialOrigin=${this.socialOrigin}&email=${this.email}&accountOwnerUserId=${uId}`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	fetchPublicProfileOfUser(username) {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/user/portfolio/public/${username}`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	fetchAllSkillsOfUser(uId) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic '+this.token,
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/user/skills?socialOrigin=${this.socialOrigin}&email=${this.email}&accountOwnerUserId=${uId}`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	fetchAllBlockedUserOfLoggedInUser(uId) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic '+this.token,
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/user/blocked?socialOrigin=${this.socialOrigin}&email=${this.email}&accountOwnerUserId=${uId}`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
				/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	unBlockedUserOfLoggedInUser(loginUserId,blockUserId) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic '+this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/user/unblock?socialOrigin=${this.socialOrigin}&email=${this.email}&accountOwnerUserId=${loginUserId}&blockedUserId=${blockUserId}`,{}, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	fetchUserFollowMe(uId) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic '+this.token,
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/user/following_me?socialOrigin=${this.socialOrigin}&email=${this.email}&accountOwnerUserId=${uId}`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	fetchUsersFollowedByMe(uId) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic '+this.token,
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/user/following_by_me?socialOrigin=${this.socialOrigin}&email=${this.email}&accountOwnerUserId=${uId}`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	unFollowUserOfLoggedInUser(loginUserId,followUserId) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic '+this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/user/unfollow?socialOrigin=${this.socialOrigin}&email=${this.email}&accountOwnerUserId=${loginUserId}&followingUserId=${followUserId}`,{}, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}
	followUserByLoggedInUser(loginUserId,followUserId) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic '+this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/user/follow?socialOrigin=${this.socialOrigin}&email=${this.email}&accountOwnerUserId=${loginUserId}&followingUserId=${followUserId}`,{}, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}
	blockedUserOfLoggedInUser(loginUserId,blockUserId) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic '+this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/user/block?socialOrigin=${this.socialOrigin}&email=${this.email}&accountOwnerUserId=${loginUserId}&blockedUserId=${blockUserId}`,{}, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}
	addSocialConnectToUserProfile( uId,fdata) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic '+this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/user/social_contacts?socialOrigin=${this.socialOrigin}&email=${this.email}&accountOwnerUserId=${uId}`, JSON.stringify(fdata), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	deleteSocialConnectFromUserProfile( uId,fdata) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic '+this.token,
			'Content-Type': 'application/json'
		});
		return this.http.delete(`${environment.apiUrl}/user/social_contacts/remove?socialOrigin=${this.socialOrigin}&email=${this.email}&accountOwnerUserId=${uId}&socialContactId=${fdata}`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	addSkillsToUserProfile( uId,fdata) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic '+this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/user/skills?socialOrigin=${this.socialOrigin}&email=${this.email}&accountOwnerUserId=${uId}`, JSON.stringify(fdata), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	removeSkillsFromUserProfile( uId,fdata) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic '+this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/user/skills/remove?socialOrigin=${this.socialOrigin}&email=${this.email}&accountOwnerUserId=${uId}`, JSON.stringify(fdata), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	addSoftwaresToUserProfile( uId,fdata) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic '+this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/user/softwares?socialOrigin=${this.socialOrigin}&email=${this.email}&accountOwnerUserId=${uId}`, JSON.stringify(fdata), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	removeSoftwaresFromUserProfile( uId,fdata) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic '+this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/user/softwares/remove?socialOrigin=${this.socialOrigin}&email=${this.email}&accountOwnerUserId=${uId}`, JSON.stringify(fdata), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	updateUserPassword(fdata,uId) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic '+this.token,
			'Content-Type': 'application/json'
		});
		return this.http.put(`${environment.apiUrl}/user/password/update?socialOrigin=${this.socialOrigin}&email=${this.email}&userId=${uId}`, JSON.stringify(fdata), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	updateUserUsername(fdata,uId) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic '+this.token,
			'Content-Type': 'application/json'
		});
		return this.http.put(`${environment.apiUrl}/user/username/update?socialOrigin=${this.socialOrigin}&email=${this.email}&userId=${uId}`, JSON.stringify(fdata), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	updateUserEmail(fdata,uId) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic '+this.token,
			'Content-Type': 'application/json'
		});
		return this.http.put(`${environment.apiUrl}/user/email/update?socialOrigin=${this.socialOrigin}&email=${this.email}&userId=${uId}`, JSON.stringify(fdata), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	submitComment(fdata) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic '+this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/user/comment/post?socialOrigin=${this.socialOrigin}&email=${this.email}`, JSON.stringify(fdata), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	removeComment(commendId,userId) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic '+this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/user/comment/remove?socialOrigin=${this.socialOrigin}&email=${this.email}&accountOwnerUserId=${userId}&commentId=${commendId}`, JSON.stringify({}), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}
}
