<div class="row justify-content-end align-items-end custom-row">
  <button type="button" class="close" (click)="skip()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="row d-flex justify-content-center align-items-center text-center">
  <div class="col-12">
    <img [src]="icon" alt="{{ name }} icon" class="profession-icon" style="width: 45px;" />
  </div>
  <div class="col-12 mt-2">
    {{ name }}
  </div>
</div>

  <!-- <div class="row d-flex justify-content-center align-items-center">
    <!-- <span class="letsingn">Please verify you are human </span> -->
  <!-- </div>  -->
  <div class="row  d-flex justify-content-center align-items-center p-2 mt-2">
    <div class="col-12">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <!-- Label for Email -->
        <label for="email">Email</label>
        <input
          type="text"
          id="email"
          placeholder="Enter Email"
          formControlName="email"
          class="styled-input"
        />

        <!-- Error Messages -->
        <div class="errorClass" *ngIf="(form.controls['email'].touched || submitted) && form.controls['email'].errors">
          <div *ngIf="form.controls['email'].errors.required">
            Email is required
          </div>
          <div *ngIf="form.controls['email'].errors.pattern">
            Please enter a valid Email Address
          </div>
        </div>


        <div class="row d-flex justify-content-center align-items-center mt-2">
            <button type="submit" class="styled-button mt-3" >
                Send OTP
            </button>
        </div>
    </form>

    </div>
  </div>
  <div class="row d-flex justify-content-center align-items-center">
    <span class="footerpolicy">
      By signing in, you agree to the
      <span style="text-decoration: underline; color:black; cursor: pointer;" (click)="policyanavigate()">Terms and Policy</span>
    </span>
  </div>



