import { 
    Component, 
    ChangeDetectionStrategy,
    ViewEncapsulation, 
    OnInit,
    Input,
    HostBinding
} from '@angular/core';
import { CONSTANTS_TEXT } from '@app/layout/const/app.constant';
import { CommonService } from '@app/layout/service/common.service';
import { LocalstorageService } from '@app/layout/service/localstorage.service';
import { LayoutType } from '@app/shared/types/app-config.interface';
import { ColorContrast } from '@app/shared/utils/ColorContrast';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';


@Component({
    selector: 'header-nav',
    templateUrl: './header-nav.component.html',
    styleUrls: ['./header-nav.component.css'],
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.header-nav]': 'true',
        '[class.layout-vertical]': 'layoutType === "vertical"',
        '[class.layout-horizon]': 'layoutType === "horizontal"',
        '[class.is-collapse]': 'collapse'
    }
})
export class HeaderNavComponent implements OnInit {

    @Input() layoutType: LayoutType
    @Input() collapse: boolean
    @Input() isMobile: boolean
    @Input()@HostBinding('style.background-color') color: string = '#ffffff'

    logoColor: 'light' | 'dark'
    alerts:any = [];
    menuStatus:boolean = true;
    subs$!: Subscription;
    user:any ='';
    data:any = '';
    message : string = '';
    rowData: any = [];
    constant = CONSTANTS_TEXT;
    type:any = '';
    IsShow:boolean=false
    @HostBinding('class') get headingClass() {
        const color = ColorContrast(this.color)
        this.logoColor = color
        return `header-text-${color}`
    }


    constructor(private _LocalstorageService: LocalstorageService,
               private tost :ToastrService,
        private _commanServcie:CommonService,

      ) { 
        this._LocalstorageService.invokeFirstComponentFunction.subscribe((resMessage:string) => { this.showMessage(resMessage); });
    }

    ngOnInit(): void { 
        // this.user = localStorage.getItem(CONSTANTS_TEXT.user);
        // this.user = 'sachin';

        this.subs$ = this._commanServcie.getProfileData().pipe().subscribe((res:any)=>{
            if(res.payload && res.payload){
                this.data = res.payload
            localStorage.setItem("Role",this.data?.gloveRole?.name);
            }
        })

    }
    onClosed(dismissedAlert: any): void {
        this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
    }
    showMessage(response:any){
        if(response){ 
          this.IsShow = true
          var type =  response.type;
          this.message = response.message;
        
          // if(type == this.constant.info){
          //   this.type = 'info';
          //   this.tost.info(this.message),({
          //     timeOut: 3000,
          //   }); 
          // }else if(type == this.constant.error){
          //   this.type = 'danger';
          //   this.tost.error(this.message),({
          //     timeOut: 3000,
          //   }); 
          // }else if(type == this.constant.success){
          //   this.type = 'success';
          //   this.tost.success(this.message),({
          //     timeOut: 3000,
          //   }); 
          // }else if(type == this.constant.warning){
          //   this.type = 'warning';
          //   this.tost.warning(this.message),({
          //     timeOut: 3000,
          //   }); 
          // }
        }
     
  }
}

