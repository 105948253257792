export const nav = {
    'NAV': {
        'DASHBOARD': 'Dashoard',
        'CLIENT': 'Client',
        'REQUIREMENT': 'Requirement',
        // 'CONTRACT': 'Contract',
        'CANDIIDATE': 'Candidate',
        'CANDIIDATE-CREATE' : 'Create',
        'CANDIIDATE-CANDIIDATE-LIST' : 'Search Candidate',
        'CANDIIDATE-CANDIIDATE-TRACKER' : 'Candidate Tracker',
        'CANDIIDATE-PARSING-REPORT' : 'Parsing Report',
        'CANDIIDATE-SAVE-SEARCH' : 'Saved Search',
        'CLOSURE': 'Closure',
        'ASSIGNMENT': 'Assignment',
        'ROLE': 'Role',
        'EMPLOYEE': 'Employee',
        'MASTER': 'Master',
        'MASTER-LABELS': 'Labels',
        'MASTER-COUNTRY': 'Country',
        'MASTER-STATE': 'States',
        'MASTER-CITY': 'Cities',
        'MASTER-INDUSTRIES': 'Industries', 
        'MASTER-QUALIFICATIONS': 'Qualifications',
        'MASTER-INSTITUTES': 'Institutes',
        'MASTER-CERTIFICATIONS': 'Certifications',
        'MASTER-INTERVIEW-TYPE': 'Interview Type',
        'MASTER-DEPARTMENT' : 'Department',
        'MASTER-LOCATION':'Location',
        'MASTER-DESIGNATION':'Designation',
        'MASTER-CANDIDATE-ROLE':'Candidate Role',
        'MASTER-EDUCATION':'Education',
        'MASTER-COMPANY':'Company',
        'MASTER-EXPERIENCE-LEVEL':'Experience Level',
        'MASTER-FUNCTIONAL-AREA':'Functional Area',
        'MASTER-FEEDBACK':'Feedback',
        'MASTER-REASON-TO-CHANGE':'Reason To Change',
     

    }
}