import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { CONSTANTS_TEXT } from '@app/layout/const/app.constant';
import { LocalstorageService } from '@app/layout/service/localstorage.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'nav-profile',
    templateUrl: './nav-profile.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.header-nav-item]': 'true'
    }
})
export class NavProfileComponent implements OnInit {
    user:any = '';
    constructor(
        private _LocalstorageService: LocalstorageService,
        private toastr: ToastrService,
    ) { }

    profileMenuList = [
        {
            path: '',
            icon: 'feather icon-user',
            item: ' My Profile',
            route: '/profile'
        },
        {
            path: '',
            icon: 'feather icon-edit',
            item: ' Change Password',
            route: '/changePassword'
        },
        // {
        //     path: '',
        //     icon: 'feather icon-life-buoy',
        //     item: 'Support'
        // },
        // {
        //     path: '',
        //     icon: 'feather icon-power',
        //     item: 'Sign Out'
        // }
    ]

    ngOnInit(){
        this.user = localStorage.getItem(CONSTANTS_TEXT.userName);
        console.log(this.user);
        
     }
    logout(){
        
        this._LocalstorageService.logout();
        // this.toastr.success(CONSTANTS_TEXT.logoutSucces)
    }
}
