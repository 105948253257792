<mat-tab-group class="p-3" (selectedIndexChange)="onTabChange($event)">
  <mat-tab label="Details">
    <div *ngIf="listingData" class="lead-details">
      <h1 class="title">{{ listingData.title | capitalize }}</h1>
      <hr>
      <div class="row">
        <div class="col-md-6 col-12 mb-3">
          <h2>Owner Details</h2>
          <p><strong>Full Name:</strong> {{ listingData.user.fullName }}</p>
          <p><strong>Email:</strong> {{ listingData.user.email }}</p>
          <p><strong>Mobile Number:</strong> {{ listingData.user.mobileNumber }}</p>
          <p><strong>Experties:</strong> {{ listingData.profession | capitalize }}</p>
          <p><strong>Profession:</strong> {{ listingData.expertise | capitalize }}</p>
        </div>
        <div class="col-md-6 col-12 mb-3 justify-content-center">
          <h2>Additional Information</h2>
          <p><strong>Created At:</strong> {{ listingData.createdAt | date:'dd-MM-yyyy HH:mm:ss' }}</p>
          <p><strong>Updated At:</strong> {{ listingData.updatedAt | date:'dd-MM-yyyy HH:mm:ss' }}</p>
          <p><strong>Expired At:</strong> {{ listingData.expiredAt }}</p>
        </div>
      </div>
      <hr>
      <div>
        <h2>Description</h2>
        <p [innerHTML]="listingData.description | safeHtml"></p>
      </div>
      <hr>
      <h2>Professional Details</h2>
      <div class="row col-md-12">
        <div class="col-md-3 mb-3">
          <p><strong>Profession:</strong> {{ listingData.profession }}</p>
        </div>
        <div class="col-md-3 mb-3">
          <p><strong>Expertise:</strong> {{ listingData.expertise }}</p>
        </div>
        <div class="col-md-3 col-6">
          <button class="mat-button custom-button" (click)="openContactDetailsDialog(listingData)">Party Details</button>
        </div>
        <div class="col-md-3 col-6">
          <button class="mat-button custom-button" (click)="openCommentDialog()">Add Comment</button>
        </div>
      </div>
    </div>
    <div *ngIf="!listingData" class="loading">
      <p>Loading data...</p>
    </div>
  </mat-tab>

  <mat-tab label="Comments">
    <div *ngIf="comments.length > 0; else noComments" class="comments-container">
      <mat-list>
        <mat-list-item *ngFor="let comment of comments" class="comment-item">
          <mat-icon matListIcon style="font-size: xx-large; margin-top: 10px;">account_circle</mat-icon>
          <div class="comment-content">
            <div class="comment-bubble">
              <p class="comment-text" style="color: blanchedalmond;">{{ comment.comment | stripHtml }}</p>
            </div>
            <div class="comment-footer">
              <span class="comment-user">{{ comment.user.fullName }}</span>
              <span class="comment-time">{{ comment.createdAt }}</span>
              <span class="comment-time" (click)="openCommentDialog()" style="cursor: pointer;">reply</span>
            </div>
          </div>
        </mat-list-item>
      </mat-list>
    </div>
    <ng-template #noComments>
      <div class="no-comments-container">
        <mat-icon>info</mat-icon>
        <p>No comments available.</p>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>
