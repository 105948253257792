<a target="_blank"  class="herf pe-auto"><i (click)="openModal(template)" class="feather icon-paperclip" style="margin-left: 0rem; font-size:16px"></i></a>
<ng-template #template>
    <div class="modal-header m-0"  style="background-color:  #ebebeb;">
        <h4 class="modal-title pull-left">Attachment</h4>
        <button type="button" class="close pull-right" aria-label="Close" style="font-size: 24px;" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body"  style="height: 600px !important;">
        <iframe [src]="resume" style="width: 100%; height: 100%;" frameborder="0">
        </iframe>
      </div>
</ng-template>