import { Component, OnInit,HostListener  } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';

import { TokenStorageService } from '../_services/token-storage.service';
import Swal from 'sweetalert2';
import { environmentbasic } from '../../environments/environment-basic';
import { SocialAuthService, FacebookLoginProvider, GoogleLoginProvider, SocialUser } from "angularx-social-login";
import { EmailverifyComponent } from '../verifymodel/emailverify/emailverify.component';
import { MatDialog } from '@angular/material/dialog';
import { HomeService } from '../_services/home.service';
import { LoadingService } from '../_services/loading.service';
import { ProfileService } from '../_services/profile.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';
import { ErrorMessageService } from '../services/error-message.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  selectedValues: string[] = ['Option1']; // Default selected value
  items: string[] = ['Option1', 'Option2', 'Option3', 'Option4']; // Dropdown options
    selectedCityId: string | null = null;
    searchCityQuery: string = '';
    showCitySuggestions: boolean = false;
    tempErrorMessage: string = '';
	constructor(
		private formBuilder: FormBuilder,
		private ProfileService:ProfileService,
		public dialog: MatDialog,
		private authService: AuthService,
		private tokenStorage: TokenStorageService,
		private socialauthService: SocialAuthService,
		private homeService: HomeService,
		private loadingService: LoadingService,
		private router:Router,
   private errorMessageService:ErrorMessageService) {

		}
	ALPHA_NUMERIC_REGEX = /^[a-zA-Z0-9_]*$/;
	swapclass = '';
	aciveforgot = false;
private unsubscribe$ = new Subject<void>();

	activereset = false;
	regform: FormGroup;
	otpform: FormGroup;
	loginform: FormGroup;
	forgotform: FormGroup; unique
	id!: string;
	loading = false;
	submitted = false;
	otpactive = false;
	registrationactive = true;
	uservalid = '';
	otpvalid = '';
	useremail = '';
	dropdownSettings = {};
	GoogleLoginProvider = GoogleLoginProvider;
	user: any;
	userinfo: any = [];
	countries = ['USA', 'Canada', 'UK', 'Australia', 'India']; // Add m
	// toppingList: string[] = ['1', '2', '3','4'];
	cityDataList: any[] = [];

	toppingList = [
		{ id: 1, value: 1 },
		{ id: 2, value: 2 }
	  ];
	  toppingList1 = [
		{ id: 1 ,value: 1 },
		{ id: 2, value: 2}
	  ];

	disableEmail: boolean = false;
	EmailId: string | null = null;
	selectedProfessionId: any;
	professions = [];
	expertises = [];

  searchCityQuery$ = new Subject<string>();
	ngOnInit(): void {

    this.searchCityQuery$.pipe(
      debounceTime(100),
      distinctUntilChanged()
    ).subscribe((searchTerm) => {
      this.searchCity(searchTerm);
    });
    this.searchCity()
		this.fetchAllProfession();
		console.log("in login page");
		this.regform = this.formBuilder.group({
			fullName: ['', [Validators.required]],
			email: ['', [Validators.required, Validators.email]],
			mobileNumber: [''],
			password: ['', [Validators.required, Validators.minLength(6)]],
			experties: [[]],
			profession: ['', Validators.required],
			cityId:['', Validators.required],
      productUser:['professional']
		});
		this.otpform = this.formBuilder.group({
			otp: ['', [Validators.required, Validators.minLength(6)]]
		});
		this.loginform = this.formBuilder.group({
			emailusernam: ['', [Validators.required]],
			loginpassword: ['', [Validators.required, Validators.minLength(6)]]
		});
		this.forgotform = this.formBuilder.group({
			forgotemail: ['', [Validators.required, Validators.email]],
		});

		this.socialauthService.authState.subscribe((response) => {
			this.user = response;
			if (this.user) {
				let token = null;
				if (this.user.provider == 'FACEBOOK') {
					token = this.user.authToken;
				}
				if (this.user.provider == 'GOOGLE') {
					token = this.user.idToken;
				}
				let tokenInStorage = { 'access_token': token };
				this.tokenStorage.saveToken(JSON.stringify(tokenInStorage));
				this.tokenStorage.saveSocialOrigin(this.user.provider);
				this.tokenStorage.saveSocialUserEmail(this.user.email);
				Swal.fire({
					icon: 'success',
					title: 'User verifcation is completed.',
					showConfirmButton: false,
					timer: environmentbasic.timer
				});
				window.location.href = '/';
			} else {
				Swal.fire({
					icon: 'error',
					title: response['message'],
					showConfirmButton: false,
					timer: environmentbasic.timer
				});
			}
		});
	}

	get f() { return this.regform.controls; }
	get g() { return this.otpform.controls; }
	get h() { return this.loginform.controls; }
	get k() { return this.forgotform.controls; }

	onSubmit() {
		this.submitted = true;
		if (this.regform.invalid) {
			return;
		}
    let setemail = localStorage.getItem("fixemail");
		let payload = this.regform.value
    payload.email =  setemail
    payload.cityId = this.selectedCityId;
    payload.experties = this.selectedexpertiesIds;
    payload.profession = this.selectedProfessionId;



		this.uniqueUsername( payload);
		localStorage.setItem('profileData', JSON.stringify(payload));
	}

  submittedf:boolean=false
	onSubmitOtp() {
		this.otpvalid = '';
		this.submittedf = true;

		if (this.otpform.invalid) {
			return;
		}

		this.authService.verifyOtp(this.useremail, this.otpform.value.otp).subscribe(

			response => {
				if (response['status'] == 'SUCCESS') {
					this.registrationactive = false;
					this.otpactive = true;
					Swal.fire({
						icon: 'success',
						title: 'User verifcation is completed.',
						showConfirmButton: false,
						timer: environmentbasic.timer
					});
					window.location.reload();
				}
        else if (response['message']) {
          let errorMessage = this.errorMessageService.getErrorMessage(response['message']);
          Swal.fire({
            icon: 'error',
            title: errorMessage,
            showConfirmButton: false,
            timer: environmentbasic.timer
          });
        }
			});
	}

  submitted1:boolean=false
	onLoginSubmit() {
		this.loading = true;
		this.submitted1 = true;

		if (this.loginform.invalid) {
			this.loading = false;
			return;
		}

		this.authService.loginUser(this.loginform.value.emailusernam, this.loginform.value.loginpassword ,'professional')
			.pipe(
				switchMap(response => {
					console.log(response);

					if (response && response.status === "SUCCESS") {
						console.log("hello");
						console.log('response->', response);
						let accessT = response.payload.access_token;
						const accessToken = response.payload;
						this.tokenStorage.saveToken(JSON.stringify(accessToken));
						const refreshToken = response.payload.refresh_token;
						localStorage.setItem("access_token", accessT);
						const expiresIn = response.payload.expires_in;
						localStorage.setItem("refresh_token", refreshToken);
						localStorage.setItem("expires_in", expiresIn);
						this.tokenStorage.saveSocialOrigin(null);
						this.tokenStorage.saveSocialUserEmail(null);
						this.tokenStorage.saveFirstVisitToHomePageAfterLogin("firstVisit");
						return this.authService.getProfileData();
					}

          else if (response['message']) {
						let errorMessage = this.errorMessageService.getErrorMessage(response['message']);
						Swal.fire({
							icon: 'error',
							title: errorMessage,
							showConfirmButton: false,
							timer: environmentbasic.timer
						});
					}
				})
			)
			.subscribe(
				response => {
					console.log('response11->', response);
          if (response) {
						let UserProfile = response;
						localStorage.setItem("UserProfile", JSON.stringify(UserProfile));
						console.log("UserProfile retrieved successfully");
						Swal.fire({
							icon: 'success',
							title: 'You logged in successfully.',
							showConfirmButton: false,
							timer: environmentbasic.timer
						}).then(() => {
              let currentUrl = this.router.url;
              this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              this.router.onSameUrlNavigation = 'reload';
              this.router.navigate([currentUrl]);
							window.location.href = '/';
						});
					}
          else if (response['message']) {
						let errorMessage = this.errorMessageService.getErrorMessage(response['message']);
						Swal.fire({
							icon: 'error',
							title: errorMessage,
							showConfirmButton: false,
							timer: environmentbasic.timer
						});
					}
					this.loading = false;
				},

        error => {
					this.loading = false;
					console.error('An error occurred', error);
          const message = error?.error?.message;
          if (message) {
						let errorMessage = this.errorMessageService.getErrorMessage(message);
						Swal.fire({
							icon: 'error',
							title: errorMessage,
							showConfirmButton: false,
							timer: environmentbasic.timer
						});
					}
				}
			);
	}

ngOnDestroy() {
   localStorage.removeItem("fixemail");
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
}

	signUpSwap() {
		this.swapclass = 'right-panel-active';
	}

	signInSwap() {
		this.swapclass = '';
	}

  uniqueUsername(formdata) {
    console.log(formdata);
    this.loading = true;
    this.authService.userRegister(formdata).subscribe(
        response => {
            console.log(response);
            if (response['status'] === 'SUCCESS') {
                this.signInSwap();
                Swal.fire({
                    icon: 'success',
                    title: response['payload']['fullName'] + ' is registered successfully.',
                    showConfirmButton: false,
                    timer: environmentbasic.timer
                });
            }
             else if (response['message']) {
                let errorMessage = this.errorMessageService.getErrorMessage(response['message']);
                Swal.fire({
                    icon: 'error',
                    title: errorMessage,
                    showConfirmButton: false,
                    timer: environmentbasic.timer
                });
            }
            this.loading = false;
        },
        error => {
            this.loading = false;
            console.error('An error occurred', error);
            const message = error?.error?.message;
            if (message) {
                let errorMessage = this.errorMessageService.getErrorMessage(message);
                Swal.fire({
                    icon: 'error',
                    title: errorMessage,
                    showConfirmButton: false,
                    timer: environmentbasic.timer
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'An unexpected error occurred.',
                    showConfirmButton: false,
                    timer: environmentbasic.timer
                });
            }
        }
    );
}

	forgotSwitch() {
		this.aciveforgot = true;
	}

	loginSwitch() {
		this.aciveforgot = false;
	}
  submittedo:boolean=false

  onForgotSubmit() {
    this.loading = true;
    this.submittedo = true;

    if (this.forgotform.invalid) {
        this.loading = false;
        return;
    }

    this.authService.forgotPassword(this.forgotform.value.forgotemail).subscribe(
        response => {
            if (response['status'] == 'SUCCESS') {
                Swal.fire({
                    icon: 'success',
                    title: 'OTP shared over your registered email, please check your inbox.',
                    showConfirmButton: false,
                    timer: environmentbasic.timer
                });
                this.router.navigate(['/setnewpassword/' + this.forgotform.value.forgotemail]);
            }
            else if (response['message']) {
                let errorMessage = this.errorMessageService.getErrorMessage(response['message']);
                Swal.fire({
                    icon: 'error',
                    title: errorMessage,
                    showConfirmButton: false,
                    timer: environmentbasic.timer
                });
            }
            this.loading = false;
        },
        error => {
            this.loading = false;
            let errorMessage = this.errorMessageService.getErrorMessage(error?.error?.message || 'Something went wrong!');
            Swal.fire({
                icon: 'error',
                title: errorMessage,
                showConfirmButton: true
            });
        }
    );
}


	signInWithFB(): void {
		this.socialauthService.signIn(FacebookLoginProvider.PROVIDER_ID);
	}

	signOut(): void {
		this.socialauthService.signOut();
	}

	signInWithGoogle(): void {
		this.socialauthService.signIn(GoogleLoginProvider.PROVIDER_ID);
	}




	  verifyOtp(email: string, otp: string): void {
		this.authService.verifyOtp(email, otp).subscribe(response => {
			console.log(response);

			if (response['status'] === 'SUCCESS') {
				// Handle success
				console.log('OTP verified successfully');
			} else if (response['status'] === '"EXCEPTION"') {
				// Handle pending status
				Swal.fire({
					icon: 'warning',
					title: 'User exist with same email',
					showConfirmButton: false,
					timer: environmentbasic.timer
				});
			} else {
				// Handle other errors
				Swal.fire({
					icon: 'error',
					title: response['message'],
					showConfirmButton: false,
					timer: environmentbasic.timer
				});
			}



		});
	  }


  onEmailInputBlur(email: string) {
    if (!email) {
      console.error("Email ID is empty or undefined.");
      Swal.fire({
        icon: 'error',
        title: 'Please enter a valid email address',
        showConfirmButton: true
      });
      return;
    }

    this.authService.sendemailotp(email).subscribe(
      (res: any) => {
        console.log("Response from API:", res);

        if (res && res.status === 'SUCCESS') {
          this.showModal(email);
          localStorage.setItem("fixemail",email)
          Swal.fire({
            icon: 'success',
            title: 'OTP Sent Successfully!',
            showConfirmButton: false,
            timer: 3000
          });

        } else if (res.status === 'EXCEPTION' && res.message) {
          let errorMessage = this.errorMessageService.getErrorMessage(res.message);
          Swal.fire({
            icon: 'error',
            title: errorMessage,
            showConfirmButton: false,
            timer: 3000
          });

        } else {
          console.error("Unexpected response:", res);
          Swal.fire({
            icon: 'error',
            title: 'Something went wrong! Please try again later.',
            showConfirmButton: true
          });
        }
      },
      (error) => {
        console.error("Error sending email OTP:", error);
        let errorMessage = this.errorMessageService.getErrorMessage(error?.error?.message);

        Swal.fire({
          icon: 'error',
          title: errorMessage || 'Failed to send OTP. Please try again later.', // ✅ टाइटल में एरर मैसेज
          showConfirmButton: true
        });
      }
    );
  }

	onInput(event: Event): void {
		const input = event.target as HTMLInputElement;
		input.value = input.value.replace(/[^0-9]/g, ''); // Remove any non-digit characters
		if (input.value.length > 10) {
			input.value = input.value.slice(0, 10); // Limit to 10 digits
		}
	}

	  showModal(email: string): void {
		const dialogRef = this.dialog.open(EmailverifyComponent, {
		  width: '450px',
		  data: { email: email },
		  disableClose: false
		});

		dialogRef.componentInstance.emailVerified.subscribe((status: boolean) => {
		  if (status) {
			this.disableEmail = true;
			this.EmailId = email;
			this.regform.get('email')?.disable();
			this.regform.get('email')?.setValue(email);
		  }
		});
	  }






    onSearchInput(event: any) {
      this.searchCityQuery$.next(event.target.value);
    }
    searchCity(searchTerm: string = '') {
      this.searchCityQuery = searchTerm; // Update input value
      this.homeService.searchCity(searchTerm).subscribe(
        (response: any) => {
          if (response.status === 'SUCCESS') {
            this.cityDataList = response.payload;
          } else {
            this.errorMessageService.getErrorMessage('Failed to fetch city data');
          }
        },
        (error) => {
          const message = error?.error?.message || 'Failed to fetch city data';
          this.errorMessageService.getErrorMessage(message);
        }
      );
    }
    onCityInputClick() {

      this.searchCity();
      this.showCitySuggestions = true;
    }

    clearSearch() {
      if (!this.selectedCityId) {
        this.searchCityQuery = '';
        this.regform.controls['cityId'].setValue(null);
      }
    }

    hideCitySuggestions() {
      setTimeout(() => {
        if (!this.selectedCityId) {
          this.searchCityQuery = '';
          this.regform.controls['cityId'].setValue(null);
        }
        this.showCitySuggestions = false;
      }, 200);
    }

    selectCity(city: any) {
      this.selectedCityId = city.id;
      this.searchCityQuery = city.name;
      console.log("this.selectedCityId this.selectedCityId ",this.selectedCityId )
      this.regform.controls['cityId'].setValue( this.selectedCityId);
      this.showCitySuggestions = false;
    }

    onBlur() {
      if (!this.selectedCityId) {
        this.searchCityQuery = '';
        this.regform.controls['cityId'].setValue(null);
      }
      this.showCitySuggestions = false;
	}



  filteredProfessions: any[] = [];
  searchText: string = '';
  showDropdown = false;











  fetchAllProfession() {
    this.homeService.fetchAllProfession().subscribe(
      (response: any) => {
        if (response && response.payload) {
          this.professions = response.payload;
          this.filteredProfessions = [...this.professions];
        } else {
          console.error('Unexpected data format:', response);
          this.professions = [];
        }
      },
      error => {
        console.error('Error loading professions:', error);
        this.professions = [];
      }
    );
  }

  filterProfessions(event: any) {
    const search = event.target.value.toLowerCase();
    this.filteredProfessions = this.professions.filter(profession =>
      profession.name.toLowerCase().includes(search)
    );
    this.showDropdown = true;
  }

  // selectProfession(profession: any) {
  //   this.searchText = profession.name;
  //   this.selectedProfessionId = profession.id;
  //   console.log(" this.selectedProfessionId", this.selectedProfessionId)
  //   this.showDropdown = false;
  //   this.sendSelectedProfession();
  //   this.fetchAllExperties()
  // }

  showPassword: boolean = false;

togglePasswordVisibility() {
  this.showPassword = !this.showPassword;
}
showPasswordlogin: boolean = false;

togglePasswordVisibilitylogin() {
  this.showPasswordlogin = !this.showPasswordlogin;
}

  selectProfession(profession: any) {
    this.searchText = profession.name;
    this.selectedProfessionId = profession.id;
    console.log(" this.selectedProfessionId", this.selectedProfessionId);
    this.showDropdown = false;
    this.sendSelectedProfession();

    // Clear previously selected expertises when profession changes
    this.selectedexpertiesIds = [];
    this.selectedexperties = [];
    this.filteredexperties = [];

    this.fetchAllExperties();
    this.errorMessage = '';

}

  sendSelectedProfession() {
    if (this.selectedProfessionId !== null) {
      console.log("Sending profession ID:", this.selectedProfessionId);
    }
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }


  closeDropdown() {
    setTimeout(() => {
      if (!this.professions.some(prof => prof.name.toLowerCase() === this.searchText.toLowerCase())) {
        this.searchText = '';
        this.selectedProfessionId = null;
      }
      this.showDropdown = false;
    }, 200); // Delay to allow click event on dropdown options
  }




  onProfession() {
    this.searchText = '';
    this.fetchAllProfession();
    this.showDropdown = true;

    this.selectedexpertiesIds = [];
    this.selectedexperties = [];
    this.filteredexperties = [];
  }




  filteredexperties: any[] = [];
  searchTextexperties: string = '';
  showDropdownexperties = false;
  selectedexpertiesIds: any[] = [];
  selectedexperties: any[] = [];
  professionId: number | null = null;
  errorMessage: string = '';

  // fetchAllExperties() {
  //   this.homeService.fetchidExperties(this.selectedProfessionId).subscribe(
  //     (response: any) => {
  //       this.loadingService.hide();
  //       if (response && response.payload) {
  //         this.expertises = response.payload;
  //         this.filteredexperties = [...this.expertises];
  //         if (this.expertises.length === 0) {
  //           this.errorMessage = 'No expertise available for the selected profession.';
  //         } else {
  //           this.errorMessage = '';
  //         }
  //       } else {
  //         console.error('Unexpected data format:', response);
  //         this.expertises = [];
  //         this.errorMessage = 'No expertise available for the selected profession.';
  //       }
  //     },
  //     (error) => {
  //       this.loadingService.hide();
  //       console.error('Error loading listings:', error);
  //       this.expertises = [];
  //       this.errorMessage = 'Failed to load expertise. Please try again later.';
  //     }
  //   );
  // }
  fetchAllExperties() {
    this.homeService.fetchidExperties(this.selectedProfessionId).subscribe(
      (response: any) => {
        this.loadingService.hide();
        if (response && response.payload) {
          this.expertises = response.payload;
          this.filteredexperties = [...this.expertises];
          this.showDropdownexperties = false;
          if (this.expertises.length === 0) {
            // Store error message but don't show it immediately
            this.tempErrorMessage = 'No expertise available for the selected profession.';
          } else {
            this.tempErrorMessage = ''; // Reset error if expertises are found
          }
        } else {
          console.error('Unexpected data format:', response);
          this.expertises = [];
          this.tempErrorMessage = 'No expertise available for the selected profession.';
        }
      },
      (error) => {
        this.loadingService.hide();
        console.error('Error loading listings:', error);
        this.expertises = [];
        this.tempErrorMessage = 'Failed to load expertise. Please try again later.';
      }
    );
  }



  filterExperties(event: any) {
    const search1 = event.target.value.toLowerCase();
    this.filteredexperties = this.expertises.filter(expertise =>
      expertise.name.toLowerCase().includes(search1)
    );
    this.showDropdownexperties = true;
  }

  selectExperties(expertise: any) {
    const index = this.selectedexpertiesIds.indexOf(expertise.id);
    if (index === -1) {
      this.selectedexpertiesIds.push(expertise.id);
      this.selectedexperties.push(expertise);
    } else {
      this.selectedexpertiesIds.splice(index, 1);
      this.selectedexperties.splice(index, 1);
    }
    if (!this.selectedProfessionId) {
      this.errorMessage = 'Please select a profession first.';
      return;
    }
    console.log("Selected IDs:", this.selectedexpertiesIds);
  }

  sendSelectedProfessionex() {
    if (this.selectedexpertiesIds.length > 0) {
      console.log("Sending selected profession IDs:", this.selectedexpertiesIds);
    }
  }

  // toggleDropdownExperties() {
  //   if (!this.selectedProfessionId) {
  //     this.errorMessage = 'Please select a profession first.';
  //     return;
  //   }
  //   this.showDropdownexperties = !this.showDropdownexperties;
  //   this.errorMessage = '';
  // }
  toggleDropdownExperties() {
    if (!this.selectedProfessionId) {
      this.errorMessage = 'Please select a profession first.';
    } else {
      this.errorMessage = this.tempErrorMessage || ''; // Show expertise error if exists, otherwise clear
    }
    this.showDropdownexperties = !this.showDropdownexperties;
  }




  closeDropdownExperties() {
      this.showDropdownexperties = false;
  }

  removeExpertise(expertise: any) {
    const index = this.selectedexpertiesIds.indexOf(expertise.id);
    if (index !== -1) {
      this.selectedexpertiesIds.splice(index, 1);
      this.selectedexperties.splice(index, 1);
    }

    console.log("Selected IDs after removal:", this.selectedexpertiesIds);
  }

  onExperties() {
    this.searchTextexperties = '';
    this.fetchAllExperties();
    this.showDropdownexperties = true;
  }
  displaySelectedExperties(): string {
    if (!this.selectedexperties.length) return '';

    const names = this.selectedexperties.map(ex => ex.name).join(', ');
    return names.length > 30 ? `${names.substring(0, 30)}...` : names;
  }

  getExpertiseNameById(id: number): string {
    const expertise = this.filteredexperties.find(exp => exp.id === id);
    return expertise ? expertise.name : '';
  }


}
