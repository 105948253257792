<div *ngIf="isUserLeads" style="height: 17pc;">
  <div class="not-found">
    <h1>Data Not Found</h1>
    <p>Sorry, the data you are looking for does not exist.</p>
    <a routerLink="/" (click)="refresh()" class="check">Go back to Home</a>
  </div>
</div>

<div *ngIf="!isUserLeads">
  <div class="not-founds1">
    <h1>Data Not Found</h1>
    <p>Sorry, the data you are looking for does not exist.</p>
    <a routerLink="/" (click)="refresh()" class="check">Go back to Home</a>
  </div>
</div>
