<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="row w-100">
        <div class="col-6">
            <div class="header-nav-right">
                <img src="../../../../assets/images/logo/theglove.jpeg" style="height: 35px;margin-left: 60px;" alt="">
            </div>
        </div>
        <div class="col-6">
            <ul class="navbar-nav navbar-nav-icons flex-row">
                <li class="nav-item dropdown list">
                    <a href="https://www.linkedin.com/company/the-glove/mycompany/?viewAsMember=true" target="_blank" class="btn btn-primary me-2 headerIcon"><i class="la la-linkedin"></i></a>
                    <a href="https://www.facebook.com/profile.php?id=100069741056175" target="_blank" class="btn btn-primary me-2 headerIcon"><i class="la la-facebook"></i></a>
                    <a href="https://www.instagram.com/theglove_hr?igshid=YzVkODRmOTdmMw==" target="_blank" class="btn btn-primary me-2 headerIcon"><i class="la la-instagram"></i></a>
                    <a href="https://theglove.co.in/" target="_blank" class="btn btn-primary me-2 headerIcon"><i class="la la-google"></i></a>
                </li>
            </ul>
        </div>
    </div>
</nav>
<div class="container-fluid px-0" data-layout="container">
    <div class="content mt-4">
        <div class="">
            <div class="row me-0 g-4">
                <div class="col-1">
                </div>
                <div class="col-10">
                    <div class="card  border border-300 " id="card1" data-component-card="data-component-card">
                        <div class="card-header p-2 border-300 " style="background-color: #004aad;">
                            <p class="card-heading-title">Candidate Form</p>
                        </div>
                        <div class="card-body p-0">
                            <div class="p-4 code-to-copy" style="padding-bottom: 0px !important;">
                                <form [formGroup]="form" class="row" (ngSubmit)="onSubmit()">
                                    <div class="col-md-3">
                                        <div class="form-group mb-3">
                                            <label class="form-label">Email</label><span class="text-danger">*</span>
                                            <input type="text" [readonly]="status" class="form-control"
                                                formControlName="emailId" placeholder="Email"
                                               
                                                (input)="onEmailInput($event.target.value)"
                                                (blur)="onEmailInputBlur($event.target.value)">

                                                <div *ngIf="status" class="verified-email"></div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group mb-3">
                                            <label class="form-label">Full Name</label><span class="text-danger">*</span>
                                            <input type="text" class="form-control" formControlName="firstName" placeholder="Full Name" (input)="onInputChange($event)">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group  mb-3">
                                            <label class="form-label">Alternate Email</label>
                                            <input type="email" class="form-control" formControlName="alternateEmailId" placeholder="Alternate Email">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group mb-3">
                                            <label class="form-label">Gender </label><span class="text-danger">*</span>
                                            <ng-select  formControlName="gender" placeholder="Select Gender"><span class="text-danger">*</span>
                                                <ng-option *ngFor="let g of ['Male','Female','Transgender','Non–Binary']" [value]="g" >{{g}}</ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group mb-3">
                                            <label class="form-label">Mobile Number</label><span class="text-danger">*</span>
                                            <div class="input-group flex-nowrap">
                                                <input type="text" class="form-control width-25" formControlName="countryCode" placeholder="Code">
                                                <input type="number"  class="form-control width-80" (input)="onInputChange2($event)" formControlName="phoneNumber"  placeholder="Mobile Number" aria-describedby="addon-wrapping">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group mb-3">
                                            <label class="form-label">Alternate Mobile Number </label>
                                            <div class="input-group flex-nowrap">
                                                <input type="text" class="form-control width-25" formControlName="alternateCountryCode" placeholder="Code">
                                                <input type="number"  class="form-control width-80" formControlName="alternateContactNumber"  placeholder="Alternate Phone Number" aria-describedby="addon-wrapping">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group mb-3">
                                            <label class="form-label">Nationality</label><span class="text-danger">*</span>
                                            <ng-select 
                                                [items] = "masterCounrty" 
                                                bindLabel="name"
                                                bindValue="idCode"
                                                placeholder="Select Counrty"
                                                formControlName="ccCode"
                                                (change)="selectedCounrty($event)">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div style="margin-top: 15px;" class="col-md-3">
                                        <div class="form-group mb-3" >
                                            <label style="margin-top:20px;">
                                                <checkbox [(ngModel)]="checked" formControlName="diversityInclusion" single="true"> Specially Abled</checkbox> 
                                              </label>
                                       </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group mb-3">
                                            <label class="form-label">Synopsis</label><span class="text-danger">*</span>
                                            <textarea class="form-control" style="height: 100px;" formControlName="careerSynopsis" placeholder="Synopsis" maxlength="500" id="exampleFormControlTextarea1" rows="3"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group mb-3">
                                            <label class="form-label">Current Company</label><span class="text-danger">*</span>
                                            <ng-select 
                                                [items]="masterCompany" 
                                                bindLabel="name"
                                                bindValue="cmpid" 
                                                formControlName="currentCompany"
                                                placeholder="Select Company">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-4">
                                        <div class="form-group mb-3">
                                            <label class="form-label">Current Designation</label><span class="text-danger">*</span>
                                            <ng-select 
                                                [items] = "masterDesignation" 
                                                bindLabel="name"
                                                bindValue="id"
                                                placeholder="Select Current Designation"
                                                formControlName="lastDesignation">
                                            </ng-select>
                                            <!-- <input type="text" class="form-control" formControlName="lastDesignation" placeholder="Designation" > -->
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group mb-3">
                                            <label class="form-label">Total Experience (Years)</label><span class="text-danger">*</span>
                                            <input type="number" class="form-control" formControlName="totalExperience" placeholder="Total Experience" >
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group mb-3">
                                            <label class="form-label">Current CTC(Yearly)</label><span class="text-danger">*</span>
                                            <input type="number" class="form-control" formControlName="currentCTC" placeholder=" Current CTC Yearly">
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-3">
                                        <div class="form-group mb-3">
                                            <label class="form-label">Expected CTC(Yearly)</label><span class="text-danger">*</span>
                                            <input type="number" class="form-control" formControlName="expectedCTC" placeholder=" Expected CTC Yearly">
                                        </div>
                                    </div> -->
                                    <div class="col-md-3">
                                        <div class="form-group mb-3">
                                            <label class="form-label">Variable</label>
                                            <div class="input-group flex-nowrap">
                                                <span class="input-group-text" id="addon-wrapping">INR</span>
                                                <input type="number" onKeyPress="if(this.value.length==10) return false;" class="form-control" formControlName="variableCTC" placeholder="Variable" >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group mb-3">
                                            <label class="form-label">Notice Period</label><span class="text-danger">*</span>
                                            <ng-select  formControlName="noticePeriod" (change)="checkNoticePeriod($event)" placeholder="Notice Period">
                                                <ng-option *ngFor="let el of noticePeriodValues" [value]="el" >{{el}}</ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group mb-3 input-affix flex-column">
                                            <label class="form-label"> Last Working day</label><span *ngIf="lastWorkingDayRequired" class="text-danger">*</span>
                                            <input type="text" #dp="bsDatepicker" bsDatepicker [bsConfig]="{ isAnimated: true, displayMode: 'day',dateInputFormat: 'DD/MM/YYYY',showWeekNumbers:false }"  class="form-control" class="form-control" formControlName="lastWorkingDay" placeholder="Last Working day" >
                                            <i class="suffix-icon feather cursor-pointer text-dark m-12 icon-calendar mt-13" (click)="dp.toggle()" [attr.aria-expanded]="dp.isOpen" type="button"></i>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group mb-3">
                                            <label class="form-label">Current Location</label><span class="text-danger">*</span>
                                            <ng-select [items]="masterLocation" bindLabel="cityName" bindValue="locId"
                                                placeholder="Select Current Location" formControlName="currentLocation">
                                            </ng-select>
                                        </div>
                                    </div>

                                    <div class="col-md-3">
                                        <div class="form-group mb-3">
                                            <label class="form-label">Skills</label><span class="text-danger">*</span>
                                            <input type="text" class="form-control" formControlName="skills"
                                                placeholder="skills" aria-label="Text input with dropdown button">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group mb-3">
                                            <label class="form-label">Industry</label>
                                            <ng-select [items]="masterIndustry" bindLabel="name"
                                                bindValue="indId" formControlName="industry"
                                                placeholder="Select Industry">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group mb-3">
                                            <label class="form-label">Type</label>
                                            <ng-select placeholder="All" formControlName="type" class="select-input">
                                                <ng-option *ngFor="let r of types" [value]="r">{{r}}</ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group mb-3">
                                            <label class="form-label">Certification</label>
                                            <ng-select [multiple]="true" [items]="masterCertification" bindLabel="name"
                                                bindValue="id" placeholder="Select Certification"
                                                formControlName="certificationList">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-3">
                                        <div class="form-group mb-3">
                                            <label class="form-label">Preferred Location</label>
                                            <ng-select [items]="masterLocation" bindLabel="name"
                                                bindValue="name" placeholder="Select Preferred Location"
                                                formControlName="preferredLocation">
                                            </ng-select>
                                        </div>
                                    </div> -->
                                    <div class="col-12" formArrayName="educationList">
                                        <div class="row" *ngFor = "let addcontrol of education().controls; let i = index">
                                            <div class="mt-2 col-12" [formGroupName]="i">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <div class="form-group mb-3">
                                                            <label class="form-label">Education</label>
                                                            <ng-select
                                                                [disabled] = "isreadonly"
                                                                [items] = "masterEducation" 
                                                                bindLabel="name"
                                                                bindValue="eduid"
                                                                placeholder="Select Education"
                                                                formControlName="educationId"
                                                                (change)="onEducationChange($event)">
                                                            </ng-select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-6 col-lg-6 col-sm-6">
                                                                <div class="form-group mb-3">
                                                                    <label class="form-label">From Year</label>
                                                                    <ng-select (change)="fromYearInput($event,i)" formControlName="fromYear" placeholder="Select From Year">
                                                                        <ng-option *ngFor="let yr of year()" [value]="yr" >{{yr}}</ng-option>
                                                                    </ng-select>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-md-6 col-lg-6 col-sm-6">
                                                                <div class="form-group mb-3">
                                                                    <label class="form-label">From Month</label>
                                                                    <ng-select (change)="fromMonthsInput($event,i)"  formControlName="fromMonth" placeholder="Select From Month">
                                                                        <ng-option *ngFor="let mn of months" [value]="mn" >{{mn}}</ng-option>
                                                                    </ng-select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-6 col-lg-6 col-sm-6">
                                                                <div class="form-group mb-3">
                                                                    <label class="form-label">To Year</label>
                                                                    <ng-select (change)="toYearInput($event,i)" formControlName="toYear" placeholder="Select To Year">
                                                                        <ng-option *ngFor="let yr of  year()" [value]="yr" [disabled]="(yr < fromYear[i] ? true : false)" >{{yr}}</ng-option>
                                                                    </ng-select>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-md-6 col-lg-6 col-sm-6">
                                                                <div class="form-group mb-3">
                                                                    <label class="form-label">To Month</label>
                                                                    <ng-select  formControlName="toMonth" placeholder="Select To Month">
                                                                        <ng-option *ngFor="let mn of months; index as x" [value]="mn" [disabled]="(toYear[i] == fromYear[i] && x < toMonths[i] ? true : false)"  >{{mn}}</ng-option>
                                                                    </ng-select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group mb-3">
                                                            <label class="form-label">College/University</label>
                                                            <input type="text" class="form-control" formControlName="college"  placeholder="College/University" >
                                                        </div>
                                                    </div>
                                                    <div class="addRemoveButton">
                                                        <a class="formButton" style="color: #1d7ca4" *ngIf="i == 0" (click)="addeducation()"><i class="feather icon-plus-circle"></i></a>
                                                        <a class="formButton" style="color: #b92626" *ngIf="i != 0" (click)="deleteEducation(i)"><i class="feather icon-trash-2"></i></a>
                                                    </div>
                                                </div>
                                            </div>                                       
                                        </div>
                                    </div>
                                    <div class="col-12" >
                                        <div class="dropzone formbold-mb-5 formbold-file-input" fileDragDrop (filesChangeEmiter)="fileUpload($event)" >
                                            <div class="text-wrapper">
                                                <div class="centered">
                                                    <input type="file"  [accept]="allowedTypes" name="file" [disabled]="(!isName || !isMobileNumber)" id="file" (change)="fileUpload($event.target.files)" >
                                                    <label for="file" >
                                                        <div >
                                                          <span class="formbold-drop-file" > Drop files here</span>
                                                          <span class="formbold-or"> Or </span>
                                                          <span class="formbold-browse" > Upload </span>
                                                        </div>
                                                        <div class="doumentMessage" style="width: 400px;">
                                                            <span class="note"> Upload Instruction </span>
                                                            <p class="liStyle"> The maximum allowable file size is 5 MB </p>
                                                            <p class="pStyle"> Special character is not allowed  </p>
                                                            <p class="liStyle"> File name max character length limit 255 </p>
                                                            <p class="pStyle">List of Acceptance File Formats</p>
                                                            <li class="ListStyle">Pdf File</li>
                                                            <li class="ListStyle1">Image (JPG, JPEG,PNG)</li>
                                                            <li class="ListStyle2">Microsoft Word (DOC or DOCX)</li>   
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="iSCvNeeded" >
                                            <div style="color: #f46363;margin-top: -11px; font-size: 0.875;" >CV is required</div>
                                        </div>
                                        <div class="card mt-2" *ngIf="resumefiles != ''">
                                            <div class="fileNameView">
                                                <a class="h4" (click)="resumeUrl(resumefiles)" mat-line><i class="feather icon-file-text"></i>{{finalFileName}}</a>
                                                <a class="warn h4" (click)="deleteFile(file)"><i class="feather icon-trash-2 h2" style="color: red;font-size: 20px;"></i></a>
                                            </div>
                                        </div>
                                        <div style="display: inline-flex;">
                                            <checkbox  (onChange)="privacyAndPolicy($event)">I have read and agree to the &nbsp; </checkbox><a class="herf" (click)="link()" target="_blank" routerLink="/privacypolicy"> Privacy Policy</a>
                                        </div>
                                        <div class="row g-1 justify-content-end">
                                            <div class="col-auto">
                                                <button class="btn btn-outline-primary px-5"  style="--phoenix-btn-color: #1b1d22ed; --phoenix-btn-border-color: #32353e;  --phoenix-btn-hover-bg:  #004aad ;">Reset</button>
                                            </div>
                                            <div class="col-auto">
                                                <button class="btn btn-primary px-5" type="submit"  data-bs-target="#exampleModal" [disabled]="!(submitStatus && privacyAndPolicyValue) || isOtpValid || isSubmit || !status" 

                                                style="background-color: #004aad !important;">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </form>
                            </div>
                        </div>
                        <div class="col-12 mt-0 pb-4" style="padding-right: 1.7REM;">
                            <div class="modal fade" id="exampleModal" tabindex="-1" aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title text-center" id="exampleModalLabel" style="margin: auto; ">Thankyou</h5>
                                            <button class="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close"><span class="fas fa-times fs--1"></span></button>
                                        </div>
                                        <div class="modal-body" style="border-bottom: none;">
                                            <p class="text-700 mb-0 text-center" style="margin: auto; color: rgb(9 150 9) !important; margin-bottom: 0px;  font-size: 20px;  --phoenix-btn-active-bg: #004aad !important;"> Your profile has been updated. </p>
                                        </div>
                                        <div class="modal-footer" style="border-top: none;">
                                            <button class="btn btn-primary" type="button" data-bs-dismiss="modal" style="margin-top: 0px; background-color: #004aad !important; ">Okay</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-1">
                </div>
            </div>
        </div>
    </div>
</div>
<footer>
    <div class="row me-0">
        <div class="col-11 row me-0">
            <div class="col-4">
                <p class="footer-link">www.theglove.in </p>
            </div>
            <div class="col-4" style="padding: 0px 4px 0px 4px;">
                <p class="footer-link">+91-9994455666</p>
            </div>
            <div class="col-4">
                <p class="footer-link"> hr@theglove.in</p>
            </div>
        </div>
        <div class="col-1">
            <p class="footer-link version" color="grey">v1.00</p>
        </div>
    </div>

</footer>