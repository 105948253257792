import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CONSTANTS_TEXT } from '../const/app.constant';
import { INTERNET_CONNECTION_ERROR, StatusCode } from '../enum';
import { errorarray } from '../shared/function/function';
import { LocalstorageService } from './localstorage.service';
@Injectable({
  providedIn: 'root'
})
export class HttpService {
  CONSTANTS = CONSTANTS_TEXT;
  // access_token:any = '';
  constructor(
    private localService:LocalstorageService,
    private httpClient: HttpClient) {
    //  this.access_token() = localStorage.getItem('access_token');
    }
    // One Post methoed
    postAll(url: string, Body?: any): Observable<any> {
      let httpheader = new HttpHeaders().set('Authorization', 'Basic '+this.access_token());
      let options = { headers: httpheader};
      return this.httpClient.post(url, Body,options).pipe(catchError(this.errorHandler.bind(this)));
    }
    // One get methoed
    getAll(url: string, params?: any): Observable<any> {
      let httpheader = new HttpHeaders().set('Authorization', 'Basic '+this.access_token());
      let options = { headers: httpheader};
      if(params !=null){
        return this.httpClient.get(url + params,options).pipe(catchError(this.errorHandler.bind(this)));
      }else{
        return this.httpClient.get(url,options).pipe(catchError(this.errorHandler.bind(this)));
      }
    }

    //without token 
    getAllwithoutToken(url: string, params?: any): Observable<any> {
      let options: any = {};
  
  
      if (params != null) {
          return this.httpClient.get(url + params, options).pipe(
              catchError(this.errorHandler.bind(this))
          );
      } else {
          return this.httpClient.get(url, options).pipe(
              catchError(this.errorHandler.bind(this))
          );
      }
  }
  
  
    // One delete methoed
    deleteOne(url: string, id?: any): Observable<any> {
      let httpheader = new HttpHeaders().set('Authorization', 'Basic '+this.access_token());
      let options = { headers: httpheader};
      return this.httpClient.delete(url + '?id=' + id,options).pipe(catchError(this.errorHandler.bind(this)));
    }
     // One delete methoed
     deleteTwo(url: string, id?: any): Observable<any> {
      let httpheader = new HttpHeaders().set('Authorization', 'Basic '+this.access_token());
      let options = { headers: httpheader};
      return this.httpClient.delete(url + '?id=' + id,options).pipe(catchError(this.errorHandler.bind(this)));
    }
    // One put methoed
    putApi(url: string, Body?: any, id?:any): Observable<any> {
      let httpheader = new HttpHeaders().set('Authorization', 'Basic '+this.access_token());
      let options = { headers: httpheader};
      if(id){
        return this.httpClient.put(url+'/'+id, Body,options).pipe(catchError(this.errorHandler.bind(this)));
      }else{
        return this.httpClient.put(url, Body,options).pipe(catchError(this.errorHandler.bind(this)));
      }
    }
    putApiParams(url: string, params?: any,Body?: any): Observable<any> {
      let httpheader = new HttpHeaders().set('Authorization', 'Basic '+this.access_token());
      let options = { headers: httpheader};
      return this.httpClient.put(url+'/'+params, Body,options).pipe(catchError(this.errorHandler.bind(this)));
    }

  // Error Handling
  private errorHandler(response: any) {
    let message:string = '';
  }
  // if(response.status === StatusCode.ValidationCode){
  //   if(response.error.message){
  //     message = response.error.message;
  //   }else if(response.error.error){
  //     let arr = errorarray(response.error.error);
  //     if(arr.length){
  //       message = arr[0];
  //     }
  //   }
  // }else if(response.status === StatusCode.BadRequest){
  //   message = response.error.message;
  // }else if(response.status === StatusCode.NotFound){
  //   message = response.error.message;
  // }else if(response.status === StatusCode.InternalServerError){
  //       message = INTERNET_CONNECTION_ERROR.servererror;
  // }else if (response.status == StatusCode.Unknown) {
  //      message = INTERNET_CONNECTION_ERROR.internetcheck;
  // }else{
  //      message = INTERNET_CONNECTION_ERROR.someerror;
  // }

  // this.localService.showmessage(this.CONSTANTS.error, message);
  // }
  access_token(){
    return localStorage.getItem('access_token');
  }
  // this.access_token() = localStorage.getItem('access_token');
}
