<div class="row">
    <div class="col-12">
      <span style="font-size:22px;">Comment</span>

      <button type="button" class="close" (click)="closeModal()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
    <div class="row" style="padding-top: 5px;">
      <div class="col-12">
        <textarea id="comment" formControlName="comment" rows="6" class="form-control"></textarea>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-6">
        <mat-form-field class="custom-form-field full-width custom-file">
          <div class="file-upload-container">
            <mat-toolbar>
              <input
              matInput
              [value]="isMobile ? (selectedFileNames.length ? selectedFileNames.join(', ') : '') : (selectedFileNames.length ? selectedFileNames.join(', ') : 'Select Files')"
              readonly
            />

              <div class="button-container">
                <button type="button" mat-stroked-button color="primary" (click)="triggerFileInput()">Browse</button>&nbsp;
                <button type="button" mat-flat-button color="primary" [disabled]="!selectedFiles.length" (click)="uploadFiles()">Upload</button>
              </div>
            </mat-toolbar>
            <input
              type="file"
              id="fileInput"
              formControlName="imageUrls"
              (change)="handleChange($event)"
              name="fileInput"
              accept=".pdf,.doc,.docx"
              multiple
              hidden
            />
          </div>

          <div class="example" *ngIf="isLoading"> </div>
        </mat-form-field>
      </div>
      <!-- <div class="example" *ngIf="isLoading"> </div> -->
      <div class="uploaded-files" style="margin-top: 32px;">
        <div *ngFor="let fileName of selectedFileNames; let i = index" class="uploaded-file-container">
          <a href="#" (click)="openFile(i); $event.preventDefault();">{{ fileName }}</a>
          <button mat-icon-button style="color:#004aad;" (click)="removeImage(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>


    <div class="row mt-4">
      <div class="col-12 text-right " style="margin-top:7px;">
        <button type="button" mat-stroked-button style=" margin-right: 10px; color: #004aad;" (click)="closeModal()">Cancel</button>
        <button type="submit" mat-flat-button color="primary">Submit</button>
      </div>
    </div>
  </form>


