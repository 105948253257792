
<div class="auth-page-wrapper pt-5" style="color:#004aad;">
    <!-- page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles" style="color:#004aad;;">
        <div id="video-container">
            <video autoplay="" loop="" muted="">
                <source src="https://www.shutterstock.com/shutterstock/videos/1087159541/preview/stock-footage-diverse-group-of-workers-attending-job-interview-meeting-with-applicant-talking-about-work.webm" type="video/mp4" />
            </video>
        </div>

        <div class="bg-overlay"></div>
        <div class="shape">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
                <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
            </svg>
        </div>
        <canvas class="particles-js-canvas-el" width="1349" height="380" style="width: 100%; height: 100%;"></canvas>
    </div>

    <!-- page content -->
    <div class="auth-page-content">
        <div class="container">
            <div class="row justify-content-center" style="margin-top: 140px;">
                <div class="col-md-8 col-lg-6 col-xl-4">
                    <div class="card mt-5 login-bg">
                        <div class="card-body p-5">
                            <div class="text-center">
                                <div class="d-inline-block auth-logo">
                                    <img src="http://15.206.61.218/assets/images/logo/logo.png" alt="JSLPS image" height="50" style="border-radius:5px;"/>
                                </div>
                                <h3 class="text-dark mt-3">Login to Your Account</h3>
                            </div>

                            <div class="p-2 mt-3">
                                <div class="mb-3">
                                    <label for="username" class="form-label">Username</label><span class="text-danger"> *</span>
                                    <input name="txtusername" type="text" id="txtusername" class="form-control" placeholder="username" />
                                </div>

                                <div class="mb-3">
                                    <label class="form-label text-black">Password</label> <span class="text-danger"> *</span>
                                    <div class="form-group input-affix flex-column">
                                        <label class="d-none text-black">Password</label>
                                        <input  [type]="showPassword ? 'text' : 'password'"  placeholder="Password"  class="form-control no-validation-icon no-success-validation"  formControlName="password" >
                                        <i class="suffix-icon feather cursor-pointer text-dark"  [ngClass]="showPassword ? 'icon-eye-off' : 'icon-eye'" (click)="onShowPasswordClick()"></i>
                                    </div>
                                </div>

                                <div class="form-check">
                                    <!-- <label class="form-check-label" for="auth-remember-check">Remember me</label> -->
                                    <a href="#" class="float-end text-primary">Forgot password?</a>
                                </div>

                                <div class="mt-4">
                                    <input type="submit" name="btnlogin" value="Login" id="btnlogin" class="btn btn-primary w-100" />
                                </div>
                            </div>
                        </div>
                        <!-- card body -->
                    </div>
                    <!-- card -->
                </div>
            </div>
            <!-- row -->
        </div>
        <!-- container -->
    </div>
    <!-- end page content -->
</div>
<!-- <div class="footer-content w-100 p-2" style="border-top:1px solid rgb(163, 163, 163) ;">
    <p class="mb-0 w-100">Powered by 
        <span class="d-none d-sm-inline-block mx-1">|</span>
        <br class="d-sm-none" />The Glove.
        <a class="mx-1" href="#" target="_blank"> <img src="./../../../../assets/images/logo/theglove.jpeg" style="height: 21px;" alt="Ksquare"></a>
        <span class="" style="margin: auto; width: 50px;">www.theglove.in</span>
        <span class="text-end float-end"><a href="" class="text-gray">v1.0.0</a></span>
    </p>
</div> -->
<footer style="border-top: 1px grey solid;">
    <div class="row px-3 mt-2">
        <div class="col-11 row">
            <div class="col-6">
                <p class="footer-link m-0">Ksquaretech.co.in </p>
            </div>
            <div class="col-6" style="padding: 0px 4px 0px 4px;">
                <p class="footer-link m-0">+91-9264535666</p>
            </div>
            <!-- <div class="col-4">
                <p class="footer-link"> Powered by 
                    <span class="d-none d-sm-inline-block mx-1">|</span>
                    <br class="d-sm-none" />The Glove.
                    <a class="mx-1"> <img src="./../../../../assets/images/logo/theglove.jpeg" style="height: 21px;" alt=""></a>
                </p>
            </div> -->
        </div>
        <div class="col-1">
            <p class="footer-link version m-0" color="grey">v1.00</p>
        </div>
    </div>

</footer>
