import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { RoutePath } from '../config/router.config';

@Injectable({
  providedIn: 'root'
})
export class GuestGuard implements CanActivate {
  constructor(private router:Router){
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree | any{
      let token =  localStorage.getItem('access_token');
      let isLoggedIn = true ;
      if(token != null && token != ''){
        isLoggedIn = true
      }else{
        isLoggedIn = false
      }
      const tokenchecked:boolean = isLoggedIn;
      if(tokenchecked){
        this.router.navigate([RoutePath.dashboard])
      }else{
        return true
      }
  }
}
