import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { AuthLayoutComponent } from "./layout/auth-layout/auth-layout.component";
import { AppLayoutComponent } from "./layout/app-layout/app-layout-component";

import { AUTH_LAYOUT_ROUTES } from "./routes/auth-layout.routes";
import { APP_LAYOUT_ROUTES } from './routes/app-layout.routes';
import { AuthGuard } from './layout/guards/auth.guard';
import { GuestGuard } from './layout/guards/guest.guard';
import { RoutePath } from './layout/config';
import { CandidateMailFormComponent } from './views/candidate-mail/candidate-mail-form/candidate-mail-form.component';
import { CANDIDATE_MAIL_LAYOUT_ROUTES } from './routes/candidate-mail-layout.routes';
import { Login1Component } from './views/login/login1/login1.component';
import { Login2Component } from './views/login/login2/login2.component';
import { Login3Component } from './views/login/login3/login3.component';
import { login1 } from './routes/login1.routes';
import { login2 } from './routes/login2.routes';
import { login3 } from './routes/login3.routes';
import { PrivacyPolicyComponent } from './views/candidate-mail/privacy-policy/privacy-policy.component';
import { privacypolicy } from './routes/privacypolicy.routes';

const appRoutes: Routes = [
    {path:RoutePath.Empty,redirectTo:RoutePath.auth, pathMatch:'full'},
    {path:RoutePath.auth, canActivate:[GuestGuard], loadChildren: ()=> import('./layout/auth/auth.module').then(m => m.AuthModule)},
    { 
       
        canActivate:[AuthGuard],
        path: '', 
        component: AuthLayoutComponent, 
        children: AUTH_LAYOUT_ROUTES
    },
    { 
        canActivate:[AuthGuard],
        path: '', 
        component: AppLayoutComponent,
        children: APP_LAYOUT_ROUTES 
    },
    { 
        path: '', 
        component: CandidateMailFormComponent,
        children: CANDIDATE_MAIL_LAYOUT_ROUTES 
    },
    { 
        path: '', 
        component: Login1Component,
        children: login1 
    },
    { 
        path: '', 
        component: Login2Component,
        children: login2 
    },
    { 
        path: '', 
        component: Login3Component,
        children: login3 
    },
    { 
        path: '', 
        component: PrivacyPolicyComponent,
        children: privacypolicy
 
    },

];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            preloadingStrategy: PreloadAllModules,
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled',
            relativeLinkResolution: 'legacy'
        })
    ],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule {
}