<div class="card-container">
  <div class="card">
    <div class="card-body">
      <div class="listing-header row">
       <div class="col-md-6 col-8">
         <h5 class="listing-title" (click)="navigateToDetail(listing.id)">
           {{ listing.title | capitalize }}
         </h5>
       </div>
       <div class="col-md-6 col-4 d-flex justify-content-end parent-container">
        <div *ngIf="editButton">
          <span
          class="badge badge-status"
          (click)="toggleStatus(listing.id)"
          [ngClass]="{'activate': listing?.status === 'draft' || listing?.status === 'inactive',
                      'inactivate': listing?.status === 'active'}">
          {{ listing?.status === 'draft' || listing?.status === 'inactive' ? 'Active' : 'DeActive' }}
        </span>
        </div>
        <div *ngIf="!editButton">
          <span
            class="badge badge-status"
            >
            {{listing.status |capitalize}}
          </span>
        </div>
      </div>

     </div>

     <div class="row mt-2">
       <div class="col-md-6 col-6">
        <span class="profession-text"><strong>Profession:</strong>  {{listing.profession | capitalize}}</span>
       </div>
       <div class="col-md-6 col-6 d-flex justify-content-end">
        <h3 class="expertise-text"><strong>Experties:</strong> {{listing.expertise}}</h3>

       </div>
     </div>

     <div class="listing-subheader">
       <span class="description-text">
        <!-- <strong>Address:</strong> -->
        {{listing.address}}</span>
     </div>

     <!-- <div class="listing-description mt-2" matTooltip="{{listing.description | stripHtml}}">
      <span class="description-text" [innerHTML]="listing.description | safeHtml"></span>
    </div> -->
    <div class="listing-description mt-2">
      <span class="description-text" [innerHTML]="listing.description | safeHtml"></span>
    </div>

     <div class="listing-footer row mt-4">
      <div class="col-md-5 col-12 mb-2 mb-md-0">
        <span>{{ listing.createdAt | timeAgo }}</span>
      </div>
      <div class="col-md-7 col-12 d-flex justify-content-md-end justify-content-end">
        <div *ngIf="editButton" class="mr-2">
          <button class="btn btn-primary btn-edit" (click)="editDetail(listing.id)">
            <i class="fa fa-edit" aria-hidden="true"></i>&nbsp; Edit Details
          </button>
        </div>
        <div>
          <button class="btn btn-primary btn-view" (click)="navigateToDetail(listing.id)">
            <i class="fa fa-star" aria-hidden="true"></i>&nbsp; View Details
          </button>
        </div>
      </div>
     </div>
    </div>
  </div>
</div>
