<div class="nav-logo border-buttom">
    <logo  style="display: none;"
        [logoType]="!collapse || quickExpand ? 'logo' : 'fold' "
        [height]="!collapse || quickExpand ? 30 : 30"
        [white]= "color === 'dark'"
        class="w-100"
    ></logo>
</div>
<perfect-scrollbar class="side-nav-content">
    <vertical-menu-content></vertical-menu-content>
</perfect-scrollbar>
