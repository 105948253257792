<div class="page-container">
  <app-header></app-header>

  <div class="container">
    <div class="faq-img row">
      <div class="faq-khubii-head col-md-6 col-lg-6 mb-6">
        <h3 mat-title class="text-center mb-4 pb-2 faq-text-color fw-bold" style="font-size: 25px; letter-spacing: 2px;">
          The Swap FAQ
        </h3>
        <p mat-subtitle class="text-center" style="font-size: 20px; font-family: 'Roboto', sans-serif;">
          Everything you need to know so that you can use The Swap as an Expert
        </p>
      </div>
      <div class="col-md-6 col-lg-6 mb-6">
        <img src="../../assets/images/faq-img.png" width="100%" alt="FAQ Image">
      </div>
    </div>

    <div class="p-5 row">
      <mat-accordion multi>
        <!-- Getting Started Section -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <i class="fa fa-quora faq-text-color pe-2" style="color: #004aad;"></i>&nbsp;&nbsp;How to Sign up to The Swap?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="accordion-body">
            <mat-list>
              <mat-list-item>
                <h4 mat-line class="faq-text-color">Signup by clicking on the Signup option on the “TOP RIGHT” corner of the screen. In a new screen, fill in basic details, and after authentication, you can log in with new credentials.</h4>
              </mat-list-item>
            </mat-list>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <i class="fa fa-ravelry faq-text-color pe-2" style="color: #004aad;"></i>&nbsp;&nbsp;Key steps to Quickly start using
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="accordion-body">
            <mat-list>
              <mat-list-item>
                <ul>
                  <li>Login to the application</li>
                  <li>Update profile with profession and expertise</li>
                  <li>Upload Portfolio</li>
                  <li>Your professional profile will be available for public view</li>
                </ul>
              </mat-list-item>
            </mat-list>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <i class="fa fa-area-chart faq-text-color pe-2" style="color: #004aad;"></i>&nbsp;&nbsp;The Swap: For professionals & experts alike.
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="accordion-body">
            <mat-list>
              <mat-list-item>
                <h4 mat-line class="faq-text-color">The Swap supports both Professionals & Experts to showcase their skills and grow their network.</h4>
              </mat-list-item>
            </mat-list>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <i class="fa fa-university faq-text-color pe-2" style="color: #004aad;"></i>&nbsp;&nbsp;The Swap boosts your career
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="accordion-body">
            <mat-list>
              <mat-list-item>
                <h4 mat-line class="faq-text-color">
                  <p>The Swap will help you by:</p>
                  <ul>
                    <li>Providing a dedicated profile page to showcase your expertise</li>
                    <li>Connecting you to a global audience</li>
                    <li>Expanding your professional network</li>
                    <li>Helping you find new opportunities</li>
                  </ul>
                </h4>
              </mat-list-item>
            </mat-list>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <i class="fa fa-grav faq-text-color pe-2" style="color: #004aad;"></i>&nbsp;&nbsp;The Swap offers professional packages!
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="accordion-body">
            <mat-list>
              <mat-list-item>
                <h4 mat-line class="faq-text-color">
                  <p>
                    For professional packages, please contact our support team at
                    <a href="mailto:info@theswap.com">info@theswap.com</a> for more details.
                  </p>
                </h4>
              </mat-list-item>
            </mat-list>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <i class="fas fa-user faq-text-color pe-2" style="color: #004aad;"></i>&nbsp;&nbsp;Profile visibility criteria
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="accordion-body">
            <mat-list>
              <mat-list-item>
                <h4 mat-line class="faq-text-color">
                  <p>
                    To ensure visibility, profiles must have a complete summary, expertise listed, and an updated portfolio. Regular engagement is recommended for better exposure.
                  </p>
                </h4>
              </mat-list-item>
            </mat-list>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
