import { Component, OnInit } from '@angular/core';
import { HomeService } from '../_services/home.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from '../_services/loading.service';
import { MatDialog } from '@angular/material/dialog';
import { PropertyOwnerComponent } from '../property-owner/property-owner.component';
import { CommentComponent } from '../Comment/comment.component';

@Component({
  selector: 'app-lead-details',
  templateUrl: './lead-details.component.html',
  styleUrls: ['./lead-details.component.scss']
})
export class LeadDetailsComponent implements OnInit {
  listing: any;
  listingData: any;
  currentIndex: number = 0;
  data: any;
  id: number;
  UserDetails: any;
  Images: string[] = [];
  asking: number;
  size: number;
  total: number;
  message: string = '';
  messages: string[] = [];
  payload:any;
  comments: { comment: string; createdAt: Date; user: { fullName: string } }[] = [];

  constructor(

    private homeService: HomeService,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private dialog: MatDialog,
    private router: Router,


  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      console.log('params', params);

      this.id = params['id'];
      console.log(this.id);

      if (this.id) {
        this.getLead();
      } else {
        console.error('No Property ID found in query params');
      }
    });
    this.showComment(this.id);
  }


  getLead() {
    this.loadingService.show();
    this.homeService.getLeadwithoutUser(this.id).subscribe((response: any) => {
      if (response.status === 'SUCCESS') {
        this.loadingService.hide();
        this.listingData = response?.payload;
        console.log('listingData', this.listingData);
      } else {
        console.error('Response status is not SUCCESS');
      }
    }, (error) => {
      this.loadingService.hide();
      console.error('API call error', error);
    });
  }

  onTabChange(index: number): void {
    if (index === 1) { // Index 1 = "Comments" tab
      this.showComment(this.id);
    }
  }

  openCommentDialog(){
    this.showComment(this.id);
            this.dialog.open(CommentComponent, {
              width: '70%',
              height:'auto',
              data: { id: this.id },
              panelClass: 'custom-dialog-container'
            });
  }


  openContactDetailsDialog(listingData): void {
    const lastdate = localStorage.getItem('auth-user');
    if (lastdate) {
      try {
        const lastdatedata = JSON.parse(lastdate);
        const lastdatedata1 = JSON.parse(lastdatedata);
        const lastLoginString = lastdatedata1.lastLogin;
        console.log("lastLogin", lastLoginString);
        const lastLogin = this.parseDate(lastLoginString);
        const currentTime = new Date();
        if (lastLogin) {
          const adjustedTime = new Date(currentTime.getTime() - (5 * 60 * 60 * 1000) - (30 * 60 * 1000));
          const timeDifferenceInSeconds = Math.floor((adjustedTime.getTime() - lastLogin.getTime()) / 1000);
          console.log("count 0 to 1800", timeDifferenceInSeconds);
          if (timeDifferenceInSeconds <= 1800) {
            this.dialog.open(PropertyOwnerComponent, {
              data: listingData,
              width: '440px',
              height:'270px'
            });
          }
         else {
          console.error('Invalid date parsed:', lastLogin);
          this.router.navigate(['/login']);
        }
      }
      } catch (error) {
        console.error('Error parsing lastdate:', error);
        this.router.navigate(['/login']);
      }
    } else {
      console.log('User is not logged in.');
      this.router.navigate(['/login']);
    }
  }

  parseDate(dateString: string): Date {
    const parts = dateString.split(' ');
    const dateParts = parts[0].split('-');
    const timeParts = parts[1].split(':');
    return new Date(
      parseInt(dateParts[2], 10),
      parseInt(dateParts[1], 10) - 1,
      parseInt(dateParts[0], 10),
      parseInt(timeParts[0], 10),
      parseInt(timeParts[1], 10),
      parseInt(timeParts[2], 10)
    );

  }

  showComment(id: any) {
    this.loadingService.show();
    this.homeService.showComment(id).subscribe(
      (response: any) => {
        this.loadingService.hide();
        if (response.status === 'SUCCESS') {
          this.comments = response.payload?.map((item: any) => ({
            comment: item.comment,
            createdAt: item.createdAt,
            user: item.user
          })) || [];
          console.log (this.comments );

          if (this.comments.length === 0) {
            console.error('No comments found in the response');
          }
        } else {
          console.error('Response status is not SUCCESS');
        }
      },
      (error) => {
        this.loadingService.hide();
        console.error('API call error:', error);
      }
    );
  }






}
