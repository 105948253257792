import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '@app/layout/service/common.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-loader',
  templateUrl: './modal-loader.component.html',
  styleUrls: ['./modal-loader.component.css']
})
export class ModalLoaderComponent implements OnInit {
  @Input() email: string;
  title: string;
  closeBtnName: string;
  list: any[] = [];
  modalRef: BsModalRef;
  isLoading:boolean=false
  subs$!: Subscription;
  number:any;

  
  constructor(public bsModalRef: BsModalRef,
    private _commanServcie:CommonService,
    ) {}
 
  ngOnInit() {
    // this.list.push('PROFIT!!!');
    // this.modalRef.hide();
  }
}
