import { Component, OnInit } from '@angular/core';
import {FormBuilder,FormGroup,Validators,FormControl,FormArray,AbstractControl} from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import Swal from 'sweetalert2';
import { TokenStorageService } from '../_services/token-storage.service';
import * as AOS from 'aos';
import {environmentbasic} from '../../environments/environment-basic';
import { ErrorMessageService } from '../services/error-message.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {

	otp: string = '';
	number: any;
	isMessage: boolean = false;
	message: string = '';
	status: boolean = false;
	email: string;

	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthService,
		private tokenStorage: TokenStorageService,
		private route: ActivatedRoute,
		private router:Router,
  private errorMessageService:ErrorMessageService) { }


	resetform: FormGroup;
	id!: string;
    loading = false;
    submitted = false;
    otpactive = false;
   	pramaval:any=[];
   	useremail='';

	ngOnInit(): void {
		AOS.init();
		this.route.params.subscribe(val => {
			this.pramaval=val;
			if(this.pramaval && this.pramaval.email){
					this.useremail=this.pramaval.email;
			}else{
				this.router.navigate(['/login']);
			}
		});

		this.resetform = this.formBuilder.group({
			newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
		},
    {
      validator: this.passwordMatchValidator // Attach the custom validator
    }
  );
	}

	// get l() { return this.resetform.controls; }
  get l() {
    return this.resetform.controls;
  }
	onOtpChange(otp: string) {
		this.otp = otp;
		console.log(otp);

	  }
    otpConfig = {
      length: 6,
      allowNumbersOnly: true
    };



    passwordMatchValidator(control: AbstractControl) {
      const password = control.get('newPassword');
      const confirmPassword = control.get('confirmPassword');

      if (password && confirmPassword && password.value !== confirmPassword.value) {
          confirmPassword.setErrors({ ...(confirmPassword.errors || {}), mustMatch: true });
      } else if (confirmPassword.errors) {
          delete confirmPassword.errors.mustMatch;
          if (Object.keys(confirmPassword.errors).length === 0) {
              confirmPassword.setErrors(null);
          }
      }
      return null;
  }










	onResetSubmit(){
		this.submitted = true;
		if (this.resetform.invalid) {
            return;
        }
		this.authService.forgotpassword(this.otp, this.resetform.value.newPassword,this.resetform.value.confirmPassword,this.useremail).subscribe(
		response => {
			if(response['status']=='SUCCESS'){

				Swal.fire({
				  icon: 'success',
				  title: 'Password Reset is successfully.',
				  showConfirmButton: false,
				  timer: environmentbasic.timer
				});
				this.router.navigate(['/login']);
			}
      else if (response['message']) {
        let errorMessage = this.errorMessageService.getErrorMessage(response['message']);
        Swal.fire({
          icon: 'error',
          title: errorMessage,
          showConfirmButton: false,
          timer: environmentbasic.timer
        });
        }
		});
	}

  showPasswords:boolean=false
  showPassword:boolean=false
  togglePasswordVisibilitys(){
    this.showPasswords = !this.showPasswords;
 }
 togglePasswordVisibility(): void {
  this.showPassword = !this.showPassword;
}
}
