<div class="privacy-container">
  <img src="../../assets/images/policy.jpg" alt="About Us" class="aboutus">
  <h1>Privacy Policy </h1>
  <p class="intro">At theswap.in, we value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your data when you use our platform.</p>

  <h2>1. Information We Collect</h2>
  <ul class="privacy-list">
    <li><b>Personal Information:</b> Name, phone number, email address, and location when you register or request a service.</li>
    <li><b>Service Details:</b> Information about the services you are looking for or offering.</li>
    <li><b>Usage Data:</b> Your interactions with the website, such as pages visited and search history.</li>
    <li><b>Device & Location Data:</b> Your IP address, browser type, and approximate location (if allowed).</li>
  </ul>

  <h2>2. How We Use Your Information</h2>
  <ul class="privacy-list">
    <li>✅ Connect you with relevant service providers or customers.</li>
    <li>✅ Improve our platform by analyzing user behavior.</li>
    <li>✅ Send you updates, promotions, and important notifications.</li>
    <li>✅ Prevent fraud, spam, and unauthorized activities.</li>
    <li>✅ Respond to your inquiries and customer support requests.</li>
  </ul>

  <h2>3. Sharing of Information</h2>
  <p>We do not sell or rent your personal data to third parties. Your contact details may be shared only with service providers when you request a service. We may disclose information if required by law or to protect our platform from fraud and misuse.</p>

  <h2>4. Data Security</h2>
  <p>We take strict security measures to protect your data, including encryption and secure servers. However, while we strive to protect your information, we cannot guarantee 100% security due to external threats.</p>

  <h2>5. Cookies & Tracking Technologies</h2>
  <p>We use cookies to improve user experience and analyze website traffic. You can disable cookies in your browser settings, but some features may not work properly.</p>

  <h2>6. Your Rights & Choices</h2>
  <ul class="privacy-list">
    <li>✅ Edit or delete your personal information from your account.</li>
    <li>✅ Unsubscribe from promotional messages.</li>
    <li>✅ Contact us for any concerns about your data.</li>
  </ul>

  <h2>7. Changes to This Policy</h2>
  <p>We may update this Privacy Policy from time to time. Please check this page regularly to stay informed.</p>

  <h2>8. Contact Us</h2>
  <p>If you have any questions about our Privacy Policy, feel free to contact us at <b>info@ksquaretech.co.in</b>.</p>

  <p class="agreement">By using theswap.in, you agree to this Privacy Policy. If you do not agree, please stop using our platform.</p>
</div>
