<!--- Created by Mitchell Boland from spangle web design spangle.com.au -->
<!--- Send me a message there if you require a website, or would like some css help -->

<!DOCTYPE html>
<html>

<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
  <title>Spangle Web Design</title>
  <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
    integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
  <link href="https://fonts.googleapis.com/css?family=Maven+Pro:400,500,700,900" rel="stylesheet">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bulma/0.7.2/css/bulma.css">
</head>

<body>
  <div class="termsAndConditions fadeIn">
    <h1 class="termsAndConditionsHeading">Terms Of Service</h1>
    <!-- <h4 class="spangleWelcome">Welcome to Spangle</h4> -->
    <p class="termsParagraphIntro">The Ksquare respects your privacy and we are committed to the security of your
      information when you Submit your Profile to us. This Privacy Policy will tell you about how we handle your
      Personal Information from your use of the Job Portal and applications, how we use and protect that information,
      and what choices you have concerning how such information is used on the Job Portal you are viewing, as well as
      any other The Ksquare-affiliated Job Portal to which you may link from this Job Portal.
    </p>
    <div class="serviceLeadingSection">
      <h4 class="serviceLead">What Information We Collect and How that Information May Be
        Used</h4>
      <p class="spl">The Ksquare considers information that can identify, or be used to contact, a unique individual as
        “Personal Information.” When you use our Job Portal or applications, we may collect Personal Information from
        you.
        This Personal Information may be information you submit directly to The Ksquare; or it may be information that the
        technology present on our Job Portal, or in our applications, collects automatically. This information would
        include
        your profile information which includes but may not be limited to your e-mail address, address, and telephone
        number. Depending on the nature of your inquiry or activities on the Job Portal and applications, we may also
        collect your job search criteria and preferences, employment experience, educational history, skills, references
        information, background information, and other information contained in your resume, and any login ID or email
        address that you create. Additionally, by creating a profile and logging into our Job Portal, we may collect and
        use
        automatically collected information (like where you navigated through our Job Portal, or what features of our
        applications you used) </p>
      <div class="serviceInfoContainer">
        <!-- <h6 class="serviceLead">Some service info</h6> -->
        <p class="serviceDetails">
          As noted earlier, when you use our Job Portal and applications, we use automated means of collecting
          information, such
          as cookies and other web-based or electronic tracking technologies to collect information about your use of
          the Job
          Portal or application. This information is necessary in order for us to provide you with personalized and
          location-based content, as well as for analyzing the use of resources, troubleshooting problems, preventing
          fraud, and
          improving our services. Collected items may include the pages most frequently visited, services accessed the
          most, and
          links to other sites that users most often visit. We measure activity in aggregate, such as number of users of
          our Job
          Portal, and activities conducted on our Job Portal, but may sometimes choose to measure use on an individual
          level.
          This allows us to provide a better user experience.
        </p>
        <!-- <div class="secionLine lineColorBlue"></div> -->
      </div>
      <div class="serviceInfoContainer">
        <h6 class="serviceLead">The Ksquare uses the information we collect for the following purposes: </h6>
        <p class="serviceDetails">(1) to provide you with information regarding employment opportunities and
          career-related
          information; <br />(2) establishing and maintaining communications with you;<br /> (3) where you have
          requested a
          service, assisting you in the completion of your application, the assessment of your eligibility for any such
          requested service, the processing and maintenance of the service, as well as any applicable renewal of such
          service;
          <br />(4) responding to your inquiries about applications, accounts and other services;<br /> (5) making
          proposals
          for future service needs;<br />(6) allowing our affiliated companies to notify you of certain products or
          services
          offered by our affiliated companies;<br /> (7) processing transactions through service providers; <br />(8)
          complying with applicable legal requirements, court orders, legal proceedings, document requests, and industry
          standards and our policies; <br />(9) protecting against fraud, suspicious or other illegal activities;<br />
          (10)
          protecting the rights of The Ksquare or other third parties; <br />(11) compiling statistics for analysis of our
          sites and our business.
        </p>
        <!-- <div class="secionLine lineColorBlue"></div> -->
      </div>
      <div class="serviceInfoContainer">
        <h6 class="serviceLead">How We May Share Your Information</h6>
        <p class="serviceDetails">The Ksquare may disclose your Personal Information: to third parties to perform business
          functions;
          <br /><br />
          The Ksquare may share your Personal Information with third parties or The Ksquare customers for employment,
          recruitment,
          and placement services, for potential employment opportunities with customers who are seeking employees who
          have your
          qualifications, or to a customer with whom you have been placed by The Ksquare, in order to integrate with their
          employment or application process. The Ksquare may also share your information with third party service
          providers which
          are under contract to us, for the provision of the services for which you or one of our clients has
          contracted. These
          service providers have agreements with The Ksquare to handle your Personal Information the same way The Ksquare
          would. For
          example, The Ksquare uses analytics providers which track your use of the Job Portal or application. The Ksquare
          will only
          share Personal Information about you that is relevant to our legitimate business purposes or as stated in this
          Privacy
          Policy.
        </p>
        <!-- <div class="secionLine lineColorBlue"></div> -->
      </div>
      <div class="serviceInfoContainer">
        <h6 class="serviceLead">Protecting Your Information</h6>
        <p class="serviceDetails">We want your information to remain as secure as reasonably possible. We regularly
          monitor
          industry standard technical safeguards for securing your Personal Information and review our physical,
          technical, and
          organizational security practices to determine how we can prevent the loss, misuse, unauthorized access,
          alteration,
          or disclosure of your Personal Information. While we strive to protect your Personal Information, considering
          the
          nature of the Internet, and your control over your own access codes, The Ksquare cannot ensure or warrant the
          security
          of any information you transmit to us.</p>
        <!-- <div class="secionLine lineColorBlue"></div> -->
      </div>
      <div class="serviceInfoContainer">
        <h6 class="serviceLead">Your Rights and Choices</h6>
        <p class="serviceDetails">We may also, from time to time, use your Personal Information to send you messages or
          marketing materials regarding our services, including employment information and job opportunities that you
          indicate
          an interest in when creating a profile. You may receive these messages from The Ksquare via email, phone, direct
          mail,
          and other communication means.<br /><br />
          You may choose to refuse cookies, which will restrict our ability to report on individual use - simply refer
          to your
          browser’s help instructions to learn more about how to manage their use.
          <br /><br />
          The major browsers have attempted to implement the draft “Do Not Track” (“DNT”) standard of the World Wide Web
          Consortium (“W3C”) in their latest releases. As this standard has not been finalized, The Ksquare Job Portal is
          not
          compatible with DNT and so does not recognize DNT settings.
        </p>
        <!-- <div class="secionLine lineColorBlue"></div> -->
      </div>
      <div class="serviceInfoContainer">
        <h6 class="serviceLead">How to Contact The Ksquare</h6>
        <p class="serviceDetails">If at any time you have questions or concerns about The Ksquare privacy commitment or
          anything
          contained in this Privacy Policy, please feel free to contact us via the Contact Us form, via e-mail at
          hr@theKsquare.co.in, at the address below.<br /><br />
          Our postal address is:<br />
          The Ksquare<br />
          Hemkunth Chambers, 3rd Floor, 310, Nehru Place, New Delhi-19

        </p>
        <!-- <div class="secionLine lineColorBlue"></div> -->
      </div>
    </div>

    <div class="serviceLeadingSection">
      <h4 class="serviceLead">Consent and Changes to Privacy Policy</h4>
      <p class="spl">By using this Job Portal or our applications, you acknowledge and consent to the privacy practices
        described in this Privacy Policy, including the collection and use of your information as described herein. The
        Ksquare
        may change this Privacy Policy from time to time due to changes in relevant law or The Ksquare business practices.
        If we
        decide to make changes to this Privacy Policy, we will post the changes on this Job Portal, and your continued
        usage
        after such changes are posted constitutes acceptance of each revised Privacy Policy <br />
        This Privacy Policy was last revised and is effective as 24th Dec 2023
      </p>
    </div>
  </div>
</body>

</html>
<!-- <div class="serviceInfoContainer">
          <h6 class="serviceLead">Some service info</h6>
          <p class="serviceDetails">Lorem ipsum dolor sit amet consectetur adipisicing elit. 
            Perferendis repellendus rem quas eius, deleniti quos pariatur earum nihil voluptate inventore 
            vel atque possimus labore laborum, reprehenderit maxime, placeat quo corrupti.</p>
          <div class="secionLine lineColorOrange"></div>
        </div>
        <div class="serviceInfoContainer">
          <h6 class="serviceLead">Some service info</h6>
          <p class="serviceDetails">Lorem ipsum dolor sit amet consectetur adipisicing elit. 
            Perferendis repellendus rem quas eius, deleniti quos pariatur earum nihil voluptate inventore 
            vel atque possimus labore laborum, reprehenderit maxime, placeat quo corrupti.</p>
          <div class="secionLine lineColorOrange"></div>
        </div>
        <div class="serviceInfoContainer">
          <h6 class="serviceLead">Some service info</h6>
          <p class="serviceDetails">Lorem ipsum dolor sit amet consectetur adipisicing elit. 
            Perferendis repellendus rem quas eius, deleniti quos pariatur earum nihil voluptate inventore 
            vel atque possimus labore laborum, reprehenderit maxime, placeat quo corrupti.</p>
          <div class="secionLine lineColorOrange"></div>
        </div>                    -->
<!-- </div> -->
<!-- <div class="serviceLeadingSection">
        <h4><span class="sn lightGreen">3.</span><span class="st lightGreen">Lorem ipsum</span></h4>
        <p class="spl">Lorem ipsum dolor sit amet consectetur, adipisicing elit. 
          Explicabo expedita minima architecto adipisci atque neque libero 
          facilis officia blanditiis provident 
          repudiandae numquam earum dolorem hic quis ipsa, a quisquam placeat:</p>
        <div class="serviceInfoContainer">
          <h6 class="serviceLead">Some service info</h6>
          <p class="serviceDetails">Lorem ipsum dolor sit amet consectetur adipisicing elit. 
            Perferendis repellendus rem quas eius, deleniti quos pariatur earum nihil voluptate inventore 
            vel atque possimus labore laborum, reprehenderit maxime, placeat quo corrupti.</p>
          <div class="secionLine lineColorGreen"></div>
        </div>
        <div class="serviceInfoContainer">
          <h6 class="serviceLead">Some service info</h6>
          <p class="serviceDetails">Lorem ipsum dolor sit amet consectetur adipisicing elit. 
            Perferendis repellendus rem quas eius, deleniti quos pariatur earum nihil voluptate inventore 
            vel atque possimus labore laborum, reprehenderit maxime, placeat quo corrupti.</p>
          <div class="secionLine lineColorGreen"></div>
        </div>
        <div class="serviceInfoContainer">
          <h6 class="serviceLead">Some service info</h6>
          <p class="serviceDetails">Lorem ipsum dolor sit amet consectetur adipisicing elit. 
            Perferendis repellendus rem quas eius, deleniti quos pariatur earum nihil voluptate inventore 
            vel atque possimus labore laborum, reprehenderit maxime, placeat quo corrupti.</p>
          <div class="secionLine lineColorGreen"></div>
        </div>        
      </div> 
      <div class="serviceLeadingSection">
        <h4><span class="sn purple">4.</span><span class="st purple">Lorem ipsum</span></h4>
        <p class="spl">Lorem ipsum dolor sit amet consectetur, adipisicing elit. 
          Explicabo expedita minima architecto adipisci atque neque libero 
          facilis officia blanditiis provident 
          repudiandae numquam earum dolorem hic quis ipsa, a quisquam placeat:</p>
        <div class="serviceInfoContainer">
          <h6 class="serviceLead">Some service info</h6>
          <p class="serviceDetails">Lorem ipsum dolor sit amet consectetur adipisicing elit. 
            Perferendis repellendus rem quas eius, deleniti quos pariatur earum nihil voluptate inventore 
            vel atque possimus labore laborum, reprehenderit maxime, placeat quo corrupti.</p>
          <div class="secionLine lineColorPurple"></div>
        </div>
        <div class="serviceInfoContainer">
          <h6 class="serviceLead">Some service info</h6>
          <p class="serviceDetails">Lorem ipsum dolor sit amet consectetur adipisicing elit. 
            Perferendis repellendus rem quas eius, deleniti quos pariatur earum nihil voluptate inventore 
            vel atque possimus labore laborum, reprehenderit maxime, placeat quo corrupti.</p>
          <div class="secionLine lineColorPurple"></div>
        </div>
        <div class="serviceInfoContainer">
          <h6 class="serviceLead">Some service info</h6>
          <p class="serviceDetails">Lorem ipsum dolor sit amet consectetur adipisicing elit. 
            Perferendis repellendus rem quas eius, deleniti quos pariatur earum nihil voluptate inventore 
            vel atque possimus labore laborum, reprehenderit maxime, placeat quo corrupti.</p>
          <div class="secionLine lineColorPurple"></div>
        </div>        
      </div>    
      <h4 class="closeTerms">CLOSE TERMS AND CONDITIONS</h4>
    </div> -->