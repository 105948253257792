<!-- <mat-dialog-content>
    <div mat-dialog-title>
      <label class="mb-3 col-12">Verify OTP<span class="text-danger"></span></label>
    </div>
    <div mat-dialog-content class="text-center" style="border-radius: 25px;">
      <div class="spinner"></div>
      <div  id="otp"class="form-group">
        <ng-otp-input (onInputChange)="onOtpChange($event)"[config]="{length:6}"></ng-otp-input>
      </div>
      <br>
      <div class="button-container">
        <button mat-raised-button color="primary" (click)="verifyOtp()">Verify OTP</button>
      </div>
    </div>
  </mat-dialog-content>
   -->

   <mat-dialog-content>
    <div class="first-row">
      <span class="title">Enter OTP</span>
      <div class="close-container">
        <button class="close-button" (click)="closeDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
    </div>

    <div class="otp-card">
      <p class="p4">Check your email. OTP sent on &nbsp;<strong>{{ email }}</strong></p>
      <div id="otp" class="form-group">
        <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="otpConfig"></ng-otp-input>
      </div>
      <div class="button-container mt-2">
        <button mat-raised-button color="primary" (click)="verifyOtp()">Verify OTP</button>
      </div>
    </div>
  </mat-dialog-content>
