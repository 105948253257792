import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { HttpService } from './http.service';
import { ApiURL } from './api';
import { IResetPassword, login, loginResponse } from '../shared/typings/app.typings';
import { CONSTANTS_TEXT } from 'src/app/layout/const/app.constant';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private httpservice:HttpService,
    private httpClient: HttpClient,
  
    ) { }

  // login(object:login){
  //   return this.httpservice.postAll(ApiURL.login, object)
  // }

// loginApi(data:login){
//   let httpheader = new HttpHeaders()
//   .set('Authorization', 'Basic d2ViOnNlY3JldA==');
// let options = {
//   headers: httpheader
// };
// return this.httpClient.post<any>(ApiURL.login+'?grant_type=password'+'&username='+data.username+'&password='+data.password, null, options)
// }

loginApi(data:any){
  console.log(data);
  
let httpheader = new HttpHeaders()
.set('Authorization', 'Basic d2ViOnNlY3JldA==');
let options = {
headers: httpheader
};
return this.httpClient.post<any>(ApiURL.login+'?platform=portal'+'&userName='+data.email
+'&password='+data.password+'&countryCode=91', null, options)
}


userProfile(data:loginResponse){
  let httpheader = new HttpHeaders()
  .set('Authorization', `Basic ${data.access_token}`);
    let options = {
      headers: httpheader
    };
  return this.httpClient.get<any>(ApiURL.userPofile, options)
}
getProfileData(){
  let httpheader = new HttpHeaders()
  .set('Authorization', `Basic ${true}`);
    let options = {
      headers: httpheader
    };
  return this.httpClient.get<any>(ApiURL.userPofile, options)
}
verifyUserToken(access_token){
  let httpheader = new HttpHeaders()
  .set('Authorization', `Basic ${access_token}`);
    let options = {
      headers: httpheader
    };
  return this.httpClient.get<any>(ApiURL.userPofile, options)
}
  
forgetPassword(data:any){
  let httpheader = new HttpHeaders()
  .set('Authorization', 'Basic d2ViOnNlY3JldA==');
  let options = {
    headers: httpheader
  };
return this.httpClient.post<any>(ApiURL.forget+'?email='+data?.username, null, options)
}

otpVerify(email:any,payload:IResetPassword){
  let httpheader = new HttpHeaders()
  .set('Authorization', 'Basic d2ViOnNlY3JldA==');
  let options = {
    headers: httpheader
  };
return this.httpClient.post<any>(ApiURL.otpVerify+'?email='+email, payload, options)
}

// getUserdata(data:any,payload:any){
//   let user = localStorage.getItem(CONSTANTS_TEXT.user)
//   .set('Authorization', `Basic ${data.access_token}`);
//     let options = {
//       headers: httpheader
//     };
//   return this.httpClient.get<any>(ApiURL.getUserData+data?.username)
// }
}
