<footer class="footer-classic footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-12 mt-3"></div>
      <div class="col-lg-2 col-md-6 col-sm-12 mt-3">
        <h3>Legal Content</h3>
        <ul class="nav-list">
          <li><a routerLink="/termsandcondition"><i class="fa fa-files-o" aria-hidden="true"></i> Term and Conditions</a></li><br><br>
          <li><a routerLink="/privacyPolicy" ><i class="fa fa-user-secret" aria-hidden="true"></i> Privacy Policy</a></li><br><br>
          <li><a routerLink="/FAQ" ><i class="material-icons" aria-hidden="true">question_answer</i> Faqs</a></li><br>
        </ul>
      </div>
      <div class="col-lg-2 col-md-6 col-sm-12 mt-3">
        <h3>Company</h3>
        <ul class="nav-list">
          <li><a routerLink="/aboutUs"><i class="fa fa-users" aria-hidden="true"></i> About Us</a></li><br><br>
          <li><a routerLink="/" ><i class="fas fa-mobile-alt"></i>
            +91-7838656543</a></li><br><br>
            <li><a href="mailto:info@ksquaretech.co.in" ><i class="fa fa-envelope" aria-hidden="true"></i>
              info@ksquaretech.co.in</a></li><br>
        </ul>
      </div>
      <div class="col-lg-2 col-md-6 col-sm-12 mt-3">
        <h3>Office Address</h3>
        <div style="font-size: 14px;">
          Ksquaretch Consulting , Flat-103, Plot-478, Krishna colony, Gurgaon, Haryana 122001, India
        </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12 mt-3"></div>
      <div class="col-12 mt-1 text-center">
        <ul class="nav-list"><br>
          <li>©2025 Theswap.co.in Team. All Rights Reserved.</li>
        </ul>
      </div>
    </div>
  </div>
</footer>
