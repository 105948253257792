<header class="header_p">
	<div class="navigation-wrap bg-light stlatest-header stlatest-style">
		<div class="container-fluid mob-menu-width">
			<div class="row" style="margin-top: 5px; box-shadow: 0 1px 6px rgba(57, 63, 72, 0.3);">
				<div class="col-12 col-md-12 col-sm-12">
					<nav class="navbar navbar-expand-xl navbar-light " style="padding: 5px;">
						<button class="navbar-toggler" type="button" #navbarToggler1 data-toggle="collapse"
							data-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1"
							aria-expanded="false" aria-label="Toggle navigation">
						</button>
						<a class="navbar-brand mr-0" routerLink="/">
							<img src="../../assets/images/logo-no-background.png" alt="" style="width: 55px;">
						</a>
						<div class="collapse navbar-collapse" id="navbarSupportedContent" style="width:100%"
						[ngClass]="{'collapse': collapsed1, 'navbar-collapse': true}">
						<ul class="navbar-nav ml-auto p-0 right-header-content ">
							<li class="nav-item" *ngIf="userinfo && userinfo.email; else notlogin">
								<div class="user-info-container d-flex justify-content-between align-items-center p-2" style="font-size: 20px;">
								  <div class="left-section d-flex align-items-center">
									<a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
									  aria-expanded="false" style="font-weight: bold;">
									  <span *ngIf="userName; else showFullName" style="font-weight: bold;">{{userfullname}}</span>&nbsp;&nbsp;
									  <ng-template #showFullName>
										<span class="username" style="font-weight: bold;">{{userfullname}}</span>
									  </ng-template>
									</a>
								  </div>
								  <div class="right-section d-flex align-items-center">
									<a routerLink="/add-lead" class="add-property-link" style="width: 80px;">Add Lead</a>
									<a routerLink="/add-lead" class="add-Ksa mr-2">
									  <i class="fa fa-plus" aria-hidden="true"></i>
									</a>
									<button mat-icon-button [matMenuTriggerFor]="menu" class="profile-icon-button">
									  <i class="fa fa-user-circle" style="color: #004aad; font-size: 34px;"></i>
									</button>
								  </div>
								</div>

								<mat-menu #menu="matMenu">
								  <button mat-menu-item routerLink="update/profile/username/userid/activetab">
									<i class="fas fa-user" style="color: #004aad; font-size: 16px;"></i>
									<span>&nbsp; My Profile</span>
								  </button>
								  <button mat-menu-item (click)="navigateToUserLead()">
									<i class="fa fa-building" style="color: #004aad; font-size: 16px;"></i>
									&nbsp; My Leads
								  </button>
								  <button mat-menu-item (click)="userLogout()">
									<i class="fas fa-sign-out-alt" style="color: #004aad; font-size: 16px;"></i>
									&nbsp; Logout
								  </button>
								</mat-menu>
							  </li>

							<ng-template #notlogin>
								<!-- <li class="nav-item">
								  <div class="nav-content">
									<a routerLink="/login" class="add-property-link" style="width: 80px;">Add Lead</a>
									<a routerLink="/login" class="add-Ksa">
										<i class="fa fa-plus" aria-hidden="true"></i>
									</a>&nbsp;
									<a routerLink="/login" class="login-signup-link">Login | Signup</a>
								  </div>
								</li> -->
								<li class="nav-item">
									<div class="user-info-container d-flex justify-content-between align-items-center p-2" style="font-size: 20px;">
									  <div class="left-section d-flex align-items-center">
									  </div>
									  <div class="right-section d-flex align-items-center">
										<a routerLink="/login" class="add-property-link" style="width: 80px;">Add Lead</a>
										<a routerLink="/login" class="add-Ksa mr-2">
										  <i class="fa fa-plus" aria-hidden="true"></i>
										</a>
										<a routerLink="/login" class="login-signup-link" style="color: #004aad;">Login | Signup</a>
									  </div>
									</div>


								  </li>
							  </ng-template>
						</ul>
					  </div>
					</nav>
				</div>
			</div>
		</div>
	</div>
</header>
