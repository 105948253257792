<div class="about-container">
  <img src="../../assets/images/abouts.jpg" alt="About Us" class="aboutus">
  <h1>Welcome to <span class="highlight">theswap.in</span></h1>
  <p class="lead">Your one-stop destination for finding trusted professionals and service providers near you!</p>

  <div class="section">
      <h2>What We Do</h2>
      <p>At <span class="highlight">theswap.in</span>, we aim to simplify the process of hiring professionals. Whether you need a plumber, an electrician, a tutor, a digital marketing expert, or a wedding planner, we have got you covered.</p>
  </div>

  <div class="section">
      <h2>Why Choose Us?</h2>
      <ul class="features">
        <li><b>Find Trusted Professionals</b> – We connect you with verified and skilled service providers.</li>
        <li><b>Quick and Easy</b> – Just enter your requirement, and we will help you find the best match.</li>
        <li><b>Many Categories</b> – From home repairs to business services, we cover everything.</li>
        <li><b>Hassle-Free Experience</b> – No more searching endlessly; we bring the best options to you.</li>
        <li><b>Customer Satisfaction</b> – We focus on quality services and happy customers.</li>
    </ul>

  </div>

  <div class="section">
      <h2>Our Mission</h2>
      <p>Our mission is simple – to make life easier by connecting people with the right service providers. We believe in creating a reliable and user-friendly platform where customers can find the best professionals without any stress.</p>
  </div>
</div>
