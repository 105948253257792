
export const CONSTANTS_TEXT = {
   signup:'signup',
   error:'error',
   info:'info',
   danger:'danger',
   success:'success',
   warning:'warning',
   email:'email',
   create:'create',
   edit:'edit',
   token:'token',
   role:'role',
   status:"SUCCESS",
   roleadmin:"Admin",
   access_token:'access_token',
   refresh_token:'refresh_token',
   user_access:'user_access',
   requiredAllFields:'All fields are required!',
   loginSucces: 'You are successfully logged in',
   loginError:'Incorrect Credentials!',
   invalidCredentials:'Invalid Credentials!',
   invalidEmail:'Invalid Email!',
   logoutSucces: 'Logged out Sucessfully!',
   search:'search',
   noticperiod:'Notice Period?',
   servingNotice:'Serving Notice Period',
   text:'text',
   select:'select',
   number:'number',
   date:'date',
   daterange:'daterange',
   type:'type',
   commanfilterdateFormate:'DD-MM-YYYY',
   formatedate:'DD-MMM-YYYY',
   filter:'filter',
   parms:'query',
   sorting:'sorting',
   SUCCESS:'SUCCESS',
   details_save:'Your Detail has been saved.',
   created:' have been created.',
   details_upadte:'Your Detail has been updated.',
   spoc_created:'Your Detail has been created.',
   dwn_success:'Download Successfully.',
   update:'has been updated',
   add:'has been Created ',
   deleterecod:'Record Deleted Successfully.',
   Country:'Country',
   Qualifications:'Qualifications',
   Interview:'Interview',
   Location:'Location',
   Institute:'Institute',
   State:'State',
   City:'City',
   Date:'Date',
   Industry:'Industry',
   Designations:'Designations',
   offerDesignations:'offerDesignations',
   Departments:'Departments',
   Certifications:'Certifications',
   InterviewSchedule:'InterviewSchedule',
   Role:'Role',
   Education:'Education',
   Company:'Company',
   Employee:'Employee',
   Requirement:'Requirement',
   Golverole:'Golverole',
   Assignement:'Assignement',
   EmployeeAction:'EmployeeAction',
   ExperienceLevel: 'ExperienceLevel',
   FunctionalArea:'FunctionalArea',
   save:'save',
   StatusClient:'StatusClient',
   Feedback: 'Feedback',
   Reasons: 'Reasons',
   jcid:'jcid',
   SaveSearch:'SaveSearch',
   userName:'userName',
   deleteRole:'Role Updated Successfully',
   EXCEPTION:'EXCEPTION',
   E_error:'Something went wrong',

   Airport:'Airport',
   Terminal:'Terminal',
   BusinessCategory:'BusinessCategory',
   productCategory:'ProductCategory',
   please_Select_option:'please select filter option',
   addValidation:'addValidation',
   removeValidation:'removeValidation',
   productListing:'productListing',
   productFilterData:'productFilterData',
   uploadD:'File Uploaded Successfully.',

   //Country  
   countryCodeExist:'Country Code is Already Exist',
   counrtyCodeRequired:'Country Code is Required',
   countryNameExist:'Country Name is Already Exist',
   counrtyNameRequired:'Country Name is Required',
   
   
   countryIdForCity:'countryIdForCity',
   stateIdForCity:'stateIdForCity',

   //city
   cityCodeExist:'City Code is Already Exist',
   cityCodeRequired:'City Code is Required',

   //state
   countryIdForState:'CountryIdForStateList',
   StateCodeExist:'State Code is Already Exist',
   StateCodeRequired:'State Code is Required',

   //industry
   industryCodeExist:'Industry Code is Already Exist',
   industryCodeRequired:'Industry Code is Required',
   industryNameExist:'Industry is Already Exist',
   industryNameRequired:'Industry is Required',

   //department
   departmentCodeExist:'Department Code is Already Exist',
   departmentCodeRequired:'Department Code is Required',
   departmentNameExist:'Department is Already Exist',
   departmentNameRequired:'Department is Required',

   //qualification
   qualificationNameExist:'Qualification is Already Exist',
   qualificationNameRequired:'Qualification is Required',
   
    //Experience level
    experienceNameExist:'Experience is Already Exist',
    experienceNameRequired:'Experience is Required',

    //institute
    instituteNameExist:'Institute is Already Exist',
    instituteNameRequired:'Institute is Required',

    //interview
   interviewNameExist:'Interview is Already Exist',
   interviewNameRequired:'Interview is Required',

   //Designation
   designationIdExist:'Designation ID is Already Exist',
   designationIdRequired:'Designation ID is Required',
   designationNameExist:'Designation is Already Exist',
   designationNameRequired:'Designation is Required',

   //Candidate Role
   croleNameExist:'Job Role is Already Exist',
   croleNameRequired:'Job Role is Required',
   croleIdExist:'Candidate ID is Already Exist',
   croleIdRequired:'Candidate ID is Required',

   //Certifications
   certificationNameExist:'Certification is Already Exist',
   certificationNameRequired:'Certification is Required',

   //Functional Area
   functionalAreaNameExist:'Functional Areas is Already Exist',
   functionalAreaNameRequired:'Functional Areas is Required',
   
   //role
   roleNameExist:'Employee Role is Already Exist',
   roleNameRequired:'Employee Role is Required',

   //Location
   locationNameExist:'Location is Already Exist',
   locationNameRequired:'Location is Required',

   //Company
    companyNameExist:'Company is Already Exist',
    companyNameRequired:'Company is Required',

   //Education
   educationNameExist:'Education is Already Exist',
   educationNameRequired:'Education is Required',

   //OfferDEsignation
   offerNameExist:'Offer Designation is Already Exist',
   offerNameRequired:'Offer Designation is Required',

   //user
   user:'user',
   previousValue:'Please fill previous form value',
   previousValuecontrol:'Please fill previous Question value',

   // form-field-check 
   shortanswer:'Short Answer',
   paragraph:'Paragraph',
   multiple_choice:'Multiple choice',
   option:'Option',
   formtypetext:'text',
   formtypenumber:'number',
   validationyes:'YES',
   validationno:'NO',
   menuAll:'menuAll',
   loginTime:'loginTime',
   allEmployees:'allEmployees',
   feedBack:'feedBack',
   contactAdmin:'Something went wrong, Please contact Adminstrator'

}


