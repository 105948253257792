<div class="container-fluid">
  <div class="contain {{swapclass}}" id="contain">
    <div class="form-contain sign-up-contain">
      <!-- <div class="form-wrapper"> -->
        <form  *ngIf="regform" [formGroup]="regform" (ngSubmit)="onSubmit()" class="account account1 reg{{registrationactive}}">

          <h2 class="createaccount">Create Account</h2>
          <div class="row">
            <div class="col-sm-12 col-12">
              <input type="text" placeholder="Full Name *" formControlName="fullName" />
              <div class="errorClass" *ngIf="(regform?.controls?.fullName?.touched || regform?.controls?.fullName?.dirty || submitted) && regform?.controls?.fullName?.errors">
                <div *ngIf="regform?.controls?.fullName?.errors?.required">Full Name is required</div>
              </div>

            </div>

            <div class="col-sm-12 col-12">
              <div class="position-relative">
                <input
                  type="email"
                  placeholder="Enter Email *"
                  formControlName="email"
                  #emailInput
                  [attr.disabled]="disableEmail ? true : null"
                />
                <button
                  type="button"
                  class="btn input-btn"
                  (click)="onEmailInputBlur(emailInput.value)"
                  [ngClass]="{ 'error-active': submitted && regform.controls.email.errors }"
                  [attr.disabled]="disableEmail ? true : null"
                >
                  Verify
                </button>

                <!-- <div class="errorClass" *ngIf="submitted && regform?.controls?.email?.errors">
                  <div *ngIf="regform?.controls?.email?.errors?.required">Email is required</div>
                  <div *ngIf="regform?.controls?.email?.errors?.email">Email must be a valid email address</div>
                </div> -->
                <div class="errorClass" *ngIf="(regform?.controls?.email?.touched || regform?.controls?.email?.dirty || submitted) && regform?.controls?.email?.errors">
                  <div *ngIf="regform?.controls?.email?.errors?.required">Email is required</div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-12">
              <input type="text" placeholder="Mobile Number" formControlName="mobileNumber" maxlength="10"  (input)="onInput($event)"/>

            </div>
            <div class="col-sm-12 col-12">
              <div class="password-wrapper">
                <input
                  [type]="showPassword ? 'text' : 'password'"
                  placeholder="Password *"
                  formControlName="password"
                />
                <i
                  class="fa"
                  [ngClass]="showPassword ? 'fa-eye' : 'fa-eye-slash'"
                  (click)="togglePasswordVisibility()"
                ></i>
              </div>
              <!-- <div class="errorClass" *ngIf="submitted && f.password.errors">
                <div *ngIf="f.password.errors.required">Password is required</div>
                <div *ngIf="f.password.errors.minlength">
                  Password must be at least 6 characters
                </div>
              </div> -->
              <div class="errorClass" *ngIf="(regform?.controls?.password?.touched || regform?.controls?.password?.dirty || submitted) && regform?.controls?.password?.errors">
                <div *ngIf="regform?.controls?.password?.errors?.required">Password is required</div>
              </div>
            </div>


              <div class="col-sm-12 col-12 position-relative">
                <div class="styled-select-container">
                  <input
                    type="text"
                    class="form-control city-input"
                    style="cursor: pointer;"
                    [ngModel]="searchCityQuery"
                    formControlName="cityId"
                    (input)="onSearchInput($event)"
                    (click)="onCityInputClick()"
                    (focus)="onCityInputClick()"
                    (blur)="onBlur()"
                    placeholder="Nearby City *"
                    #cityInput
                  />
                  <!-- <span class="dropdown-icon" (click)="showCitySuggestions = !showCitySuggestions"> -->
                    <i class="fas" (click)="showCitySuggestions = !showCitySuggestions" [ngClass]="{'fa-chevron-down': !showCitySuggestions, 'fa-chevron-up': showCitySuggestions}"></i>
                  <!-- </span> -->


                  <div *ngIf="showCitySuggestions && cityDataList.length > 0" class="suggestion-dropdown list-group">
                    <span *ngFor="let city of cityDataList"
                      class="list-group-item list-group-item-action"
                      (mousedown)="selectCity(city)"
                      [class.selected]="city.id === selectedCityId">
                      {{ city.name }}
                    </span>
                  </div>
                </div>
                <!-- <div class="errorClass" *ngIf="submitted && regform?.controls?.cityId?.errors">
                  <div *ngIf="regform?.controls?.cityId?.errors?.required">City is required</div>
                </div> -->
                <div class="errorClass" *ngIf="(regform?.controls?.cityId?.touched || regform?.controls?.cityId?.dirty || submitted) && regform?.controls?.cityId?.errors">
                  <div *ngIf="regform?.controls?.cityId?.errors?.required">CityId is required</div>
                </div>

              </div>




                <div class="col-sm-12 col-12 position-relative">
                  <div class="styled-select-containe">
                  <input
                    type="text"
                    class="form-control dropdown-input"
                    formControlName="profession"
                    placeholder="Search Profession *"
                    [(ngModel)]="searchText"
                    (keyup)="filterProfessions($event)"
                    (focus)="showDropdown = true"
                    (click)="onProfession()"
                    (blur)="closeDropdown()"
                  />
                  <i class="fas" style="right: 24px !important; top: 36px !important;"
                    [ngClass]="{'fa-chevron-up': showDropdown, 'fa-chevron-down': !showDropdown}"
                    (click)="toggleDropdown()">
                  </i>

                  <!-- Dropdown List -->
                  <ul class="dropdown-list" *ngIf="showDropdown">
                    <li
                      *ngFor="let profession of filteredProfessions"
                      (mousedown)="selectProfession(profession)"
                    >
                      {{ profession.name }}
                    </li>
                  </ul>
                </div>
                <!-- <div class="errorClass" *ngIf="submitted && regform?.controls?.profession?.errors">
                  <div *ngIf="regform?.controls?.profession?.errors?.required">Profession is required</div>
                </div> -->
                <div class="errorClass" *ngIf="(regform?.controls?.profession?.touched || regform?.controls?.profession?.dirty || submitted) && regform?.controls?.profession?.errors">
                  <div *ngIf="regform?.controls?.profession?.errors?.required">Profession is required</div>
                </div>


            </div>
            <div class="col-sm-12 col-12 position-relative">
              <div class="styled-select-containe">
                <input
                  type="text"
                  class="form-control dropdown-input"
                  formControlName="experties"
                  placeholder="Select Expertise"
                  [ngModel]="displaySelectedExperties()"
                  (focus)="showDropdownexperties = false"
                  (click)="toggleDropdownExperties()"
                  readonly
                  (blur)="closeDropdownExperties()"
                />
                <i
                class="fas" style="right: 24px !important; top: 35px !important;"
                  [ngClass]="{'fa-chevron-up': showDropdownexperties, 'fa-chevron-down': !showDropdownexperties}"
                  [ngStyle]="errorMessage ? {'right': '24px', 'top': '35px', 'position': 'absolute'} : {'right': '24px'}"
                  (click)="toggleDropdownExperties()" >
                </i>

                <ul class="dropdown-list" *ngIf="showDropdownexperties && filteredexperties?.length">
                  <li
                    *ngFor="let expertise of filteredexperties"
                    [class.selected]="selectedexpertiesIds.includes(expertise.id)"
                    (mousedown)="selectExperties(expertise)"
                  >
                    {{ expertise.name }}
                  </li>
                </ul>
              </div>
              <!-- <p *ngIf="errorMessage" class="errorClass">{{ errorMessage }}</p> -->
              <!-- <p *ngIf="errorMessage && !(regform?.controls?.profession?.errors)" class="errorClass">
                {{ errorMessage }}
              </p> -->
              <p *ngIf="(errorMessage === 'Please select a profession first.' && (regform?.controls?.profession?.errors || !selectedProfessionId)) ||
              (errorMessage !== 'Please select a profession first.' && !(regform?.controls?.profession?.errors))" class="errorClass">
              {{ errorMessage }}
              </p>

            </div>



          </div><br><br>
          <button class="signinn">Sign Up</button>
          <div class="mobile-only" id="mobileSignIn" (click)="signInSwap()">Sign In</div>
        </form>

        <form [formGroup]="otpform" (ngSubmit)="onSubmitOtp()" class="account otp{{otpactive}}">
          <h3>Verify OTP</h3>
          <span>Please check your email, we have sent you a 6 digits code.</span>
          <input type="text" placeholder="Enter OTP" formControlName="otp" />
          <div class="errorClass" *ngIf="submittedf && g.otp.errors">
            <div *ngIf="g.otp.errors.required">OTP is required</div>
            <div *ngIf="g.otp.errors.minlength">OTP must be at least 6 characters</div>
            <div>{{this.otpvalid}}</div>
          </div>
          <button type="submit" class="signinn">Verify OTP</button>
        </form>
      <!-- </div> -->
    </div>

    <div class="form-contain sign-in-contain">
      <form [formGroup]="loginform" (ngSubmit)="onLoginSubmit()" class="account login{{aciveforgot}}">
        <h2 class="welcome-back">Welcome Back</h2>
        <input type="text" placeholder="Enter Username or Email" formControlName="emailusernam" />
        <!-- <div class="errorClass" *ngIf="submitted1 && h?.emailusernam?.errors">
          <div *ngIf="h?.emailusernam?.errors?.required">Username or Email is required</div>
        </div> -->
        <div class="errorClass" *ngIf="(h?.emailusernam?.touched || h?.emailusernam?.dirty || submitted1) && h?.emailusernam?.errors">
          <div *ngIf="h?.emailusernam?.errors?.required">Username or Email is required</div>
        </div>

          <div class="password-wrapper">
            <input
              [type]="showPasswordlogin ? 'text' : 'password'"
              placeholder="Password"
              formControlName="loginpassword"
            />
            <i
              class="fa"
              [ngClass]="showPasswordlogin ? 'fa-eye' : 'fa-eye-slash'"
              (click)="togglePasswordVisibilitylogin()"
            ></i>
          </div>
          <!-- <div class="errorClass" *ngIf="submitted1 && h.loginpassword.errors">
            <div *ngIf="h.loginpassword.errors.required">Password is required</div>
            <div *ngIf="h.loginpassword.errors.minlength">
              Password must be at least 6 characters
            </div>
          </div> -->
          <div class="errorClass" *ngIf="(h?.loginpassword?.touched || h?.loginpassword?.dirty || submitted1) && h?.loginpassword?.errors">
            <div *ngIf="h?.loginpassword?.errors?.required">Password is required</div>
            <div *ngIf="h?.loginpassword?.errors?.minlength">
              Password must be at least 6 characters
            </div>
          </div>





        <button type="submit" class="signinn  mb-2 bsk" style="margin-top: 20px;">Sign In</button>
        <!-- <a href="javascript:;" (click)="forgotSwitch()" style="color: #004aad; margin-left: 7pc; margin-top: 13px;">Forgot your password?</a> -->
        <div class="row p-3 d-flex justify-content-end">
        <a href="javascript:;" (click)="forgotSwitch()" class="d-flex justify-content-end forgot ">Forgot password?</a>
      </div>
      <div class="mobile-only" id="mobileSignUp" (click)="signUpSwap()">Sign Up</div>
      </form>
      <form [formGroup]="forgotform" (ngSubmit)="onForgotSubmit()" class="account fotgot{{aciveforgot}}">
        <h3 class="txt">Forgot Password</h3>
        <!-- <div class="social-contain">
          <a href="javascript:;" (click)="signInWithFB()" class="social"><i class="fa fa-facebook"
              aria-hidden="true"></i></a>
          <a href="javascript:;" (click)="signInWithGoogle()" class="social"><i class="fa fa-google"
              aria-hidden="true"></i></a>
        </div> -->
        <!-- <span>or use your account</span> -->
        <input type="email" placeholder="Email" formControlName="forgotemail" />
        <!-- <div class="errorClass" *ngIf="submittedo && k.forgotemail.errors">
          <div *ngIf="k.forgotemail.errors.required">Email is required</div>
          <div *ngIf="k.forgotemail.errors.email">Email must be a valid email address</div>
        </div> -->
        <div class="errorClass" *ngIf="(k?.forgotemail?.touched || k?.forgotemail?.dirty || submittedo) && k?.forgotemail?.errors">
          <div *ngIf="k?.forgotemail?.errors?.required">Email is required</div>
          <div *ngIf="k?.forgotemail?.errors?.email">Email must be a valid email address</div>
        </div>

        <button type="submit" class="signinn mb-2"  style="margin-top: 10px;">Confirm Email</button>
        <a href="javascript:;" (click)="loginSwitch()" class="forgot-password-link1" style="margin-top: 20px; text-align: end; color: #004aad;">Sign In</a>
      </form>

    </div>
    <div class="overlay-contain">
      <div class="overlay">
        <div class="overlay-panel overlay-left">
          <h3 style="color: white;">Welcome Back!</h3>
          <p>To keep connected with us please login with your account</p>
          <button class="ghost" id="signIn" (click)="signInSwap()">Sign In</button>
        </div>
        <div class="overlay-panel overlay-right">
          <h1>Hello, Friend!</h1>
          <p>Enter your details and start your journey with us</p>
          <button class="ghost" id="signUp" (click)="signUpSwap()">Sign Up</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading" class="loader"></div>
