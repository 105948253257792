<div  class="header-nav-wrap"  [ngClass]="{'container': layoutType === 'horizontal'}">
    <ng-container *ngTemplateOutlet="layoutType === 'vertical' ? vertical : horizontal"></ng-container>

</div>
<!-- <alert *ngIf="message"  [type]="type"  style="margin-bottom: -1rem; margin-top: 46px; position: absolute; width: 100%; z-index: 999999;"dismissible="true">{{message}}</alert> -->



<ng-template #vertical>
    <div class="header-nav-left">
        <nav-toggle [isMobile]="isMobile"></nav-toggle>
    </div>
    <ng-container *ngTemplateOutlet="headerNavRight"></ng-container>
</ng-template>

<ng-template #horizontal>
    <div class="header-nav-left">
        <nav-toggle *ngIf="isMobile" [isMobile]="isMobile"></nav-toggle>
        <logo *ngIf="!isMobile" [white]= "logoColor !== 'dark'"></logo>
    </div>
    <ng-container *ngTemplateOutlet="headerNavRight"></ng-container>
</ng-template>

<ng-template #headerNavRight>
    <div class="header-nav-right mt-1">
        <img src="../../../../assets/images/logo/cropped-ksquare.png" style="height: 30px; position: absolute; right: 205px; width: 5%;" alt="" class="mt-1">
    </div>
    <div class="header-nav-right">
        <!-- <nav-config></nav-config> -->
        <nav-profile></nav-profile>
    </div>
</ng-template>
