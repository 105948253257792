<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Animated Login Page </title>
    <link rel="icon" href="logo.png" type="x-icon">
</head>

<body>
     <section>
    <div class="login-box">
        <form action="">
            <!-- <h2>Login</h2> -->
            <img src="http://15.206.61.218/assets/images/logo/logo.png" alt="JSLPS image" height="50" style="border-radius: 6px; margin-bottom: 1rem;">
            <div class="input-box">
                <span class="icon">
                    <!-- <ion-icon name="mail"></ion-icon> -->
                    <i class="feather icon-user"></i>
                </span>
                <input type="email" >
                <label>Username <span>*</span></label>
            </div>

            <div class="input-box">
                <span class="icon">
                    <!-- <ion-icon name="lock-closed"></ion-icon> -->
                    <i class="feather icon-lock"></i>
                </span>
                <input type="password" >
                <label>Password <span>*</span></label>
            </div>

            <div class="remember-forgot">
                <label></label>
                <a href="#">Forgot Password</a>
            </div>

            <button type="submit">Login</button>
            <div class="register-link">
                <!-- <p>Don't have an account? <a href="#">Register</a></p> -->
            </div>



        </form>
    </div>
    </section>
    

    <script type="module" src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js"></script>
    <script nomodule src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js"></script>
</body>

</html>