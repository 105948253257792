import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from '../_services/profile.service';
import { ErrorMessageService } from '../services/error-message.service';
import Swal from 'sweetalert2';
import { environmentbasic } from '../../environments/environment-basic';
import { MatSelectChange } from '@angular/material/select';
import { HomeService } from '../_services/home.service';

@Component({
  selector: 'app-profileupdate',
  templateUrl: './profileupdate.component.html',
  styleUrls: ['./profileupdate.component.scss']
})
export class ProfileUpdateComponent implements OnInit {
  profilePic: string = '../assets/images/profile.png';
  updateform: FormGroup;
  submitted = false;
  isreadonly: boolean = false;
  profileid: any;
  emailverified:any
  professions: any[] = [];
  expertises: any[] = [];
  selectedProfessionId: number | null = null;
  isProfessionDropdownOpen = false;
  isExpertiseDropdownOpen = false;

  constructor(
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private errorMessageService: ErrorMessageService,
    private homeService: HomeService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {

    const profileData = localStorage.getItem('UserProfile');
    const parsedProfile = profileData ? JSON.parse(profileData) : null;
    this.profileid = parsedProfile?.payload?.payload?.id ?? parsedProfile?.payload?.id;
    this.initializeForm();
    this.getProfileById(this.profileid);
    this.fetchAllProfession();
  }

  initializeForm() {
    this.updateform = this.formBuilder.group({
      fullName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      mobileNumber: [''],
      profession: ['', [Validators.required]], // Store profession ID
      experties: [[]], // Store multiple expertise IDs
      deleteAccount: [0],
      accountEnabled: [1]
    });
  }

  get f() {
    return this.updateform.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.updateform.invalid) {
      return;
    }
    // const payload = this.updateform.value;
    const payload = { ...this.updateform.value, email: this.emailverified };
    console.log('Submitting payload:', payload);
    localStorage.setItem('profileData', JSON.stringify(payload));
    this.updateprofile(payload);
  }

  getProfileById(profileid: number) {
    this.profileService.getProfileById(profileid).subscribe(
      (response) => {
        if (response['status'] === 'SUCCESS') {
          const parsedProfileData = response['payload'];
          this.selectedProfessionId = parsedProfileData.professions?.id ?? null;
          this.updateform.controls['email'].disable();
          this.emailverified= parsedProfileData.email ?? '',
          this.updateform.patchValue({
            fullName: parsedProfileData.fullName ?? '',
            email: parsedProfileData.email ?? '',
            mobileNumber: parsedProfileData.mobileNumber ?? '',
            profession: this.selectedProfessionId,
            experties: parsedProfileData.expertise?.map((exp: any) => exp.id) ?? [],
            deleteAccount: parsedProfileData.deleteAccount ?? 0,
            accountEnabled: parsedProfileData.accountEnabled ?? 1
          });

          if (this.selectedProfessionId) {
            this.fetchAllExperties(this.selectedProfessionId);
          }
        }
      },
      (error) => {
        console.error('Error fetching profile:', error);
      }
    );
  }

  updateprofile(payload: any) {
    this.profileService.updateprofile(payload).subscribe(
      (response) => {
        if (response['status'] === 'SUCCESS') {
          console.log('Profile updated successfully!');
          const updatedProfileData = {
            ...JSON.parse(localStorage.getItem('auth-user') || '{}'),
            ...payload
          };
          localStorage.setItem('auth-user', JSON.stringify(updatedProfileData));

          Swal.fire({
            icon: 'success',
            title: 'Profile updated successfully!',
            showConfirmButton: false,
            timer: environmentbasic.timer
          });
        } else if (response['message']) {
          const errorMessage = this.errorMessageService.getErrorMessage(response['message']);
          Swal.fire({
            icon: 'error',
            title: errorMessage,
            showConfirmButton: false,
            timer: environmentbasic.timer
          });
        }
      },
      (error) => {
        console.error('Error updating profile:', error);
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while updating the profile.',
          text: error.message || 'Please try again later.',
          showConfirmButton: false,
          timer: environmentbasic.timer
        });
      }
    );
  }

  onProfessionDropdownOpen() {
    this.isProfessionDropdownOpen = true;
    if (this.professions.length === 0) {
      this.fetchAllProfession();
    }
  }

  onExpertiseDropdownOpen() {
    this.isExpertiseDropdownOpen = true;
    if (this.selectedProfessionId && this.expertises.length === 0) {
      this.fetchAllExperties(this.selectedProfessionId);
    }
  }

  fetchAllProfession() {
    this.homeService.fetchAllProfession().subscribe(
      (response: any) => {
        if (response && response.payload) {
          this.professions = response.payload;
        } else {
          this.professions = [];
        }
      },
      (error) => {
        console.error('Error fetching professions:', error);
        this.professions = [];
      }
    );
  }

  fetchAllExperties(professionId: number) {
    this.homeService.fetchidExperties(professionId).subscribe(
      (response: any) => {
        if (response && response.payload) {
          this.expertises = response.payload;
          this.cd.detectChanges();
        } else {
          this.expertises = [];
        }
        this.cd.detectChanges();
      },
      (error) => {
        console.error('Error fetching expertise:', error);
        this.expertises = [];
      }
    );
  }
  // <!-- experties: [[]],
  // profession: ['', Validators.required], -->
  onProfessionSelect(event: MatSelectChange) {
    this.selectedProfessionId = event.value;
    this.updateform.patchValue({ profession: this.selectedProfessionId });

    this.expertises = [];
    this.updateform.patchValue({ experties: [] });

    this.fetchAllExperties(this.selectedProfessionId);
  }
  onInput(event: Event): void {
		const input = event.target as HTMLInputElement;
		input.value = input.value.replace(/[^0-9]/g, ''); // Remove any non-digit characters
		if (input.value.length > 10) {
			input.value = input.value.slice(0, 10); // Limit to 10 digits
		}
	}
}
