<div class="page-container">
  <app-header></app-header>
  <app-menu></app-menu><br>
  <div class="container-fluid d-flex flex-column align-items-center " style="margin-top: 3pc;">
    <div class="avatar-banner">
      <img alt="Profile Picture" src="{{profilePic}}" class="profile-pic" />
    </div>
    <div class="contact-form-wrapper mt-5">
      <div class="contact-form">
        <h3>Your Contact Details</h3>
        <form [formGroup]="updateform" (ngSubmit)="onSubmit()">
          <h2 class="createaccount">Update Your Profile</h2>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Full Name</mat-label>
              <input matInput type="text" formControlName="fullName"
                     (blur)="updateform.controls['fullName'].markAsTouched()"
                     (input)="updateform.controls['fullName'].markAsDirty()"/>
              <mat-error *ngIf="(f.fullName.touched || f.fullName.dirty) && f.fullName.errors">
                <span *ngIf="f.fullName.errors.required">Full Name is required</span>
                <span *ngIf="f.fullName.errors.minlength">Full Name must be at least 8 characters</span>
                <span *ngIf="f.fullName.errors.pattern">Only letters are allowed</span>
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Email</mat-label>
              <input matInput type="email" formControlName="email"  />
              <mat-error *ngIf="submitted && f.email.errors">
                <span *ngIf="f.email.errors.required">Email is required</span>
                <span *ngIf="f.email.errors.email">Email must be a valid email address</span>
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Mobile Number</mat-label>
              <input matInput type="text" formControlName="mobileNumber" maxlength="10" (input)="onInput($event)" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Profession</mat-label>
              <mat-select formControlName="profession" (selectionChange)="onProfessionSelect($event)">
                <mat-option *ngFor="let profession of professions" [value]="profession.id">
                  {{ profession.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="(f.profession.touched || f.profession.dirty) && f.profession.errors?.required">
                Profession is required
              </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Expertise</mat-label>
              <mat-select formControlName="experties" multiple (click)="onExpertiseDropdownOpen()">
                <mat-option *ngFor="let expertise of expertises" [value]="expertise.id">
                  {{ expertise.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          <div class="button-group">
            <button mat-raised-button color="primary" type="submit">Update & Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
