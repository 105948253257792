import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CONSTANTS_TEXT } from '@app/layout/const/app.constant';
import { CommonService } from '@app/layout/service/common.service';
import { LocalstorageService } from '@app/layout/service/localstorage.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-candidate-tracker-action',
  templateUrl: './candidate-tracker-action.component.html',
  styleUrls: ['./candidate-tracker-action.component.css']
})
export class CandidateTrackerActionComponent implements OnInit {

  params:any;
  isDropup = true;
  bsModalRef: BsModalRef;
  constructor(private modalService: BsModalService,
    private router:Router,
    private _LocalstorageService:LocalstorageService,
    private _commanServcie:CommonService) { }

  ngOnInit(): void {
  }

  agInit(params: any): void {
    this.params = params.data;
  }

  candidateAdd(){
    const initialState = {
      data:this.params.jcId
    };
    this.router.navigate(['candidate/create'], {queryParams:{requirementId:this.params.jcId}});
    // this.bsModalRef = this.modalService.show(CandidateListComponent, { class: 'modal-lg modal-dialog-centered', initialState });
  }

  feedback(){
    const initialState = {
      data:this.params.jcId
    };
    // this.bsModalRef = this.modalService.show(RequirementAssignToComponent, { class: 'modal-md modal-dialog-centered', initialState });
  }

  Edit(){
    this.router.navigate(['requirement/create'], {queryParams:{data:JSON.stringify({id:this.params?.jcId,index:1})}});
  }

  changedstatus(status:string){
    this._commanServcie.requirementStatusChanged(this.params?.jcId,status).subscribe((response:any)=>{
      if(response.status === CONSTANTS_TEXT.status){
        let value1 = status.charAt(0).toUpperCase()+status.slice(1);
        this._LocalstorageService.showMessage(CONSTANTS_TEXT.success, `${this.params?.jcId} has been ${value1}`);
      }else{
        this._LocalstorageService.showmessage(CONSTANTS_TEXT.error, response.message)
    }
    });
  }

}
