export const pagePaths = {
    Empty: '',
    redirect:'/',
    full: 'full',
    auth:'auth',
    forgot:'forgot',
    otp:'otp',
    login:'Login',
    main:'main',
    dashboard:'dashboard',
    employee:'employee',
    Client:'client',
    employeeCreate:'employeeCreate',
    create:'create',

    closure:'closure',
    
    role:'role',
    editRole:'edit/:id',
    roleCreate:'create',

    editEmployee:'edit/:id',
    editDetail:'edit/:id',
    edit:'edit/',
    master:'master',
    country:'country',
    countryCreate:'country/create',
    countryEdit:'country/edit/:id',
    state:'state',
    stateEdit:'state/edit/:id',
    stateCreate:'state/create',
    city:'city',

    cityEdit:'city/edit/:id',
    cityCreate:'city/create',

    industry:'industry',
    industryEdit:'industry/edit/:id',
    createindustry:'industry-create',

    qualification:'qualification',
    editqualification:'qualification/edit/:id',
    createqualification:'qualification/create',


    institute:'institute',
    editinstitute:'institute/edit/:id',
    createinstitute:'institute/create',

    certification:'certification',
    editcertification:'certification/edit/:id',
    createcertification:'certification/create',

    department:'department',
    editdepartment:'department/edit/:id',
    createdepartment:'department/create',

    designation:'designation',
    editdesignation:'designation/edit/:id',
    createdesignation:'designation/create', 
   

    candidaterole:'candidaterole',
    editcandidaterole:'candidaterole/edit/:id',
    createcandidaterole:'candidaterole/create', 

    location:'location',
    editlocation:'location/edit/:id',
    createlocation:'location/create', 

    changePassword:`changePassword`,
    profile:'profile',

    user:'user',
    view:'view',


    interview:'interview',
    editInterview:'interview/edit/:id',
    createInterview:'interview/create',

    education:'education',
    editeducation:'education/edit/:id',
    createeducation:'education/create',

    company:'company',
    editcompany:'company/edit/:id',
    createcompany:'company/create',

    expLevel:'experience-level',
    editexpLevel:'experience-level/edit/:id',
    createexpLevel:'experience-level/create',

    funcArea:'functional-area',
    editfuncArea:'functional-area/edit/:id',
    createfuncArea:'functional-area/create',

    assignment:'assignment',
    editAssignment:'assignment/edit/:id',
    createAssignment:'assignment/create',

    feedback:'feedback',
    editFeedback:'feedback/edit/:id',
    createFeedback:'feedback/create',

    reasonToChange:'reasonToChange',
    editReasonToChange:'reasonToChange/edit/:id',
    createReasonToChange:'reasonToChange/create',

    offerDesignation:'offerdesignation',
    editofferDesignation:'offerdesignation/edit/:id',
    createofferDesignation:'offerdesignation/create',

    airport:'airport',
    supplier:'supplier',
    terminal:'terminal',
    businessCategory:'businessCategory',
    productCategory:'productCategory',
    productCategoryCreate:'product-category-create',
    businessCreate:'business-category-create',
    businessAdd:'business-category-add',
    create_country:'create-country',
    create_state:'create-state',
    create_supplier:'create_supplier',
    create_airport:'create-airport',
    create_city:'create-city',
    create_terminal:'create-terminal',
    listing:'listing',
    services_management:'services-management',
    shop_management:'shop-management',
    product_management:'product-management',
    order_management:'order-management',
    supplier_management:'supplier_management',

    update:'update',
    Requirement:'requirement',
    candidate:'candidate',
    createCandidate : 'create',
    editCandidate:'edit/:id',
    candidateList:'list',
    editCandidateData:'candidate/edit',
    candidateTracker:'tracker',
    saveSearch: 'savesearch',

    candidateMailForm:'candidate-mail-form/:id',
    candidateMailEmptyForm:'candidate-mail-form',
    login1:'login1',
    login2:'login2',
    login3:'login3',
    privacypolicy:'privacypolicy'
}
export const RoutePath = {
    ...pagePaths,
}
