import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { AppConfigState } from './store/app-config/app-config.state';
import { TranslateModule } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { AnimationDriver } from '@angular/animations/browser'
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { HashLocationStrategy, registerLocaleData } from '@angular/common';
import { SwitchModule } from '@app/shared/components/switch/switch.module';
import { RadioModule } from '@app/shared/components/radio/radio.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AlertModule } from 'ngx-bootstrap/alert';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgOtpInputModule } from  'ng-otp-input';
import 'ag-grid-enterprise';
import { NgxEditorModule } from 'ngx-editor';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
// import mockServer from './mock-data/app.mock';

// mockServer();

const cookieConfig:NgcCookieConsentConfig = {
    cookie: {
        domain: "https://theglove.co.in"
      },
      position: "bottom",
      theme: "classic",
      palette: {
        popup: {
          // background: "#e9e9e9",
          text: "#000000",
          // link: "#004aad"
        },
        button: {
          // background: "#4599bb",
          text: "#000000",
          // border: "transparent"
        }
      },
      type: "opt-in",
      content: {
        message: "This website uses cookies to ensure you get the best experience on our website.",
        dismiss: "Got it!",
        deny: "Refuse cookies",
        link: "Learn more",
        href: "https://theglove.co.in",
        policy: "Cookie Policy"
      }
  }

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        TranslateModule.forRoot(),
        LayoutModule,
        NgxsModule.forRoot([
            AppConfigState
        ]),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        NgxsLoggerPluginModule.forRoot(),
        ReactiveFormsModule,
        FormsModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-full-width',
          }),
        NgBootstrapFormValidationModule.forRoot(),
        AgGridModule,
        SwitchModule,
        RadioModule,
        BsDropdownModule.forRoot(),
        AlertModule.forRoot(),
        NgSelectModule,
        BsDatepickerModule.forRoot(),
        NgOtpInputModule,
        CarouselModule.forRoot(),
        NgxEditorModule,
        NgcCookieConsentModule.forRoot(cookieConfig),
    ],
    providers: [
        {
            provide: LocationStrategy, 
            useClass: PathLocationStrategy
        },
        { provide: LocationStrategy, useClass: HashLocationStrategy }
    ],
    bootstrap: [AppComponent],
    
})
export class AppModule { }
