import { Injectable } from '@angular/core';
import { ApiURL } from './api';
import { HttpService } from './http.service';
import { addbusinessCategory, adminRoleResponse, cityId, counrty, createCountry, createState, createproductCategory, createEmployee, shopcreate, stateID, terminalId, terminalgetId, userdata } from '../shared/typings/app.typings';
import { getApiParamset } from '../shared/function/function';
import { BehaviorSubject, Observable, concatMap, map, pipe, tap } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private closeModelRequirment = new BehaviorSubject<boolean>(false);
  public data$ = this.closeModelRequirment.asObservable();

  refreshRequirement$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  refreshcoutryStatus$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  refreshcompanyStatus$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  refreshstateStatus$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  refreshcityStatus$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  refreshindustryStatus$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  refreshqualificationStatus$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  refreshinstituteStatus$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  refreshcertificationStatus$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  refreshinterviewScheduleStatus$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  refreshdepartmentStatus$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  refreshinterviewStatus$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  refreshlocationStatus$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  refreshdesignationStatus$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  refreshcroleStatus$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  refresheducationStatus$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  refreshexperinceStatus$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  refreshfunareaStatus$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  refreshfeedbackStatus$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  refreshreasontochangeStatus$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  refreshgloveroleStatus$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  refreshclientStatus$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  refreshemployeeStatus$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  refreshFilterSearch$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  refreshAssignInReq$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  refreshofferDesignationStatus$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  constructor(
    private httpservice: HttpService,
    private httpClient: HttpClient,
    private httpClients: HttpClient
  ) {}

  modelClose(model: boolean) {
    this.closeModelRequirment.next(model);
  }

  forgetPassword(payload: any) {
    return this.httpservice.postAll(ApiURL.forget, payload);
  }
  // getmyprofile(){
  //   // return this.httpservice.getAll(ApiURL.fetchEmployeeList, null);
  //   return this.httpservice.getAll(ApiURL.getuserredirecturl, null);
  // }
  getProfileData() {
    // return this.httpservice.getAll(
    //   ApiURL.getProfileData + '?username=' + username,
    //   null
    // );
    return this.httpservice.getAll(ApiURL.getProfileData, null);
  }
  getEmployeeList() {
    return this.httpservice.getAll(ApiURL.fetchEmployeeList, null);
  }
  getAllrolemaster() {
    return this.httpservice.getAll(ApiURL.roleMaster, null);
  }
  // add employee
  createEmploye(payload: createEmployee) {
    return this.httpservice.postAll(ApiURL.createEmploye, payload);
  }
  getdepartmentAll() {
    return this.httpservice.getAll(ApiURL.departmentAll, null);
  }
  getdesignationAll() {
    return this.httpservice.getAll(ApiURL.designationAll, null);
  }
  checkUniqueEmpIdentifier(data) {
    return this.httpservice.getAll(
      ApiURL.checkUniqueEmpIdentifier + '?empId=' + data,
      null
    );
  }
  checkUniqueUsernameIdentifier(payload: any) {
    return this.httpservice.postAll(
      ApiURL.checkUniqueUsernameIdentifier + '?username=' + payload,
      null
    );
  }
  updateEmploye(empId: string, payload: any) {
    return this.httpservice.putApi(
      ApiURL.updateEmploye + '?empId=' + empId,
      payload
    );
  }
  getEmployee(id: any) {
    return this.httpservice.getAll(ApiURL.getEmployeeById + '?id=' + id, null);
  }
  
  getCountry() {
    return this.httpservice.getAll(ApiURL.getCountry, null);
  }
  getCountryById(id) {
    return this.httpservice.getAll(ApiURL.country + "/"+id, null);
  }
  createCounrty(payload) {
    return this.httpservice.postAll(ApiURL.country, payload);
  }
  updateCounrty(payload, id) {
    return this.httpservice.putApi(ApiURL.country + '/' + id, payload);
  }
  countryUniqueness(payload) {
    return this.httpservice.getAll(
      ApiURL.countryUniqueness + '?' + payload,
      null
    );
  }
  getStateList(counrtyId: number) {
    return this.httpservice.getAll(
      ApiURL.stateByCountry + '?countryId=' + counrtyId,
      null
    );
  }
  cityByCountry(counrtyId: number) {
    return this.httpservice.getAll(
      ApiURL.cityByCountry + '?countryId=' + counrtyId,
      null
    );
  }
  cityByState(stateId: number) {
    return this.httpservice.getAll(
      ApiURL.cityByState + '?stateId=' + stateId,
      null
    );
  }
  cityById(id) {
    return this.httpservice.getAll(ApiURL.city + '/' + id, null);
  }
  updateCity(id, payload) {
    return this.httpservice.putApi(ApiURL.city + '/' + id, payload);
  }
  createCity(payload) {
    return this.httpservice.postAll(ApiURL.city, payload);
  }

  updateState(payload, id) {
    return this.httpservice.putApi(ApiURL.state + '/' + id, payload);
  }
  createState(payload) {
    return this.httpservice.postAll(ApiURL.state, payload);
  }
  getStateById(id) {
    return this.httpservice.getAll(ApiURL.state + '/' + id, null);
  }
  stateUniqueness(code) {
    return this.httpservice.getAll(
      ApiURL.stateUniqueness + '?stateCode=' + code,
      null
    );
  }

  getUserName(username: any) {
    return this.httpservice.getAll(ApiURL.getUserData + '?username=' + username, null);
  }
  statusCountry(payload, id) {
    return this.httpservice
      .putApi(ApiURL.statusCountry + '?countryId=' + id + '&status=' + payload)
      .pipe(
        tap(() => {
          return this.refreshcoutryStatus$.next(true);
        })
      );
  }
  statusState(payload, id) {
    return this.httpservice
      .putApi(ApiURL.statusState + '?stateId=' + id + '&status=' + payload)
      .pipe(
        tap(() => {
          return this.refreshstateStatus$.next(true);
        })
      );
  }
  statusCity(payload, id) {
    return this.httpservice
      .putApi(ApiURL.statusCity + '?cityId=' + id + '&status=' + payload)
      .pipe(
        tap(() => {
          return this.refreshcityStatus$.next(true);
        })
      );
  }
  cityUniqueness(code) {
    return this.httpservice.getAll(
      ApiURL.cityUniqueness + '?cityCode=' + code,
      null
    );
  }

  //glove role
  roleUniqueness(data) {
    return this.httpservice.getAll(
      ApiURL.roleUniqueness + '?name=' + data,
      null
    );
  }
  getGloveRole() {
    return this.httpservice.getAll(ApiURL.gloveRoleListing, null);
  }
  // updateRoleById(payload, id) {
  //   return this.httpservice.putApi(
  //     ApiURL.updateRoleById + '/' + id + '?id=' + id,
  //     payload
  //   );
  // }
  statusGloverole(payload, id) {
    return this.httpservice
      .putApi(ApiURL.statusGloverole + '?id=' + id + '&status=' + payload)
      .pipe(
        tap(() => {
          return this.refreshgloveroleStatus$.next(true);
        })
      );
  }
  statusEmployee(payload, id) {
    return this.httpservice
      .putApi(ApiURL.statusEmployee + '?id=' + id + '&status=' + payload)
      .pipe(
        tap(() => {
          return this.refreshemployeeStatus$.next(true);
        })
      );
  }

  //interview
  getInterviewAll() {
    return this.httpservice.getAll(ApiURL.getInterviewAll, null);
  }

  createInterview(payload: any) {
    return this.httpservice.postAll(ApiURL.createInterview, payload);
  }
  getInterviewById(id) {
    return this.httpservice.getAll(ApiURL.interview + '?id=' + id, null);
  }
  updateInterviewById(payload, id) {
    return this.httpservice.putApi(
      ApiURL.updateInterviewById + '?id=' + id,
      payload
    );
  }
  statusInterview(payload, id) {
    return this.httpservice
      .putApi(ApiURL.statusInterview + '?id=' + id + '&status=' + payload)
      .pipe(
        tap(() => {
          return this.refreshinterviewStatus$.next(true);
        })
      );
  }
  interviewUniqueness(data) {
    return this.httpservice.getAll(
      ApiURL.interviewUniqueness + '?name=' + data,
      null
    );
  }

  //institute
  getInstituteAll() {
    return this.httpservice.getAll(ApiURL.getInstituteAll, null);
  }
  getInstituteById(id) {
    return this.httpservice.getAll(ApiURL.getInstituteById + '?id=' + id, null);
  }
  createInstitute(payload) {
    return this.httpservice.postAll(ApiURL.createInstitute, payload);
  }
  updateInstitute(payload, id) {
    return this.httpservice.putApi(
      ApiURL.updateInstitute +'?id=' + id,
      payload
    );
  }
  statusInstitute(payload, id) {
    return this.httpservice
      .putApi(ApiURL.statusInstitute + '?id=' + id + '&status=' + payload)
      .pipe(
        tap(() => {
          return this.refreshinstituteStatus$.next(true);
        })
      );
  }
  instituteUniqueness(data) {
    return this.httpservice.getAll(
      ApiURL.instituteUniqueness + '?name=' + data,
      null
    );
  }

  //qualifications
  getQualificationAll() {
    return this.httpservice.getAll(ApiURL.getQualificationAll, null);
  }
  getQualificationById(id) {
    return this.httpservice.getAll(
      ApiURL.getQualificationById + '?id=' + id,
      null
    );
  }
  createQualification(payload) {
    return this.httpservice.postAll(ApiURL.createQualification, payload);
  }
  updateQualification(payload, id) {
    return this.httpservice.putApi(
      ApiURL.updateQualification + '?id=' + id,
      payload
    );
  }
  statusQualification(payload, id) {
    return this.httpservice
      .putApi(ApiURL.statusQualification + '?id=' + id + '&status=' + payload)
      .pipe(
        tap(() => {
          return this.refreshqualificationStatus$.next(true);
        })
      );
  }
  qualificationUniqueness(data) {
    return this.httpservice.getAll(
      ApiURL.qualificationUniqueness + '?name=' + data,
      null
    );
  }

  //certification
  getCertificationsAll() {
    return this.httpservice.getAll(ApiURL.getCertificationsAll, null);
  }
  getCertificationsById(id) {
    return this.httpservice.getAll(
      ApiURL.getCertificationsById + '?id=' + id,
      null
    );
  }
  createCertifications(payload) {
    return this.httpservice.postAll(ApiURL.createCertifications, payload);
  }
  updateCertifications(payload, id) {
    return this.httpservice.putApi(
      ApiURL.updateCertifications + '?id=' + id,
      payload
    );
  }
  statusCertifications(payload, id) {
    return this.httpservice
      .putApi(ApiURL.statusCertifications + '?id=' + id + '&status=' + payload)
      .pipe(
        tap(() => {
          return this.refreshcertificationStatus$.next(true);
        })
      );
  }
  certificationUniqueness(data) {
    return this.httpservice.getAll(
      ApiURL.certificationUniqueness + '?name=' + data,
      null
    );
  }
  //department
  getDepartmentAll() {
    return this.httpservice.getAll(ApiURL.getDepartmentAll, null);
  }
  getDepartmentById(id) {
    return this.httpservice.getAll(ApiURL.getDepartmentById + '?dptId=' + id, null);
  }
  createDepartment(payload) {
    return this.httpservice.postAll(ApiURL.createDepartment, payload);
  }
  updateDepartment(payload, dptId) {
    return this.httpservice.putApi(
      ApiURL.updateDepartment + '?dptId=' + dptId,
      payload
    );
  }
  statusDepartment(payload, id) {
    return this.httpservice
      .putApi(ApiURL.statusDepartment + '?dptId=' + id + '&status=' + payload)
      .pipe(
        tap(() => {
          return this.refreshdepartmentStatus$.next(true);
        })
      );
  }
  departmentUniqueness(data) {
    return this.httpservice.getAll(
      ApiURL.departmentUniqueness + '?' + data,
      null
    );
  }



  //rolemaster
  getRoleMasterAll() {
    return this.httpservice.getAll(ApiURL.getRoleMasterAll, null);
  }
  getRoleMasterById(id) {
    return this.httpservice.getAll(ApiURL.getRoleMasterById + '?id=' + id, null);
  }
  createRoleMaster(payload) {
    return this.httpservice.postAll(ApiURL.createRoleMaster, payload);
  }
  updateRoleMaster(payload, id) {
    return this.httpservice.putApi(ApiURL.updateRoleMaster , payload);
  }
  statusRoleMaster(payload, id) {
    return this.httpservice
      .putApi(ApiURL.statusRoleMaster + '?id=' + id + '&status=' + payload)
      .pipe(
        tap(() => {
          return this.refreshcroleStatus$.next(true);
        })
      );
  }
  rolemasterUniqueness(data) {
    return this.httpservice.getAll(
      ApiURL.rolemasterUniqueness + '?' + data,
      null
    );
  }

  //industry
  getIndustryAll() {
    return this.httpservice.getAll(ApiURL.getIndustryAll, null);
  }
  getIndustryById(id) {
    return this.httpservice.getAll(ApiURL.getIndustryById + '?id=' + id, null);
  }
  createIndustry(payload) {
    return this.httpservice.postAll(ApiURL.createIndustry, payload);
  }
  updateIndustry(payload, indId) {
    return this.httpservice.putApi(
      ApiURL.updateIndustry + '?id=' + indId,
      payload
    );
  }
  statusIndustry(payload, id) {
    return this.httpservice
      .putApi(ApiURL.statusIndustry + '?id=' + id + '&status=' + payload)
      .pipe(
        tap(() => {
          return this.refreshindustryStatus$.next(true);
        })
      );
  }

  checkUniqueIndIdentifier(data) {
    return this.httpservice.getAll(
      ApiURL.checkUniqueIndIdentifier + '?' + data,
      null
    );
  }

  //client
  getClientAll() {
    return this.httpservice.getAll(ApiURL.getClientAll, null);
  }
  getClientById(id) {
    return this.httpservice.getAll(ApiURL.getClientById + '?id=' + id, null);
  }
  createClient(payload) {
    return this.httpservice.postAll(ApiURL.createClient, payload);
  }
  updateClient(indId, payload) {
    return this.httpservice.putApi(ApiURL.updateClient + '?clId=' + indId, payload);
  }

  createClientScop(payload){
    return this.httpservice.postAll(ApiURL.createClientScop, payload);
  }

  createClientScopUpdate(payload){
    return this.httpservice.putApi(ApiURL.updateClientScop, payload);
  }


  checkUniqueClientIdentifier(data) {
    return this.httpservice.getAll(
      ApiURL.checkUniqueClientIdentifier + '?clientId=' + data,
      null
    );
  }
  statusClient(payload, id) {
    return this.httpservice
      .putApi(ApiURL.statusClient + '?id=' + id + '&status=' + payload)
      .pipe(
        tap(() => {
          return this.refreshclientStatus$.next(true);
        })
      );
  }

  //Profile
  // getProfileData(username: any) {
  //   return this.httpservice.getAll(
  //     ApiURL.getProfileData + '?username=' + username,
  //     null
  //   );
  // }

  gloveroleaccessrights(payload) {
    return this.httpservice.postAll(ApiURL.gloveroleaccessrights, payload);
  }
  getGloverole(id) {
    return this.httpservice.getAll(
      ApiURL.getGloverole + '?gloveRoleId=' + id,
      null
    );
  }

  //Location
  getLocationAll() {
    return this.httpservice.getAll(ApiURL.getLocationAll, null);
  }
  getLocationById(id) {
    return this.httpservice.getAll(ApiURL.getLocationById + '?id=' + id, null);
  }
  createLocation(payload) {
    return this.httpservice.postAll(ApiURL.createLocation, payload);
  }
  updateLocation(payload, locId) {
    return this.httpservice.putApi(
      ApiURL.updateLocation + '?id=' + locId,
      payload
    );
  }
  statusLocation(payload, id) {
    return this.httpservice
      .putApi(ApiURL.statusLocation + '?id=' + id + '&status=' + payload)
      .pipe(
        tap(() => {
          return this.refreshlocationStatus$.next(true);
        })
      );
  }

  //Candidate
  candidateCreateUpdate(payload) {
    return this.httpservice.postAll(ApiURL.candidateCreateUpdate, payload);
  }
  candidateEducation(payload) {
    return this.httpservice.postAll(ApiURL.candidateEducation, payload);
  }
  candidateExperience(payload) {
    return this.httpservice.postAll(ApiURL.candidateExperience, payload);
  }
  getCandidateByID(id) {
    return this.httpservice.getAll(ApiURL.candidateById + '?ctid=' + id, null);
  }
  getCandidateByMobileAndCountryCode(mobile, counrtyCode) {
    return this.httpservice.getAll(
      ApiURL.candidate + '?mobile=' + mobile + '&countryCode' + counrtyCode,
      null
    );
  }
  candidateList(payload) {
    return this.httpservice.postAll(ApiURL.candidateList, payload);
  }
  getCandidateByKey(id) {
    return this.httpservice.getAll(ApiURL.candidateByKey + '?key=' + id, null);
  }
  candidateAll(payload) {
    return this.httpservice.postAll(ApiURL.candidateAll, payload);
  }
  getCandidateWithRequirement(email,jobId) {
    return this.httpservice.getAll(ApiURL.getCandidateWithRequirement+'?email='+email+'&jobId='+jobId);
  }

  //Company
  getCompanyAll() {
    return this.httpservice.getAll(ApiURL.getCompanyAll, null);
  }
  getCompanyList(payload) {
    return this.httpservice.postAll(ApiURL.getCompanyAll, payload);
  }
  getCompanyById(id) {
    return this.httpservice.getAll(ApiURL.getCompanyById + '?id=' + id, null);
  }
  createCompany(payload) {
    return this.httpservice.postAll(ApiURL.createCompany, payload);
  }
  updateCompany(payload, cmpid) {
    return this.httpservice.putApi(
      ApiURL.updateCompany + '?id=' + cmpid,
      payload
    );
  }
  statusCompany(payload, id) {
    return this.httpservice
      .putApi(ApiURL.statusCompany + '?id=' + id + '&status=' + payload)
      .pipe(
        tap(() => {
          return this.refreshcompanyStatus$.next(true);
        })
      );
  }
  companyUniqueness(data) {
    return this.httpservice.getAll(
      ApiURL.companyUniqueness + '?name=' + data,
      null
    );
  }

  //Education
  getEducationAll() {
    return this.httpservice.getAll(ApiURL.getEducationAll, null);
  }
  getEducationById(id) {
    return this.httpservice.getAll(ApiURL.getEducationById + '?id=' + id, null);
  }
  createEducation(payload) {
    return this.httpservice.postAll(ApiURL.createEducation, payload);
  }
  updateEducation(payload, eduid) {
    return this.httpservice.putApi(
      ApiURL.updateEducation + '?id=' + eduid,
      payload
    );
  }

  requirementAssignList() {
    return this.httpservice.postAll(ApiURL.requirementAssign);
  }

  requirementAssignanfDeleteandCreate(payload: any) {
    return this.httpservice.postAll(
      ApiURL.requrementAssigndeleteandCreate,
      payload
    );
  }

  candidateAnswerPost(payload: any) {
    return this.httpservice.postAll(ApiURL.candidateResponse, payload);
  }
  candidateEmailForm(payload: any) {
    return this.httpservice.postAll(ApiURL.candidateEmailForm, payload);
  }

  // workflow Feedback Normal and Clousre

  candidateFeedback(payload: any) {
    return this.httpservice.postAll(ApiURL.candidateFeedback, payload);
  }
  feedbackClousre(payload: any) {
    return this.httpservice.postAll(ApiURL.feedbackClousre, payload);
  }
  putFeedback(payload, id) {
    return this.httpservice.putApi(ApiURL.putFeedback + '?id=' + id, payload);
  }

  //Experience
  getExperienceAll() {
    return this.httpservice.getAll(ApiURL.getExperienceAll, null);
  }
  getExperienceById(id) {
    return this.httpservice.getAll(
      ApiURL.getExperienceById + '?id=' + id,
      null
    );
  }
  createExperience(payload) {
    return this.httpservice.postAll(ApiURL.createExperience, payload);
  }
  updateExperience(payload, id) {
    return this.httpservice.putApi(ApiURL.updateExperience + '?id=' + id, payload);
  }
  statusExperience(payload, id) {
    return this.httpservice
      .putApi(ApiURL.statusExperience + '?id=' + id + '&status=' + payload)
      .pipe(
        tap(() => {
          return this.refreshexperinceStatus$.next(true);
        })
      );
  }
  experienceLevelUniqueness(data) {
    return this.httpservice.getAll(
      ApiURL.experienceLevelUniqueness + '?name=' + data,
      null
    );
  }

  //Functional Area
  getFunctionalAll() {
    return this.httpservice.getAll(ApiURL.getFunctionalAll, null);
  }
  getFunctionalById(id) {
    return this.httpservice.getAll(ApiURL.getFunctionalById + '?id=' + id, null);
  }
  createFunctional(payload) {
    return this.httpservice.postAll(ApiURL.createFunctional, payload);
  }
  updateFunctional(payload, faId) {
    return this.httpservice.putApi(
      ApiURL.updateFunctional + '?id=' + faId,
      payload
    );
  }
  statusFunctional(payload, id) {
    return this.httpservice
      .putApi(ApiURL.statusFunctional + '?id=' + id + '&status=' + payload)
      .pipe(
        tap(() => {
          return this.refreshfunareaStatus$.next(true);
        })
      );
  }
  functionalUniqueness(data) {
    return this.httpservice.getAll(
      ApiURL.functionalUniqueness + '?name=' + data,
      null
    );
  }

  //Feedback
  getFeedbackAll() {
    return this.httpservice.getAll(ApiURL.getFeedbackAll, null);
  }
  getFeedbackById(id) {
    return this.httpservice.getAll(ApiURL.getFeedbackById + '?id=' + id, null);
  }
  createFeedback(payload) {
    return this.httpservice.postAll(ApiURL.createFeedback, payload);
  }
  updateFeedback(payload, id) {
    return this.httpservice.putApi(ApiURL.updateFeedback + '?id=' + id, payload);
  }
  statusFeedback(payload, id) {
    return this.httpservice
      .putApi(ApiURL.statusFeedback + '?id=' + id + '&status=' + payload)
      .pipe(
        tap(() => {
          return this.refreshfeedbackStatus$.next(true);
        })
      );
  }

  //Reason To Change
  getReasonAll() {
    return this.httpservice.getAll(ApiURL.getReasonAll, null);
  }
  getReasonById(id) {
    return this.httpservice.getAll(ApiURL.getReasonById + '?id=' + id, null);
  }
  createReason(payload) {
    return this.httpservice.postAll(ApiURL.createReason, payload);
  }
  updateReason(payload, id) {
    return this.httpservice.putApi(ApiURL.updateReason + '?id=' + id, payload);
  }
  statusReason(payload, id) {
    return this.httpservice
      .putApi(ApiURL.statusReason + '?id=' + id + '&status=' + payload)
      .pipe(
        tap(() => {
          return this.refreshreasontochangeStatus$.next(true);
        })
      );
  }

  // getrequirementId(){
  //   return this.httpservice.getAll(ApiURL.getRquiementId)
  // }
  // getrequirementUniqeId(){
  //   return this.httpservice.getAll(ApiURL.getRquiementUniqueId)
  // }

  getallClientMaster() {
    return this.httpservice.getAll(ApiURL.getallClientMaster);
  }
  getallGloveSpocMaster(name){
    return this.httpservice.getAll(ApiURL.getallSpocmMaster+name);

  }

  postrequirement(payload: any) {
    return this.httpservice.postAll(ApiURL.postrequirementManagement, payload);
  }

  putrequirement(payload: any, id: string) {
    return this.httpservice.putApi(
      ApiURL.postrequirementManagement+'?id='+id,
      payload
    );
  }

  postrequirementEvaluation(payload: any) {
    return this.httpservice.postAll(ApiURL.postrequirementEvaluation, payload);
  }

  deleterequirementEvaluation(id: number) {
    console.log(id);
    return this.httpservice.deleteTwo(ApiURL.deleterequirementEvaluation,id);
}
  deleterequirementEvaluationotion(id: number) {
    return this.httpservice.deleteTwo(
      ApiURL.deleterequirementEvaluationotion,id);
  }

  deleteAllrequirementEvaluation(id: number) {
    return this.httpservice.deleteTwo(
      ApiURL.deleteAllRequirementEvaluationQuestion,id);
  }

  putrequirementEvaluation(payload: any) {
    return this.httpservice.postAll(ApiURL.postrequirementEvaluation, payload);
  }

  postrequirementAssign(payload: any) {
    return this.httpservice.postAll(ApiURL.postrequirementAssign, payload);
  }
  putrequirementAssign(payload: any) {
    return this.httpservice.postAll(ApiURL.postrequirementAssign, payload);
  }

  getrequirementdata(id: string) {
    return this.httpservice.getAll(
      ApiURL.postrequirementManagement+'?id='+id,
      null
    );
  }
  getrequirementEvaluation(id: string) {
    return this.httpservice.getAll(
      ApiURL.getrequirementEvaluation+'?id='+id,
      null
    );
  }

  checkFormEvaluation(reqrementId: string, ctId: string) {
    return this.httpservice.postAll(
      `${ApiURL.checkevalutionForm}?jcid=${reqrementId}&ctid=${ctId}`,
      null
    );
  }

  getrequirementassign(id: string) {
    return this.httpservice.getAll(
      ApiURL.getrequirementassign+'?jcId='+id,
      null
    );
  }
  getCandidateTracker(payload: any) {
    return this.httpservice.postAll(ApiURL.candidateTracker, payload);
  }
  getCandidateClouser(payload: any) {
    return this.httpservice.postAll(ApiURL.candidateClouser, payload);
  }
  candidateCSVDownload(payload: any) {
    // const httpOptions = {
    //   responseType: 'blob' as 'json',
    // };
    return this.httpservice.postAll(
      ApiURL.candidateCSVDownload, payload
      // ,httpOptions
    );
    // return this.httpservice.postAll(ApiURL.candidateCSVDownload, payload);
  }
  closureCSVDownload(payload: any) {
    // const httpOptions = {
    //   responseType: 'blob' as 'json',
    // };
    return this.httpservice.postAll(
      ApiURL.closureCSVDownload, payload
      // ,httpOptions
    );
    // return this.httpservice.postAll(ApiURL.candidateCSVDownload, payload);
  }

  getCandidateSendOtp(email: any) {
    return this.httpservice.getAll(ApiURL.candidateSendOtp, email);
  }
  getcandidateVeifyOtp(email: string, otp: string) {
    return this.httpservice.postAll(
      ApiURL.candidateVeifyOtp + '?email=' + email + '&otp=' + otp
    );
  }

  evaluationFormDownload(id: any) {
    // const httpOptions = {
    //   responseType: 'blob' as 'json',
    // };
    return this.httpClients.get(
      ApiURL.evaluationFormDownload + id,
    );
  }
  access_token() {
    return localStorage.getItem('access_token');
  }
  
  // file upload function
  getMediaMetaData(purpose: string, contentType: string, file: any, name: any, phoneNumber: any, type: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Basic ' + this.access_token(),
      }),
    };
  
    console.log(name);
  
    if (type != "") {
      const parts = name.split('.');
      const extension = parts.pop();
      const baseFilename = parts.join('.');
  
      function generateRandomString(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let randomString = '';
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          randomString += characters.charAt(randomIndex);
        }
        return randomString;
      }
  
      const randomString = generateRandomString(8);
      console.log(randomString);
  
      var finalFileName = baseFilename + "_" + phoneNumber + "" + contentType;
      name = finalFileName;
      console.log(name);
    }
  
    return this.httpClient
      .get(
        `${environment.url}global/media/presigned?purpose=${purpose}&contentType=${contentType}&id=${name}`,
        httpOptions 
      )
      .pipe(
        concatMap((res: any) =>
          this.s3UploadCall(
            res['payload']['preSignedUrl'],
            res['payload']['key'],
            file,
            contentType
          )
        ),
        map((response: any) => {
          console.log("response  ---->", response);
          return { url: response.url, type: contentType };
        })
      );
  }
  
  s3UploadCall(
    preSignedUrl: string,
    key: string,
    file: any,
    contentType: string
  ) {
    // this.key = key;
    // const formData = new FormData();
    // formData.append("file", file)
    const headersPic = new HttpHeaders({
      'Content-Type': contentType,
    });

    let httpheader = new HttpHeaders().set('Content-Type', contentType);
    let options = {
      headers: httpheader,
    };

    return this.httpClient.put(preSignedUrl, file, options).pipe(
      map((response: any) => {
        return { url: key, type: contentType };
      })
    );
  }
  statusEducation(payload, id) {
    return this.httpservice
      .putApi(ApiURL.statusEducation + '?eduId=' + id + '&status=' + payload)
      .pipe(
        tap(() => {
          return this.refresheducationStatus$.next(true);
        })
      );
  }
  educationUniqueness(data) {
    return this.httpservice.getAll(
      ApiURL.educationUniqueness + '?name=' + data,
      null
    );
  }

  //client Spoc
  getClientSpocAll() {
    return this.httpservice.getAll(ApiURL.getClientSpocAll, null);
  }

  getcomapanybaseclientscop(id: string) {
    return this.httpservice.getAll(
      ApiURL.getCompanybaseclientscop+'?clId='+id,
      null
    );
  }

  // get listing requirment
  //client Spoc
  requirementListingAll(payload: any) {
    return this.httpservice.postAll(ApiURL.getrequirementAll, payload);
  }

  requirementStatusChanged(id: string, status: string) {
    // return this.httpservice.postAll(ApiURL.getrequirementAll,payload)
    return this.httpservice
      .putApi(`${ApiURL.getrequirementStatuschanged}?id=${id}&status=${status}`, null)
      .pipe(
        tap(() => {
          return this.refreshRequirement$.next(true);
        })
      );
  }

  //Assignement
  getAssignementAll(payload) {
    return this.httpservice.postAll(ApiURL.getAssignementAll, payload);
  }

  //city searching
  cityfilter(name: string) {
    return this.httpservice.getAll(`${ApiURL.city}/name?name=${name}`);
  }

  getcity(id: number) {
    return this.httpservice.getAll(`${ApiURL.city}?cityId=${id}`);
  }
  // saveCandidateFilter(payload) {
  //   return this.httpservice.postAll(ApiURL.employeeSavedSearch, payload);
  // }
  // fetchCandidateFilter(payload) {
  //   return this.httpservice.getAll(ApiURL.fetchCandidateFilter, payload);
  // }

  //savesearch
  // saveSerach(data) {
  //   return this.httpservice.getAll(ApiURL.saveSerach, data);
  // }

  deleteSaveSerach(id) {
    return this.httpservice.deleteOne(ApiURL.deleteSaveSerach, id).pipe(
      tap(() => {
        return this.refreshFilterSearch$.next(true);
      })
    );
  }

  navMenu() {
    return this.httpservice.getAll(ApiURL.navMenu);
  }
  menuFetchForUser() {
    return this.httpservice.getAll(ApiURL.menuFetchForUser);
  }
  defaultRoleAccess() {
    return this.httpservice.getAll(ApiURL.defaultRoleAccess);
  }

  //interview Feedback
  canInterviewFeedback(jcId,ctId) {
    return this.httpservice.getAll(
      ApiURL.canInterviewFeedback + '?jcId=' + jcId+ '&ctId=' + ctId,
      null
    );
  }
  evaluationformQueAns(jcId,ctId) {
    return this.httpservice.getAll(
      ApiURL.evaluationformQueAns + '?jcid=' + jcId+ '&ctid=' + ctId,
      null
    );
  }
  candidateEmailBcc(payload){
    return this.httpservice.postAll(ApiURL.candidateEmailBcc, payload);
  }

  unblockUser(userName) {
    return this.httpservice.getAll(
      ApiURL.unblockUser + '?username=' + userName
    );
  }

  //clientlocations for workflow
  clientLocations(name) {
    return this.httpservice.getAll(ApiURL.clientLocations + '?name=' + name, null);
  }
  // cityLocations(name){
  //   return this.httpservice.getAll(ApiURL.ctLocations + '?name='+name,null);
  // }

  // allSchedule
  interviewSchedule(currentDate,oneYearLater) {
    console.log(currentDate);

    return this.httpservice.getAll(ApiURL.interviewSchedule+"?dateFrom="+currentDate+"&dateTo="+oneYearLater);
  }
  exportCandidateOffer(payload){

    return this.httpservice.getAll(ApiURL.exportCandidateOffer+payload);
  }

  // Get Client spoc With Help of Name
  clientSpocByName(name:any){
    return this.httpservice.getAll(ApiURL.clientSpocByName,name)
  }

  //designation
  getDesignationAll() {
    return this.httpservice.getAll(ApiURL.getDesignationAll, null);
  }
  getDesignationById(id) {
    return this.httpservice.getAll(ApiURL.getDesignationById + '?dgId=' + id, null);
  }
  createDesignation(payload) {
    return this.httpservice.postAll(ApiURL.createDesignation, payload);
  }
  updateDesignation(payload, dgId) {
    return this.httpservice.putApi(
      ApiURL.updateDesignation + '?dgId=' + dgId,
      payload
    );
  }
  statusDesignation(payload, id) {
    return this.httpservice
      .putApi(ApiURL.statusDesignation + '?dgId=' + id + '&status=' + payload)
      .pipe(
        tap(() => {
          return this.refreshdesignationStatus$.next(true);
        })
      );
  }
  designationUniqueness(data) {
    return this.httpservice.getAll(
      ApiURL.designationUniqueness + '?' + data,
      null
    );
  }
  //offer desginations
  offerDesignationAll() {
    return this.httpservice.getAll(ApiURL.offerDesignationAll, null);
  }

  getOfferDesignationById(id){
    return this.httpservice.getAll(ApiURL.getOfferDesignationById + '?id=' + id, null);
  }

  createOfferDesignation(payload) {
    return this.httpservice.postAll(ApiURL.createOfferDesignation, payload);
  }

  updateOfferDesignation(payload, id) {
    return this.httpservice.putApi(
      ApiURL.updateOfferDesignation + '?id=' + id, payload
    );
  }

  statusOfferDesignation(payload, id) {
    return this.httpservice
      .putApi(ApiURL.statusOfferDesignation + '?id=' + id + '&status=' + payload)
      .pipe(
        tap(() => {
          return this.refreshofferDesignationStatus$.next(true);
        })
      );
  }

  offerDesignationUniqueness(data) {
    return this.httpservice.getAll(
      ApiURL.offerDesignationUniqueness + '?name=' + data,
      null
    );
  }


}
