import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PrivacyPolicyComponent } from '../Footer-content/privacy-policy/privacy-policy.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HomeService } from '../_services/home.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { AuthService } from '../_services/auth.service';
import { UserProfileService } from '../_services/user-profile.service';
import Swal from 'sweetalert2';
import { environmentbasic } from '../../environments/environment-basic';
import { ErrorMessageService } from '../services/error-message.service';
@Component({
  selector: 'app-verifiedotp',
  templateUrl: './verifiedotp.component.html',
  styleUrls: ['./verifiedotp.component.scss'],
})
export class VerifiedotpComponent implements OnInit {
  otp: string = '';
  form: FormGroup;
  submitted = false;


  // Properties for name and icon
  name: string;
  icon: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
     public data: {
      email: string;
      expertiseId: string;
      professionId: string;
      name: string;
      icon: string;
    },
    private tokenStorage: TokenStorageService,
    private fb: FormBuilder,
    private userProfileService: UserProfileService,
    private authService: AuthService,
    private rtr: Router,
    private homeService: HomeService,
    private errorMessageService:ErrorMessageService,
    public dialogRef: MatDialogRef<PrivacyPolicyComponent>
  ) {
    // Assign name and icon from data to component properties
    this.name = data.name;
    this.icon = data.icon;


    console.log('Email:', data.email);
    console.log('Name:', data.name);
    console.log('Icon:', data.icon);
    console.log('Expertise ID:', data.expertiseId);
    console.log('Profession ID:', data.professionId);
  }

  ngOnInit(): void {}

  // Submit OTP logic
  onSubmitOtp() {}

  // Resend OTP logic
  resend() {
    this.homeService.sendotpToMobile(this.data.email).subscribe(
      (res: any) => {
        console.log(res);

        if (res && res.status === 'SUCCESS') {
          console.log('OTP Resent Successfully');
        } else {
          console.log('Failed to resend OTP');
        }
      },
      error => {
        console.error('Error sending email OTP:', error);
      }
    );
  }

  skip() {
    this.dialogRef.close();
  }

  onOtpChange(otp: string) {
    this.otp = otp;
    console.log(otp);
  }

  UserProfile() {
    this.authService.getProfileData().subscribe(response => {
      if (response) {
        let UserProfile = response;
        localStorage.setItem("UserProfile", JSON.stringify(UserProfile));
        console.log("UserProfile retrieved successfully");
        this.userProfileService.notifyUserProfileUpdate();
      } else {
        console.log("Failed to retrieve user profile");
      }
    });
  }

  // Verifying the OTP
  verifyOtp() {
    if (this.data.expertiseId !== undefined) {
      this.homeService.AddNewLead(
        this.data.email,
        this.data.professionId,
        this.data.expertiseId,
        this.otp
      )
      .subscribe(
        (response) => {
          if (response['status'] === 'SUCCESS') {
            console.log(response);
            let accessT = response.payload.access_token;
            const accessToken = response.payload;
            this.tokenStorage.saveToken(JSON.stringify(accessToken));
            const refreshToken = response.payload.refresh_token;
            localStorage.setItem('access_token', accessT);
            const expiresIn = response.payload.expires_in;
            localStorage.setItem('refresh_token', refreshToken);
            localStorage.setItem('expires_in', expiresIn);
            this.tokenStorage.saveSocialOrigin(null);
            this.tokenStorage.saveSocialUserEmail(null);
            this.tokenStorage.saveFirstVisitToHomePageAfterLogin('firstVisit');
            this.UserProfile();
            // window.location.reload();
            Swal.fire({
                                        icon: 'success',
                                        title: "Your service request has been received. Our team will connect with you shortly.",
                                        confirmButtonText: 'OK',
                                        customClass: {
                                          confirmButton: 'custom-swal-button'
                                        }
                                      }).then(() => {
                                        window.location.reload();
                                      });

                                      const style = document.createElement('style');
                                      style.innerHTML = `
                                        .custom-swal-button {
                                          background-color: #004aad !important;
                                          color: #ffffff !important;
                                        }
                                      `;
                                      document.head.appendChild(style);
            this.skip();
          } else if (response['message']) {
            const errorMessage = this.errorMessageService.getErrorMessage(response['message']);
            Swal.fire({
              icon: 'error',
              title: errorMessage,
              showConfirmButton: false,
              timer: environmentbasic.timer
            });
          } else {
            throw new Error('Login failed');
          }
        },
        (error) => {
          console.error('Error adding new lead:', error);
          const errorMessage = this.errorMessageService.getErrorMessage(error.message || 'An error occurred');
          Swal.fire({
            icon: 'error',
            title: errorMessage,
            showConfirmButton: false,
            timer: environmentbasic.timer
          });
        }
      );
    } else {
      this.homeService.AddNewLead1(
        this.data.email,
        this.data.professionId,
        this.otp
      )
      .subscribe(
        (response) => {
          if (response['status'] === 'SUCCESS') {
            console.log(response);
            let accessT = response.payload.access_token;
            const accessToken = response.payload;
            this.tokenStorage.saveToken(JSON.stringify(accessToken));
            const refreshToken = response.payload.refresh_token;
            localStorage.setItem('access_token', accessT);
            const expiresIn = response.payload.expires_in;
            localStorage.setItem('refresh_token', refreshToken);
            localStorage.setItem('expires_in', expiresIn);
            this.tokenStorage.saveSocialOrigin(null);
            this.tokenStorage.saveSocialUserEmail(null);
            this.tokenStorage.saveFirstVisitToHomePageAfterLogin('firstVisit');
            this.UserProfile();
            // window.location.reload();
            Swal.fire({
                                        icon: 'success',
                                        title: "Your service request has been received. Our team will connect with you shortly.",
                                        confirmButtonText: 'OK',
                                        customClass: {
                                          confirmButton: 'custom-swal-button'
                                        }
                                      }).then(() => {
                                        window.location.reload();
                                      });

                                      const style = document.createElement('style');
                                      style.innerHTML = `
                                        .custom-swal-button {
                                          background-color: #004aad !important;
                                          color: #ffffff !important;
                                        }
                                      `;
                                      document.head.appendChild(style);


            this.skip();
          } else if (response['message']) {
            const errorMessage = this.errorMessageService.getErrorMessage(response['message']);
            Swal.fire({
              icon: 'error',
              title: errorMessage,
              showConfirmButton: false,
              timer: environmentbasic.timer
            });
          } else {
            throw new Error('Login failed');
          }
        },
        (error) => {
          console.error('Error adding new lead:', error);
          const errorMessage = this.errorMessageService.getErrorMessage(error.message || 'An error occurred');
          Swal.fire({
            icon: 'error',
            title: errorMessage,
            showConfirmButton: false,
            timer: environmentbasic.timer
          });
        }
      );
    }
  }
}
